.mysteryBox-how-to-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 75px 20px;
  gap: 32px;
}

.mysteryBox-how-to {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 25px;
  align-self: stretch;
  max-width: 1110px;
}

.mysteryBox-how-to-item {
  display: flex;
  padding: 15px;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  flex: 1 0 0;
}

.mysteryBox-how-to-item-image img {
  width: 90px;
  height: 90px;
}

.mysteryBox-how-to-item-text {
  display: flex;
  height: 140px;
  padding: 15px 0px;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  align-self: stretch;
}

.mysteryBox-how-to-item-text-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  align-items: center;
  color: var(--text-color);
  text-align: center;
  font-size: 24px;
  font-weight: 600;
}

.mysteryBox-how-to-item-text-desc {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
  align-self: stretch;
  text-align: center;
  color: var(--para-text-color);
  font-size: var(--statement-paragraph-text);
  font-weight: 400;
}

@media screen and (max-width: 850px) {
  .mysteryBox-how-to {
    gap: 15px;
  }

  .mysteryBox-how-to-item-image img {
    width: 80px;
    height: 80px;
  }

  .mysteryBox-how-to-item-text-title {
    font-size: 20px;
  }
}

@media screen and (max-width: 650px) {
  .mysteryBox-how-to {
    gap: 5px;
  }
  .mysteryBox-how-to-item-image img {
    width: 70px;
    height: 70px;
  }
  .mysteryBox-how-to-item-text-title {
    font-size: 18px;
  }
}

@media screen and (max-width: 500px) {
  .mysteryBox-how-to {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0px;
    align-self: stretch;
    max-width: 1110px;
  }

  .mysteryBox-how-to-item-text {
    height: 80px;
  }
}

@media screen and (max-width: 400px) {
}

@media screen and (max-width: 360px) {
}
