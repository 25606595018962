@import "../../mainVariables.css";

.socialHero {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 90px 20px 0px 20px;
  /*  */
  z-index: 100;
}

.socialHero-container {
  z-index: 1;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  /*  */
  gap: var(--card-gap);

  width: 100%;
  max-width: var(--max-width);
}

.socialHero-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: nowrap;

  align-self: stretch;
}

.socialHero-title-title {
  display: flex;
  flex-direction: row;
  color: var(--text-color);
  text-align: center;
  font-size: var(--call-title-text);
  flex-wrap: nowrap;
  gap: calc(var(--card-gap) / 2);

  font-weight: 700;
  text-align: left;
}

.socialHero-title-subtitle {
  color: var(--para-text-color);
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
}

.socialHero-background {
  display: flex;
  transition: right 0.3s, top 0.3s;
  justify-content: flex-end;
}

.socialHero-background img {
  z-index: 0;

  width: 80%;
  height: auto;

  filter: drop-shadow(0 0 5px var(--special-glow-color))
    drop-shadow(0 0 10px var(--special-glow-color))
    drop-shadow(0 0 30px var(--special-glow-color));
}

.socialHero-button-container {
  display: flex;
  padding: 30px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  align-self: stretch;
}

.socialHero-button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  display: flex;
  height: 40px;
  width: 100%;
  align-items: center;
  gap: 6px;
  border-radius: 25px;
  background: var(--gradiant3vo);
}

.socialHero-button:hover {
  transition: border-radius 0.2s, color 0.2s, filter 0.2s;
  color: var(--background-color);
  filter: drop-shadow(0 0 5px var(--button-glow-color))
    drop-shadow(0 0 10px var(--button-glow-color))
    drop-shadow(0 0 30px var(--button-glow-color));
}

.socialHero-button:hover .defiHero-button-title {
  color: var(--background-color);
}

.socialHero-button-title {
  color: var(--button-text-color);
  font-size: 20px;
  font-weight: 700;
}

@media screen and (max-width: 950px) {
  .socialHero-background img {
    width: 100%;
  }
}

@media screen and (max-width: 850px) {
}

@media screen and (max-width: 768px) {
}

@media screen and (max-width: 650px) {
}

@media screen and (max-width: 600px) {
}

@media screen and (max-width: 500px) {
}

@media screen and (max-width: 400px) {
}

@media screen and (max-width: 350px) {
}
