.TokenFiveSection-wrapper {
  display: flex;
  width: 1248.037px;
  flex-direction: column;
  align-items: flex-start;
  gap: 48px;
  padding-top: 80px;
  padding-bottom: 100px;
}
.TokenFiveSection-title {
  color: var(--Total-white, #fff);
  font-family: "Poppins";
  font-size: 44px;
  font-weight: 700;
  line-height: 124%;
  letter-spacing: 2.64px;
  text-transform: capitalize;
  max-width: 824.795px;
  transform: rotate(0.038deg);
}

.TokenFive-Eco-Cards {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 24px;
  align-self: stretch;
  flex-wrap: wrap;
}
.TokenFive-card-container {
  border-radius: 12px;
  background: var(--Bg-cards-buttons, #0f161b);
  display: flex;
  width: 612px;
  padding: 12px 20px;
  align-items: flex-start;
  gap: 12px;
}
.token-card-icon {
  border-radius: 54px;
  background: var(--New-Cards-bg_new, #1c202d);
  display: flex;
  width: 64px;
  height: 64px;
  padding: 11px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}
.token-card-svg {
  width: 42px;
  height: 42px;
  flex-shrink: 0;
}
.token-card-title-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  flex: 1 0 0;
}
.token-card-title {
  color: #fff;
  font-family: "Roboto";
  font-size: 22px;
  font-weight: 700;
  line-height: 128%;
  letter-spacing: 0.88px;
}
.token-card-sub {
  align-self: stretch;
  color: #fff;
  font-family: "Roboto";
  font-size: 19px;
  font-weight: 300;
  line-height: 148%;
  letter-spacing: 0.76px;
}
@media (max-width: 576px) {
  .TokenFiveSection-wrapper {
    width: 100%;
    padding: 40px 20px;
    gap: 32px;
    text-align: center;
  }

  .TokenFiveSection-title {
    font-size: 30px;
    letter-spacing: 1.5px;
    width: 100%;
    text-align: start;
  }

  .TokenFive-Eco-Cards {
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }

  .TokenFive-card-container {
    width: 100%;
    padding: 16px;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .token-card-icon {
    width: 56px;
    height: 56px;
  }

  .token-card-svg {
    width: 36px;
    height: 36px;
  }

  .token-card-title-body {
    align-items: center;
    gap: 8px;
  }

  .token-card-title {
    font-size: 20px;
  }

  .token-card-sub {
    font-size: 16px;
    line-height: 130%;
  }
}
@media (max-width: 400px) {
  .TokenFiveSection-title {
    font-size: 24px;
    letter-spacing: 1.5px;
    width: 100%;
    text-align: start;
  }
}
