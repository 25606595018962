@import "../../mainVariables.css";

.tokenomics {
  position: relative;
  display: flex;
  padding: var(--split-padding);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}

.tokenomics-container {
  z-index: 1;
  display: flex;
  width: 100%;
  max-width: var(--max-width);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--card-gap);
}

.tokenomics-title {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--text-color);
  text-align: center;
  font-size: var(--main-title-text);
  font-weight: 700;
  align-self: stretch;
}

.tokenomics-main-container {
  display: flex;

  flex-direction: column;
  align-items: center;
  align-self: stretch;
}

.tokenomics-main-token {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
}

.tokenomics-main-token-chart {
  position: relative;
  display: flex;
  width: 75%;

  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}

.tokenomics-main-token-chart-pie {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}

.tokenomics-main-token-chart-pie img {
  width: 100%;
}

.tokenomics-main-token-metrics {
  display: flex;
  padding: 25px 0px 25px 25px;
  flex-direction: column;
  align-items: center;
  gap: 15px;

  align-self: stretch;

  width: 25%;
}

.tokenomics-main-token-metrics-token {
  display: flex;
  padding: 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;

  border-radius: 15px;
  background: var(--special-card-color);
}

.tokenomics-main-token-metrics-token-title {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;

  color: var(--text-color);
  text-align: center;
  font-size: var(--card-title-text);
  font-weight: 600;
}

.tokenomics-main-token-metrics-token-list {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}

.tokenomics-main-token-metrics-token-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.tokenomics-main-token-metrics-token-list-item-name {
  display: flex;
  align-items: center;
  text-align: left;
  color: var(--special3vo);
  font-size: var(--card-small-title-text);
  font-weight: 600;
}

.tokenomics-main-token-metrics-token-list-item-value {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  color: var(--para-text-color);
  text-align: right;
  font-size: var(--card-small-title-text);
  font-weight: 400;
}

.tokenomics-main-token-metrics-metric {
  display: flex;
  padding: 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;

  border-radius: 15px;
  background: var(--special-card-color);
}

.tokenomics-main-token-metrics-metric-title {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;

  color: var(--text-color);
  text-align: center;
  font-size: var(--card-title-text);
  font-weight: 600;
}

.tokenomics-main-token-metrics-metric-list {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}

.tokenomics-main-vesting {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  align-self: stretch;
}

.tokenomics-main-vesting-title {
  align-self: stretch;

  color: var(--text-color);
  font-size: 24px;
  font-weight: 600;
  text-align: left;
}

.tokenomics-main-vesting-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: var(--card-gap);
  align-self: stretch;
}

.tokenomics-main-vesting-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  flex: 1 0 0;
  align-self: stretch;
}

.tokenomics-main-vesting-item {
  display: flex;
  padding: 0px 15px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  min-height: 50px;

  border-radius: 5px;
  background: var(--card-color);
}

.tokenomics-main-vesting-item-name {
  display: flex;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;

  text-align: left;
  color: var(--special3vo);
  font-size: 12px;
  font-weight: 600;
}

.tokenomics-main-vesting-item-value {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1 0 0;

  color: var(--para-text-color);
  text-align: right;
  font-size: 12px;
  font-weight: 400;
}

.tokenomics-action {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 15px 0px;
}

.tokenomics-action a {
  text-decoration: none;
  color: var(--button-text-color);
}

.tokenomics-action-button {
  display: flex;
  align-items: center;
  /*  */
  cursor: pointer;
  padding: 5px 80px;
  gap: 6px;
  color: var(--button-text-color);
  border-radius: 20px;
  background: linear-gradient(90deg, #d129dc 0.08%, #0cdbfd 99.92%);

  font-size: 1rem;
  text-wrap: nowrap;
}

.tokenomics-action-button:hover {
  transition: border-radius 0.2s, color 0.2s, filter 0.2s;
  color: var(--background-color);
  filter: drop-shadow(0 0 5px var(--button-glow-color))
    drop-shadow(0 0 10px var(--button-glow-color))
    drop-shadow(0 0 30px var(--button-glow-color));
}

.tokenomics-background {
  position: absolute;
  opacity: 0.1;
  right: -400px;
  top: -500px;
  filter: blur(50px);
  z-index: 0;
}

.tokenomics-background img {
  color: var(--gradiant3vo);
  width: 1400px;
}

@media screen and (max-width: 950px) {
  .tokenomics-main-vesting-container {
    flex-direction: column;
  }

  .tokenomics-main-vesting-item {
    min-height: 30px;
  }
}

@media screen and (max-width: 850px) {
}

@media screen and (max-width: 768px) {
  .tokenomics-feature-icon img {
    height: 60px;
  }

  .tokenomics-main-token-metrics {
    width: 100%;
    padding: 0px 0px 25px 0px;
  }

  .tokenomics-main-token-chart {
    width: 100%;
  }

  .tokenomics-main-token {
    flex-direction: column;
  }
}

@media screen and (max-width: 650px) {
}

@media screen and (max-width: 600px) {
  .tokenomics-feature-icon img {
    height: 40px;
  }
}

@media screen and (max-width: 500px) {
}

@media screen and (max-width: 400px) {
}

@media screen and (max-width: 350px) {
}
