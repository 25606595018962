.engagment-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 800px;
  gap: 48px;
  background: var(--Bg-Dark, #080d10);
  padding-left: 96px;
  padding-right: 130px;
  padding-bottom: 130px;
}
.voutionaries-text-wrapper-cta {
  display: none;
}
.engagment-title-subtitle {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.engagment-title {
  width: 720px;
  color: var(--Total-white, #fff);
  font-family: "Poppins";
  font-size: 44px;
  font-style: normal;
  font-weight: 700;
  line-height: 124%;
  letter-spacing: 2.64px;
  text-transform: capitalize;
  text-align: start;
}

.engagment-subtitle {
  color: var(--Secondary-Light, #adadad);
  text-align: right;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 124%;
  text-align: start;
}

.engagment-cards-wrapper {
  display: flex;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}

.engagment-cards {
  position: relative;
  width: 400px;
  height: 350px;
}

.engagment-cards-image {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  width: 400px;
  height: 346px;
  flex-shrink: 0;
  fill: var(--Bg-cards-buttons, #0f161b);
}

.engagment-card-container {
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2; /* Ensures it's above the SVG */
  border-radius: 2px;
  display: flex;
  width: 400px;
  height: 346px;
  padding: 32px 24px;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
}

.engagment-card-title-icon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.engagment-card-title {
  color: var(--Total-white, #fff);
  font-family: "Inter";
  font-size: 22px;
  font-weight: 700;
  line-height: 128%;
  letter-spacing: 0.88px;
}

.engagment-card-icon-wrapper {
  display: flex;
  width: 64px;
  height: 64px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 46px;
  background: var(--New-Cards-bg_new, #1c202d);
}

.engagment-card-icon {
  width: 35px;
  height: 35px;
  flex-shrink: 0;
}
.engagment-card-box-1 {
  margin-top: -20px;
}
.engagment-card-box-wrapper {
  display: flex;
  width: 322px;
  justify-content: center;
  align-items: center;
  margin-top: -17px;
}
.engagment-card-box-2 {
  width: 390px;
  height: 203px;
  flex-shrink: 0;
  margin-top: 45px;
  background: -21.672px 0px / 105.97% 120.339% no-repeat;
}
.engagment-card-container-3 {
  z-index: 2;
  position: absolute;
  overflow: hidden; /* Hide any extra part of the image */
  top: 49%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2; /* Ensures it's above the SVG */
  border-radius: 2px;
  display: flex;
  width: 400px;
  height: 346px;
  padding: 32px 24px;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
}

/* .engagment-card-box-3 {
  margin-top: 45px;
  margin-top: 50px;
  width: 466px;
  height: 252px;
  flex-shrink: 0;
  background: -21.672px 0px / 105.97% 120.339% no-repeat;
} */
.engagment-card-box-3 {
  margin-top: 45px;
  margin-top: 50px;
  width: 400px;
  /* height: 252px; */
  flex-shrink: 0;
  background: -21.672px 0px / 105.97% 120.339% no-repeat;
  margin-top: -82px;
}

.engagment-botton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.botton-paragraph {
  width: 942px;
  color: var(--new-secondary-light-new, rgba(238, 238, 238, 0.9));
  font-family: "Roboto";
  font-size: 19px;
  font-style: normal;
  font-weight: 300;
  line-height: 148%;
  letter-spacing: 0.76px;
  text-align: start;
}

.engagment-cta {
  display: flex;
  width: 188px;
  height: 40px;
  justify-content: center;
  align-items: center;
  background-image: url(../../assets/Engagment/earnNowBtn.svg);
  cursor: pointer;
}
.engagment-cta-text {
  color: var(--Total-white, #fff);
  font-family: "Inter";
  font-size: 18px;
  font-weight: 700;
  line-height: 136%;
  letter-spacing: 0.36px;
}

@media screen and (max-width: 400px) {
  .voutionaries-text-wrapper-cta {
    display: flex;
  }
  .engagment-botton {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: anchor-center;
    margin-top: -60px;
  }
  .engagment-subtitle {
    display: none;
  }
  .engagment-title {
    color: var(--Total-white, #fff);
    font-feature-settings: "ss01" on;
    /* Phone/Headlines/H2 */
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 122%; /* 39.04px */
    letter-spacing: 1.92px;
    text-transform: capitalize;
    margin-left: -75px;
    padding-right: 324px;
  }
  .engagment-wrapper {
    padding-top: 50px;
    /* height: 200vh; */
    height: auto;
  }
  .botton-paragraph {
    color: var(--secondary-light-new, rgba(238, 238, 238, 0.9));
    /* Phone/Paragraph/Medium */
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 130%; /* 20.8px */
    letter-spacing: 0.64px;
    margin-left: 836px;
    padding-right: 616px;
  }
  .engagment-cards-wrapper {
    display: block;
    /* margin-left: -114px; */
    margin-left: -88px;
  }
  .engagment-card-container {
    z-index: 2;
    position: absolute;
    top: 44%;
    left: 44%;
    transform: translate(-50%, -50%);
    z-index: 2;
    border-radius: 2px;
    display: flex;
    width: 367px;
    height: 338px;
    padding: 32px 24px;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
  }
  .engagment-cards-image {
    width: 346px;
    height: 314px;
  }
  .engagment-card-box-1 {
    width: 103%;
  }
  .engagment-card-box-2 {
    width: 103%;
  }
  .engagment-cards {
    position: relative;
    width: 400px;
    height: 350px;
    margin-top: -30px;
  }
  .engagment-card-box-3 {
    width: 116%;
  }

  .voutionaries-text-wrapper-cta {
    color: var(--Total-white, #fff);

    /* Phone/Buttons/CTA */
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 28px */
    letter-spacing: 0.4px;
    padding: 7px;
  }
  .voutionaries-btn-sign {
    display: flex;
    background-image: url(../../assets/svg/join-presale-mobile.svg);
    justify-content: center;
    justify-items: center;
    cursor: pointer;
    width: 328px;
    height: 44px;
    flex-shrink: 0;

    margin-top: 20px;
  }
}
@media screen and (max-width: 450px) and (min-width: 401px) {
  .voutionaries-text-wrapper-cta {
    display: flex;
  }
  .engagment-botton {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: anchor-center;
    margin-top: -60px;
  }
  .engagment-subtitle {
    display: none;
  }
  .engagment-title {
    color: var(--Total-white, #fff);
    font-feature-settings: "ss01" on;
    /* Phone/Headlines/H2 */
    font-family: Poppins;
    font-size: 33px;
    font-style: normal;
    font-weight: 700;
    line-height: 122%; /* 39.04px */
    letter-spacing: 1.92px;
    text-transform: capitalize;
    /* margin-left: -87px; */
    margin-left: -80px;
    padding-right: 324px;
  }
  .engagment-wrapper {
    padding-top: 50px;
    /* height: 205vh; */
    height: auto;
  }
  .botton-paragraph {
    color: var(--secondary-light-new, rgba(238, 238, 238, 0.9));
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 130%;
    letter-spacing: 0.64px;
    /* margin-left: 794px; */
    margin-left: 772px;
    padding-right: 531px;
  }
  .engagment-cards-wrapper {
    display: block;
    margin-left: -53px;
  }
  .engagment-card-container {
    z-index: 2;
    position: absolute;
    top: 44%;
    left: 44%;
    transform: translate(-50%, -50%);
    z-index: 2;
    border-radius: 2px;
    display: flex;
    width: 367px;
    height: 338px;
    padding: 32px 24px;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
  }
  .engagment-cards-image {
    width: 346px;
    height: 314px;
  }
  .engagment-card-box-1 {
    width: 103%;
  }
  .engagment-card-box-2 {
    width: 103%;
  }
  .engagment-cards {
    position: relative;
    width: 400px;
    height: 350px;
    margin-top: -30px;
  }
  .engagment-card-box-3 {
    width: 116%;
  }

  .voutionaries-text-wrapper-cta {
    color: var(--Total-white, #fff);

    /* Phone/Buttons/CTA */
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 28px */
    letter-spacing: 0.4px;
    padding: 7px;
  }
  .voutionaries-btn-sign {
    display: flex;
    background-image: url(../../assets/svg/join-presale-mobile.svg);
    justify-content: center;
    justify-items: center;
    cursor: pointer;
    width: 328px;
    height: 44px;
    flex-shrink: 0;
    margin-left: 25px;
    margin-top: 20px;
  }
}
