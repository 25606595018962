@import "../../mainVariables.css";

.tokenPresale {
  position: relative;
  display: flex;
  padding: var(--no-padding);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}

.tokenPresale-container {
  display: flex;
  width: 100%;
  max-width: var(--max-width);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--card-gap);
}

.tokenPresale-title {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--text-color);
  text-align: center;
  font-size: var(--main-title-text);
  font-weight: 700;
  align-self: stretch;
}

.tokenPresale-features {
  display: flex;

  justify-content: center;
  align-items: flex-start;
  gap: var(--card-gap);
  align-self: stretch;

  flex-wrap: wrap;
}

.tokenPresale-feature {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;

  flex-basis: calc(33.333% - var(--card-gap));

  border-radius: 15px;
  background: var(--card-color);
}

.tokenPresale-feature-text {
  display: flex;
  padding: 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  flex: 1 0 0;
  align-self: stretch;
}

.tokenPresale-feature-title {
  color: var(--text-color);
  text-align: center;
  font-size: var(--card-title-text);
  font-weight: 600;
}

.tokenPresale-feature-price {
  color: var(--special3vo);
  text-align: center;
  font-size: 20px;
  font-weight: 600;
}

.tokenPresale-feature-current {
  color: var(--para-text-color);
  text-align: center;
  font-size: 16px;
  font-weight: 400;
}

.tokenPresale-feature-status.completed {
  color: var(--green3vo);
  text-align: center;

  font-size: var(--card-title-text);
  font-weight: 600;
}

.tokenPresale-feature-status.upcoming {
  color: var(--para-text-color);
  text-align: center;

  font-size: var(--card-title-text);
  font-weight: 600;
}

.tokenPresale-background {
  position: absolute;
  opacity: 0.1;
  right: -400px;
  top: -500px;
  filter: blur(50px);
  z-index: 0;
}

.tokenPresale-background img {
  color: var(--gradiant3vo);
  width: 1400px;
}

@media screen and (max-width: 950px) {
}

@media screen and (max-width: 850px) {
}

@media screen and (max-width: 768px) {
}

@media screen and (max-width: 650px) {
}

@media screen and (max-width: 600px) {
  .tokenPresale-feature {
    flex-basis: calc(50% - var(--card-gap));
  }
}

@media screen and (max-width: 500px) {
}

@media screen and (max-width: 400px) {
}

@media screen and (max-width: 350px) {
}
