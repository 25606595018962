@import "../../mainVariables.css";

.projectRoadmap {
  position: relative;
  display: flex;
  padding: 0px 20px 150px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 75px;
  align-self: stretch;
}

.projectRoadmap-container {
  display: flex;
  width: 100%;
  max-width: var(--max-width);
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: var(--card-gap);
}

.projectRoadmap-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}

.projectRoadmap-title-title {
  color: var(--text-color);
  text-align: center;
  font-size: 70px;

  font-weight: 700;
}

.projectRoadmap-background {
  position: absolute;
  opacity: 0.1;
  right: -400px;
  top: -200px;
  filter: blur(50px);
  z-index: 0;
}

.projectRoadmap-roadmap {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}

.projectRoadmap-roadmap-section1 {
  z-index: 1;
  display: flex;
  padding: 50px 0px 0px 0px;
  width: 50%;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;

  align-self: stretch;
}

.projectRoadmap-roadmap-section2 {
  z-index: 1;
  display: flex;
  padding: 50px 0px 0px 0px;
  width: 50%;
  flex-direction: column;
  align-items: flex-end;
  overflow: hidden;

  align-self: stretch;
}

.projectRoadmap-roadmap-section1 .projectRoadmap-roadmap-item {
  display: flex;
  width: var(--roadmap-width);
  padding: 15px;
  justify-content: space-between;
  align-items: flex-start;

  border-radius: 15px;
  background: var(--card-color);
}

.projectRoadmap-roadmap-section1 .projectRoadmap-roadmap-filler {
  display: flex;
  width: 100px;
  height: var(--filler-left);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.projectRoadmap-roadmap-section2 .projectRoadmap-roadmap-item {
  display: flex;
  flex-direction: row-reverse;
  width: var(--roadmap-width);
  padding: 15px;
  align-items: flex-start;
  gap: 15px;

  border-radius: 15px;
  background: var(--card-color);
}

.projectRoadmap-roadmap-section2 .projectRoadmap-roadmap-filler {
  display: flex;
  height: var(--filler-right);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  align-self: stretch;
}

.projectRoadmap-roadmap-item-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 5px;
  flex: 1 0 0;

  color: var(--para-text-color);
  text-align: center;
  font-size: var(--main-subtitle-text);
  font-weight: 500;
  text-align: left;
}

.projectRoadmap-roadmap-item-circle {
  position: relative;
  display: flex;
  width: 80px;
  height: 80px;
  justify-content: center;
  align-items: center;
  gap: 25px;
  flex-shrink: 0;

  border-radius: 80px;
  background: var(--special3vo);
}

.projectRoadmap-roadmap-section1 .projectRoadmap-roadmap-item-circle-line {
  position: absolute;
  left: 0px;
  top: 50%;
  transform: translateY(-50%);

  display: flex;
  width: calc(var(--roadmap-width) * 2);
  height: 4px;
  justify-content: center;
  align-items: center;

  border-radius: 0px 80px 80px 0px;
  background: var(--special3vo);
}

.projectRoadmap-roadmap-section2 .projectRoadmap-roadmap-item-circle-line {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);

  display: flex;
  width: calc(var(--roadmap-width) * 2);
  height: 4px;
  justify-content: center;
  align-items: center;

  border-radius: 0px 80px 80px 0px;
  background: var(--special3vo);
}

.projectRoadmap-roadmap-section1 .projectRoadmap-roadmap-item-circle-year {
  position: absolute;
  right: -80px;
  top: 0;

  color: var(--special3vo);
  text-align: center;
  font-size: 20px;
  font-weight: 500;
}

.projectRoadmap-roadmap-section2 .projectRoadmap-roadmap-item-circle-year {
  position: absolute;
  right: 120px;
  top: 0;

  color: var(--special3vo);
  text-align: center;
  font-size: 20px;
  font-weight: 500;
}

.projectRoadmap-roadmap-item-circle-quarter {
  z-index: 2;
  color: var(--text-color);
  text-align: center;
  font-size: 20px;
  font-weight: 500;
}

.projectRoadmap-roadmap-line {
  display: flex;
  width: 4px;
  height: 1352px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: var(--special3vo);
}

.projectRoadmap-roadmap-line-circle {
  display: flex;
  width: 30px;
  height: 30px;
  align-items: center;
  gap: 25px;
  flex-shrink: 0;

  border-radius: 30px;
  background: var(--special3vo);
}

.projectRoadmap-roadmap-line-arrow {
  width: 30px;
  height: 18.747px;
  flex-shrink: 0;
}

.projectRoadmap-background img {
  color: var(--gradiant3vo);
  width: 1400px;
}

@media screen and (max-width: 850px) {
  .projectRoadmap-title-title {
    font-size: 62px;
  }

  .projectRoadmap-roadmap-section1 .projectRoadmap-roadmap-item-circle-year {
    right: 50%;
    transform: translateX(50%);
    top: -40px;
  }

  .projectRoadmap-roadmap-section2 .projectRoadmap-roadmap-item-circle-year {
    right: 50%;
    transform: translateX(50%);
    top: -40px;
  }
}

@media screen and (max-width: 650px) {
  .projectRoadmap-title-title {
    font-size: 54px;
  }

  .projectRoadmap-roadmap-section1 .projectRoadmap-roadmap-item {
    flex-direction: column-reverse;
    align-items: flex-start;
    gap: var(--card-gap);
  }

  .projectRoadmap-roadmap-section2 .projectRoadmap-roadmap-item {
    flex-direction: column-reverse;
    align-items: flex-end;
    gap: var(--card-gap);
  }

  .projectRoadmap-roadmap-item-text {
    width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .projectRoadmap-title-title {
    font-size: 48px;
  }

  .projectRoadmap-comingsoon img {
    width: 160px;
  }

  .projectRoadmap-roadmap-line {
    height: 1450px;
  }
}

@media screen and (max-width: 451px) {
  .projectRoadmap-roadmap-section1 {
    width: 100%;
  }

  .projectRoadmap-roadmap-line {
    height: 2000px;
  }

  .projectRoadmap-roadmap-item-circle {
    width: 50px;
    height: 50px;
  }

  .projectRoadmap-roadmap-item {
    width: 80% !important;
  }
}

@media screen and (max-width: 400px) {
  .projectRoadmap-title-title {
    font-size: 42px;
  }
}

@media screen and (max-width: 360px) {
  .projectRoadmap-title-title {
    font-size: 32px;
  }

  .projectRoadmap-button-title {
    font-size: 16px;
  }

  .projectRoadmap-button {
    justify-content: center;
    width: 100%;
    padding: 5px 0px;
  }
}
