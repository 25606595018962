.bridging-firt-frame {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 130px;
  width: 1248px;
  margin-bottom: 80px;
  /* height: 280px; */
  /* margin-left: 131px;
  margin-right: 131px; */
}
.bridging-first-reactangle {
  background: url(../../../../assets/svg/aboutRobot2.png) 50% / cover no-repeat;
  width: 432px;
  height: 450px;
  aspect-ratio: 1/1;
  /* background: #d9d9d9; */
  /* width: 400px;
  height: 400px; */
}
.bridging-second-reactangle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  flex: 1 0 0;
}
.bridging-title {
  align-self: stretch;
  color: var(--Total-white, #fff);

  /* H2 (new) */
  font-family: Poppins;
  font-size: 44px;
  font-style: normal;
  font-weight: 700;
  line-height: 124%; /* 54.56px */
  letter-spacing: 2.64px;
  text-transform: capitalize;
}
.bridging-pargraph {
  align-self: stretch;
  color: var(--secondary-light-new, rgba(238, 238, 238, 0.9));

  /* Desktop/Paragraph/Medium */
  font-family: Roboto;
  font-size: 19px;
  font-style: normal;
  font-weight: 300;
  line-height: 148%; /* 28.12px */
  letter-spacing: 0.76px;
}
@media (max-width: 576px) {
  .bridging-firt-frame {
    display: grid;
    gap: 20px;
    margin: 0 auto;
    max-width: 95%;
    padding: 20px;
    padding-inline: 10px;
    padding-bottom: 80px;
  }
  .bridging-second-reactangle {
    order: 2;
  }
  .bridging-first-reactangle {
    order: 1;
    margin-inline: auto;
    max-width: 95%;
  }
  .bridging-title {
    align-self: stretch;
    color: var(--Total-white, #fff);

    /* H2 (new) */
    font-family: Poppins;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 124%; /* 54.56px */
    letter-spacing: 2.64px;
    text-transform: capitalize;
    padding-left: 27px;
  }
  .bridging-pargraph {
    align-self: stretch;
    color: var(--secondary-light-new, rgba(238, 238, 238, 0.9));
    text-align: start;
    /* Desktop/Paragraph/Medium */
    font-family: Roboto;
    font-size: 17px;
    font-style: normal;
    font-weight: 300;
    line-height: 148%; /* 28.12px */
    letter-spacing: 0.76px;
    margin-inline: auto;
    padding-inline: 10px;
    padding-left: 27px;
    padding-right: 13px;
  }
}
@media (max-width: 400px) {
  .bridging-title {
    align-self: stretch;
    color: var(--Total-white, #fff);
    font-family: Poppins;
    font-size: 34px;
    font-style: normal;
    font-weight: 700;
    line-height: 124%;
    letter-spacing: 2.64px;
    text-transform: capitalize;
    padding-left: 50px;
  }
  .bridging-pargraph {
    /* align-self: stretch;
    color: var(--secondary-light-new, rgba(238, 238, 238, 0.9));
    text-align: start;
     
    font-size: 17px;
    font-style: normal;
    font-weight: 300;
    line-height: 148%;  
    letter-spacing: 0.76px;
    margin-inline: auto;
    padding-inline: 20px;
    padding-left: 35px;
    padding-right: 24px; */
    align-self: stretch;
    color: var(--secondary-light-new, rgba(238, 238, 238, 0.9));
    text-align: start;
    font-family: Roboto;
    font-size: 17px;
    font-style: normal;
    font-weight: 300;
    line-height: 148%;
    letter-spacing: 0.76px;
    margin-inline: auto;
    padding-inline: 20px;
    padding-left: 53px;
    padding-right: 38px;
  }
}
