@import "./mainVariables.css";

.App {
  text-align: center;
  background: #080d10;
  overflow: hidden;
}

.container {
  max-width: 1440px;
  margin-inline: auto;
}
.root {
  --Accent-blue: #6c84f8;
}
