/* themes.css */

/* Light Theme */
:root {
  /* Light Website Main Colors */
  --Accent-blue: #6c84f8;

  --background-color: #ffffff;
  --header-background-color: rgba(255, 255, 255, 0.7);
  --footer-background-color: rgba(108, 132, 248, 0.05);
  --submenu-background-color: rgba(255, 255, 255, 0.95);
  --card-color: rgba(0, 0, 0, 0.08);
  --card-second-color: #d1d1d1;
  --card-second-border-color: #e0e0e0;
  --card-border-color: rgba(0, 0, 0, 0.08);
  --card-over-color: rgba(255, 255, 255, 0.345);
  --special-card-color: rgba(108, 132, 248, 0.2);
  --special-card-color-overlay: rgba(108, 132, 248, 0.5);
  --special-glow-color: rgba(108, 131, 248, 0.5);
  --button-glow-color: rgba(108, 131, 248, 0.5);
  --text-color: #000000;
  --button-text-color: rgb(255, 255, 255);
  --faded-text-color: #00000012;
  --header-text-color: #3e3e3e;
  --header-text-color-hover: #000000;
  --para-text-color: #3e3e3e;
  --social-color: rgba(108, 132, 248, 0.2);
  --social-link-color: #3e3e3e;
  /* 3VO Colors */
  --gradiant3vo: linear-gradient(90deg, #d129dc 0.08%, #0cdbfd 99.92%);
  --faded-gradiant3vo: linear-gradient(
    90deg,
    rgba(209, 41, 220, 0.3) 0.08%,
    rgba(12, 219, 253, 0.3) 99.92%
  );
  --social-gradiant3vo: linear-gradient(
    90deg,
    rgba(209, 41, 220, 0.3) 0.08%,
    rgba(12, 219, 253, 0.3) 99.92%
  );
  --special3vo: #6c84f8;
  --blue3vo: #01e5ff;
  --purple3vo: #fe01d2;
  --green3vo: #00fe8d;
  --dark-purple3vo: #281931;
  --no-color: #ffffff00;
  --cyan3vo: #01dfca;
}

/* Dark Theme */
.dark-theme {
  /* Dark Website Main Colors */
  --background-color: #0e0e0e;
  --header-background-color: rgba(14, 14, 14, 0.7);
  --footer-background-color: rgba(0, 0, 0, 0.5);
  --submenu-background-color: rgba(14, 14, 14, 0.95);
  --card-color: rgba(255, 255, 255, 0.08);
  --card-second-color: #202020;
  --card-second-border-color: #353434;
  --card-border-color: rgba(255, 255, 255, 0.08);
  --card-over-color: rgba(0, 0, 0, 0.559);
  --special-card-color: rgba(108, 132, 248, 0.2);
  --special-card-color-overlay: rgba(108, 132, 248, 0.5);
  --special-glow-color: rgba(0, 0, 0, 0.5);
  --button-glow-color: rgba(108, 131, 248, 0.5);
  --text-color: #ffffff;
  --button-text-color: rgb(255, 255, 255);
  --faded-text-color: #ffffff12;
  --header-text-color: #818181;
  --header-text-color-hover: #ffffff;
  --para-text-color: #b0b0b0;
  --social-color: #303030;
  --social-link-color: #ffffff;
  --table-color-1: rgba(0, 0, 0, 0);
  --table-color-2: rgba(0, 0, 0, 0.33);
  /* 3VO Colors */
  --gradiant3vo: linear-gradient(90deg, #d129dc 0.08%, #0cdbfd 99.92%);
  --faded-gradiant3vo: linear-gradient(
    90deg,
    rgba(209, 41, 220, 0.3) 0.08%,
    rgba(12, 219, 253, 0.3) 99.92%
  );
  --social-gradiant3vo: linear-gradient(90deg, #d129dc 0.08%, #0cdbfd 99.92%);
  --special3vo: #6c84f8;
  --blue3vo: #01e5ff;
  --purple3vo: #fe01d2;
  --green3vo: #00fe8d;
  --dark-purple3vo: #281931;
  --cyan3vo: #01dfca;
}

/* Breakpoints */
:root {
  --XSS: 320px;
  --SS: 400px;
  --XS: 500px;
  --S: 600px;
  --M: 650px;
  --XM: 768px;
  --L: 850px;
  --XL: 950px;
}

/* Fixed Numbers */
:root {
  --max-width: 1110px;
  --horizantal-padding: 20px;
  --top-padding: 220px var(--horizantal-padding) 0px var(--horizantal-padding);
  --bottom-padding: 0px var(--horizantal-padding) 220px
    var(--horizantal-padding);
  --full-padding: 220px var(--horizantal-padding) 150px
    var(--horizantal-padding);
  --split-padding: 150px 20px;
  --statement-padding: 150px 20px 0px 20px;
  --no-padding: 0px var(--horizantal-padding);
  --card-gap: 25px;
  --team-circle: 180px;
  --roadmap-width: 300px;
  --filler-left: 150px;
  --filler-right: 150px;
}

/* Text Sizes */
:root {
  --main-title-text: 70px;
  --main-subtitle-text: 16px;
  /*  */
  --statement-title-text: 40px;
  --statement-paragraph-text: 16px;
  /*  */
  --card-title-text: 24px;
  --card-paragraph-text: 16px;
  /*  */
  --card-small-title-text: 18px;
  /*  */
  --call-title-text: 56px;
  /*  */
  --button-title-text: 20px;
  --button-subtitle-text: 16px;
  /*  */
  --disclaimer-title-text: 24px;
  --disclaimer-paragraph-text: 14px;
}

@media screen and (max-width: 950px) {
  /* Text Sizes */
  :root {
    --main-title-text: 70px;
    --main-subtitle-text: 16px;
    /*  */
    --statement-title-text: 40px;
    --statement-paragraph-text: 16px;
    /*  */
    --card-title-text: 24px;
    --card-paragraph-text: 16px;
    --card-small-title-text: 16px;
    /*  */
    --call-title-text: 56px;
    /*  */
    --button-title-text: 20px;
    --button-subtitle-text: 16px;
    /*  */
    --disclaimer-title-text: 24px;
    --disclaimer-paragraph-text: 14px;
  }
}

@media screen and (max-width: 850px) {
  /* Text Sizes */
  :root {
    --main-title-text: 62px;
    --main-subtitle-text: 16px;
    /*  */
    --statement-title-text: 40px;
    --statement-paragraph-text: 16px;
    /*  */
    --card-title-text: 24px;
    --card-paragraph-text: 16px;
    --card-small-title-text: 14px;
    /*  */
    --call-title-text: 40px;
    /*  */
    --button-title-text: 20px;
    --button-subtitle-text: 16px;
    /*  */
    --disclaimer-title-text: 24px;
    --disclaimer-paragraph-text: 14px;
  }

  :root {
    --team-circle: 160px;
  }
}

@media screen and (max-width: 768px) {
  /* Text Sizes */
  :root {
    --main-title-text: 62px;
    --main-subtitle-text: 16px;
    /*  */
    --statement-title-text: 40px;
    --statement-paragraph-text: 16px;
    /*  */
    --card-title-text: 24px;
    --card-paragraph-text: 16px;
    /*  */
    --call-title-text: 30px;
    /*  */
    --button-title-text: 20px;
    --button-subtitle-text: 16px;
    /*  */
    --disclaimer-title-text: 20px;
    --disclaimer-paragraph-text: 14px;
  }
  :root {
    --top-padding: 145px var(--horizantal-padding) 0px var(--horizantal-padding);
    --bottom-padding: 0px var(--horizantal-padding) 145px
      var(--horizantal-padding);
    --full-padding: 145px var(--horizantal-padding) 75px
      var(--horizantal-padding);
    --split-padding: 75px 20px;
    --statement-padding: 75px 20px 0px 20px;
    --card-gap: 15px;
    --roadmap-width: 250px;
  }
}

@media screen and (max-width: 650px) {
  /* Text Sizes */
  :root {
    --main-title-text: 54px;
    --main-subtitle-text: 16px;
    /*  */
    --statement-title-text: 40px;
    --statement-paragraph-text: 16px;
    /*  */
    --card-title-text: 20px;
    --card-paragraph-text: 16px;
    /*  */
    --call-title-text: 30px;
    /*  */
    --button-title-text: 20px;
    --button-subtitle-text: 16px;
    /*  */
    --disclaimer-title-text: 20px;
    --disclaimer-paragraph-text: 14px;
  }

  :root {
    --team-circle: 140px;
    --filler-left: 75px;
    --filler-right: 75px;
    --roadmap-width: 220px;
  }
}

@media screen and (max-width: 600px) {
  /* Text Sizes */
  :root {
    --main-title-text: 54px;
    --main-subtitle-text: 16px;
    /*  */
    --statement-title-text: 40px;
    --statement-paragraph-text: 16px;
    /*  */
    --card-title-text: 18px;
    --card-paragraph-text: 16px;
    /*  */
    --call-title-text: 30px;
    /*  */
    --button-title-text: 20px;
    --button-subtitle-text: 16px;
    /*  */
    --disclaimer-title-text: 20px;
    --disclaimer-paragraph-text: 14px;
  }

  :root {
    --roadmap-width: 180px;
  }
}

@media screen and (max-width: 500px) {
  /* Text Sizes */
  :root {
    --main-title-text: 48px;
    --main-subtitle-text: 16px;
    /*  */
    --statement-title-text: 30px;
    --statement-paragraph-text: 16px;
    /*  */
    --card-title-text: 16px;
    --card-paragraph-text: 14px;
    /*  */
    --call-title-text: 30px;
    /*  */
    --button-title-text: 20px;
    --button-subtitle-text: 16px;
    /*  */
    --disclaimer-title-text: 20px;
    --disclaimer-paragraph-text: 14px;
  }
  :root {
    --team-circle: 100px;
    --roadmap-width: 160px;
  }
}

@media screen and (max-width: 450px) {
  :root {
    --roadmap-width: 250px;
    --filler-left: 40px;
    --filler-right: 40px;
  }
}

@media screen and (max-width: 400px) {
  /* Text Sizes */
  :root {
    --main-title-text: 40px;
    --main-subtitle-text: 16px;
    /*  */
    --statement-title-text: 18px;
    --statement-paragraph-text: 16px;
    /*  */
    --card-title-text: 16px;
    --card-paragraph-text: 14px;
    /*  */
    --call-title-text: 28px;
    /*  */
    --button-title-text: 20px;
    --button-subtitle-text: 16px;
    /*  */
    --disclaimer-title-text: 20px;
    --disclaimer-paragraph-text: 14px;
  }

  :root {
    --team-circle: 80px;
  }
}

@media screen and (max-width: 350px) {
  /* Text Sizes */
  :root {
    --main-title-text: 32px;
    --main-subtitle-text: 16px;
    /*  */
    --statement-title-text: 18px;
    --statement-paragraph-text: 14px;
    /*  */
    --card-title-text: 16px;
    --card-paragraph-text: 14px;
    /*  */
    --call-title-text: 24px;
    /*  */
    --button-title-text: 20px;
    --button-subtitle-text: 16px;
    /*  */
    --disclaimer-title-text: 20px;
    --disclaimer-paragraph-text: 14px;
  }

  :root {
    --roadmap-width: 220px;
  }
}
