@import "../../mainVariables.css";

.projectAdvisors {
  position: relative;
  display: flex;
  padding: var(--full-padding);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}

.projectAdvisors-container {
  display: flex;
  width: 100%;
  max-width: var(--max-width);
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: var(--card-gap);
}

.projectAdvisors-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}

.projectAdvisors-title-title {
  color: var(--text-color);
  text-align: center;
  font-size: 70px;

  font-weight: 700;
}

.projectAdvisors-team {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  gap: var(--card-gap);
  align-self: stretch;
}

.projectAdvisors-member {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: var(--card-gap);
  flex: 1 0 0;
  z-index: 1;
}

.projectAdvisors-row {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  gap: var(--card-gap);
  align-self: stretch;
}

.projectAdvisors-member {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: var(--card-gap);
  flex: 1 0 0;
  overflow: hidden;
  height: calc(var(--team-circle));

  border-radius: 15px;
  background: var(--special-card-color);
}

.projectAdvisors-member-photo img {
  display: flex;
  width: var(--team-circle);
  height: var(--team-circle);
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.projectAdvisors-member-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 15px;
  flex: 1 0 0;
  align-self: stretch;
}

.projectAdvisors-member-text-title {
  display: flex;
  height: 75px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
}

.projectAdvisors-member-text-title-name {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;

  color: var(--text-color);
  text-align: center;
  font-size: var(--button-title-text);
  font-weight: 500;
}

.projectAdvisors-member-text-title-title {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;

  color: var(--para-text-color);
  text-align: center;
  font-size: var(--button-subtitle-text);
  font-weight: 400;
}

.projectAdvisors-member-text-desc {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
  align-self: stretch;
  color: var(--para-text-color);
  font-size: calc(var(--card-paragraph-text) - 2px);
  font-weight: 300;
  text-align: left;
}

.projectAdvisors-background {
  position: absolute;
  opacity: 0.1;
  right: -400px;
  top: -200px;
  filter: blur(50px);
  z-index: 0;
}

.projectAdvisors-background img {
  color: var(--gradiant3vo);
  width: 1400px;
}

@media screen and (max-width: 950px) {
  .projectAdvisors-row {
    flex-direction: column;
  }
}

@media screen and (max-width: 850px) {
  .projectAdvisors-title-title {
    font-size: 62px;
  }
}

@media screen and (max-width: 650px) {
  .projectAdvisors-title-title {
    font-size: 54px;
  }

  .projectAdvisors-member-text-title {
    height: 50px;
  }
}

@media screen and (max-width: 500px) {
  .projectAdvisors-member {
    align-items: flex-end;
    width: 100%;
  }

  .projectAdvisors-member-text-desc {
    display: none;
  }

  .projectAdvisors-title-title {
    font-size: 48px;
  }

  .projectAdvisors-comingsoon img {
    width: 160px;
  }

  .projectAdvisors-member-text-title {
    height: fit-content;
  }
}

@media screen and (max-width: 400px) {
  .projectAdvisors-title-title {
    font-size: 42px;
  }
}

@media screen and (max-width: 360px) {
  .projectAdvisors-title-title {
    font-size: 32px;
  }

  .projectAdvisors-button-title {
    font-size: 16px;
  }

  .projectAdvisors-button {
    justify-content: center;
    width: 100%;
    padding: 5px 0px;
  }
}
