@import "../../mainVariables.css";

.mysteryDetails {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  background: var(--card-color);
  padding: 75px 25px;
}

.mysteryDetails-container {
  display: flex;
  padding: 0px 25px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  max-width: 1110px;
  width: 100%;
}

.mysteryDetails-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}

.mysteryDetails-title-tit {
  color: var(--text-color);
  text-align: center;
  font-size: 40px;

  font-weight: 700;
  padding-bottom: 32px;
}

.mysteryDetails-title-tit2 {
  color: var(--text-color);
  text-align: center;
  font-size: 40px;

  font-weight: 700;
}

.what-in-the-box {
  display: flex;
  height: 559px;
  max-width: 1110px;
  align-items: center;
  gap: 25px;
  align-self: stretch;
}

.what-in-the-box-card {
  display: flex;
  padding: 50px 20px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 30px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 50px;
  background: var(--card-color);
}

.what-in-the-box-card-title {
  color: var(--text-color);
  text-align: center;
  font-size: 40px;
  font-weight: 700;
}

.what-in-the-box-card-picture2 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 15px;
}

.what-in-the-box-card-picture2 img {
  width: 50%;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.what-in-the-box-card-picture {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  overflow: hidden;
}

.what-in-the-box-card-picture img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.mysteryBox-title-title {
  color: var(--text-color);
  text-align: center;
  font-size: 70px;
  font-weight: 700;
}

.specific-text {
  background: var(
    --3VO-Gradiant,
    linear-gradient(90deg, #d129dc 0.08%, #0cdbfd 99.92%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.cards-container {
  display: flex;
  padding: 75px 50px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  display: block; /*Replace flex to block to solve glich in slider*/
  height: auto;
  max-width: 1110px;
  padding: 20px 0px;
  align-items: center;
  gap: 25px;
  align-self: stretch;
  width: 100%;
  flex-wrap: wrap;
}

.cards-cardImage {
  width: auto;
}

.cards-cardImage img {
  flex: 1 0 0;
  align-self: stretch;
  height: 100%;
  width: auto;
  max-height: 200px;
}

.mysteryboxfeature {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 25px;
}

@media screen and (max-width: 850px) {
  .mysteryBox-title-title {
    font-size: 62px;
  }

  .what-in-the-box-card-picture2 {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .mysteryDetails-title-tit {
    font-size: 30px;
  }
}

@media screen and (max-width: 650px) {
  .mysteryBox-title-title {
    font-size: 54px;
  }
  .what-in-the-box {
    display: flex;
    flex-direction: column;
    height: auto;
    max-width: 1110px;
    align-items: center;
    gap: 25px;
    align-self: stretch;
  }

  .what-in-the-box-card-picture2 {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    height: 300px;
  }

  .what-in-the-box-card-picture {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    height: 300px;
  }

  .what-in-the-box-card-picture img {
    width: 100%;
    max-height: 381px;
    flex: 1 0 0;
    height: 100%;
  }
}

@media screen and (max-width: 500px) {
  .mysteryBox-title-title {
    font-size: 48px;
  }

  .mysteryBox-comingsoon img {
    width: 160px;
  }
}

@media screen and (max-width: 400px) {
  .mysteryBox-title-title {
    font-size: 42px;
  }
}

@media screen and (max-width: 360px) {
  .mysteryBox-title-title {
    font-size: 32px;
  }
  .mysteryDetails-title-tit {
    font-size: 24px;
  }

  .mysteryBox-button-title {
    font-size: 16px;
  }

  .mysteryBox-button {
    justify-content: center;
    width: 100%;
    padding: 5px 0px;
  }
}
