.Roadmap-section {
  display: flex;
  width: 1036px;
  flex-direction: column;
  align-items: center;
  gap: 48px;
  padding-bottom: 80px;
  padding-top: 125px;
}
.roadmap-title {
  color: var(--Total-white, #fff);

  /* H2 (new) */
  font-family: Poppins;
  font-size: 44px;
  font-style: normal;
  font-weight: 700;
  line-height: 124%; /* 54.56px */
  letter-spacing: 2.64px;
  text-transform: capitalize;
  padding-bottom: 80px;
}
.roadmap-wrapper {
  display: flex;
  width: 1036px;
  flex-direction: column;
  align-items: center;
  gap: 48px;
  padding-bottom: 100px;
}
.roadmap-road {
  /* width: 212px; */
  height: 1345px;
  flex-shrink: 0;
}
.roadmap-containers-wrapper {
  display: flex;
  /* width: 1036px; */
  /* flex-direction: column; */
  align-items: flex-start;
  gap: 20px;
}
.roadmap-card-1 {
  border-radius: 12px;
  background: var(--Bg-cards-buttons, #0f161b);
  display: flex;
  width: 400px;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  margin-top: -75px;
}

.roadmap-card-2 {
  border-radius: 12px;
  background: var(--Bg-cards-buttons, #0f161b);
  display: flex;
  width: 400px;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  margin-top: 137px;
}
.roadmap-card-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}
.roadmap-year {
  color: var(--Accent-Purple, #6c84f8);

  /* Desktop/Headlines/Subtitle */
  font-family: Roboto;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 128%; /* 28.16px */
  letter-spacing: 0.88px;
}
.roadmap-q {
  color: var(--Total-white, #fff);

  /* Desktop/Headlines/Subtitle */
  font-family: Roboto;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 128%; /* 28.16px */
  letter-spacing: 0.88px;
}
.roadmap-pargraph {
  align-self: stretch;
  color: var(--Total-white, #fff);

  /* Phone/Paragraph/Medium */
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 130%; /* 23.4px */
  letter-spacing: 0.72px;
}

.roadmap-right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}
.roadmap-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
  align-self: stretch;
}
.roadmap-card-2R {
  border-radius: 12px;
  background: var(--Bg-cards-buttons, #0f161b);
  display: flex;
  width: 400px;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  margin-top: 160px;
}
.roadmap-card-3R {
  border-radius: 12px;
  background: var(--Bg-cards-buttons, #0f161b);
  display: flex;
  width: 400px;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  margin-top: 160px;
}
.roadmap-card-4R {
  border-radius: 12px;
  background: var(--Bg-cards-buttons, #0f161b);
  display: flex;
  width: 400px;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  margin-top: 250px;
}
.roadmap-card-2L {
  border-radius: 12px;
  background: var(--Bg-cards-buttons, #0f161b);
  display: flex;
  width: 400px;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  margin-top: 195px;
}
.roadmap-card-4L {
  border-radius: 12px;
  background: var(--Bg-cards-buttons, #0f161b);
  display: flex;
  width: 400px;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  margin-top: 220px;
}
.roadmap-containers-wrapper-M {
  display: none;
}
@media (max-width: 576px) {
  .roadmap-card-3R {
    margin-top: -10px !important;
  }
  .roadmap-right {
    padding-top: 10px;
  }
  .roadmap-wrapper {
  }
  .roadmap-containers-wrapper-M {
    /* flex-direction: column; */
    display: block;
    /* align-items: center;  */
    /* gap: 32px; */
    align-items: flex-start;
    /* flex-direction: gir; */
  }
  .roadmap-containers-wrapper {
    display: none;
    /* flex-direction: column;
    align-items: center; */
    /* gap: 32px; */
    /* align-items: flex-start; */
    /* flex-direction: gir; */
  }
  .Roadmap-section {
    width: 100%;
    padding: 0 16px;
    gap: 32px;
    padding-top: 40px;
  }

  .roadmap-title {
    font-size: 37px;
    text-align: center;
    padding-bottom: 40px;
  }

  .roadmap-wrapper {
    width: 100%;
    padding-bottom: 50px;
    flex-direction: row;
  }

  .roadmap-road {
    height: auto;
  }

  /* .roadmap-containers-wrapper {
    flex-direction: column;
    align-items: center;
    gap: 32px;

    align-items: flex-start;
    flex-direction: row;
  } */

  .roadmap-card-1 {
    order: 1;
  }
  .roadmap-card-2L {
    order: 2;
  }
  .roadmap-card-2 {
    /* order: 1; */
  }

  .roadmap-left,
  .roadmap-right {
    align-items: start;
    width: 100%;
  }

  .roadmap-card-1,
  .roadmap-card-2,
  .roadmap-card-2L {
    margin-bottom: 10px;
  }

  .roadmap-card-1,
  .roadmap-card-2,
  .roadmap-card-2L,
  .roadmap-card-2R,
  .roadmap-card-3R,
  .roadmap-card-4R,
  .roadmap-card-4L {
    width: 100%;
    margin-top: 0;
    text-align: center;
    align-items: center;
  }

  .roadmap-card-header {
    justify-content: space-between;
  }

  .roadmap-pargraph {
    font-size: 16px;
    text-align: start;
  }

  .roadmap-road img {
    display: none; /* Hide the roadmap line */
  }
}

@media (max-width: 400px) {
  .roadmap-title {
    font-size: 24px;
  }

  .roadmap-card-1,
  .roadmap-card-2,
  .roadmap-card-2L,
  .roadmap-card-2R,
  .roadmap-card-3R,
  .roadmap-card-4R,
  .roadmap-card-4L {
    width: 100%;
  }

  .roadmap-pargraph {
    font-size: 14px;
  }

  .roadmap-card-header p {
    font-size: 18px;
  }
}
