@import "../../mainVariables.css";

.mysteryBoxMain {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}

.mysteryBoxMain-container {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 75px 20px;
}

.mysteryBoxMain-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}

.mysteryBoxMain-title-tit {
  color: var(--text-color);
  text-align: center;
  font-size: 40px;

  font-weight: 700;
}

.mysteryBox-title-title {
  color: var(--text-color);
  text-align: center;
  font-size: 70px;
  font-weight: 700;
}

.specific-text {
  background: var(
    --3VO-Gradiant,
    linear-gradient(90deg, #d129dc 0.08%, #0cdbfd 99.92%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media screen and (max-width: 850px) {
  .mysteryBox-title-title {
    font-size: 62px;
  }

  .mysteryBoxMain-title-tit {
    font-size: 30px;
  }
}

@media screen and (max-width: 650px) {
  .mysteryBox-title-title {
    font-size: 54px;
  }
}

@media screen and (max-width: 500px) {
  .mysteryBox-title-title {
    font-size: 48px;
  }

  .mysteryBox-comingsoon img {
    width: 160px;
  }
}

@media screen and (max-width: 400px) {
  .mysteryBox-title-title {
    font-size: 42px;
  }
}

@media screen and (max-width: 360px) {
  .mysteryBox-title-title {
    font-size: 32px;
  }
  .mysteryBoxMain-title-tit {
    font-size: 24px;
  }

  .mysteryBox-button-title {
    font-size: 16px;
  }

  .mysteryBox-button {
    justify-content: center;
    width: 100%;
    padding: 5px 0px;
  }
}
