@import "../../mainVariables.css";

.tokenHero {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 90px 20px 0px 20px;
  /*  */
  z-index: 100;
}

.tokenHero-container {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  /*  */
  gap: var(--card-gap);

  width: 100%;
  max-width: var(--max-width);
}

.tokenHero-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: nowrap;

  align-self: stretch;
}

.tokenHero-title-title {
  display: flex;
  flex-direction: row;
  color: var(--text-color);
  text-align: center;
  font-size: var(--call-title-text);
  flex-wrap: nowrap;
  gap: calc(var(--card-gap) / 2);

  font-weight: 700;
  text-align: left;
}

.tokenHero-title-subtitle {
  color: var(--para-text-color);
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
}

.tokenHero-action {
  padding: 15px 0px;
}

.tokenHero-action a {
  text-decoration: none;
  color: var(--button-text-color);
}

.tokenHero-action-button {
  display: flex;
  align-items: center;
  /*  */
  cursor: pointer;
  padding: 5px 80px;
  gap: 6px;
  color: var(--button-text-color);
  border-radius: 20px;
  background: linear-gradient(90deg, #d129dc 0.08%, #0cdbfd 99.92%);

  font-size: 1rem;
  text-wrap: nowrap;
}

.tokenHero-action-button:hover {
  transition: border-radius 0.2s, color 0.2s, filter 0.2s;
  color: var(--background-color);
  filter: drop-shadow(0 0 5px var(--button-glow-color))
    drop-shadow(0 0 10px var(--button-glow-color))
    drop-shadow(0 0 30px var(--button-glow-color));
}

.tokenHero-background {
  display: flex;
  transition: right 0.3s, top 0.3s;
  justify-content: flex-end;
}

.tokenHero-background img {
  width: 80%;
  height: auto;

  filter: drop-shadow(0 0 5px var(--special-glow-color))
    drop-shadow(0 0 10px var(--special-glow-color))
    drop-shadow(0 0 30px var(--special-glow-color));
}

@media screen and (max-width: 950px) {
  .tokenHero-background img {
    width: 100%;
  }
}

@media screen and (max-width: 850px) {
}

@media screen and (max-width: 768px) {
}

@media screen and (max-width: 650px) {
}

@media screen and (max-width: 600px) {
}

@media screen and (max-width: 500px) {
}

@media screen and (max-width: 400px) {
}

@media screen and (max-width: 350px) {
}
