@import "../../mainVariables.css";

.mysteryCall2 {
  display: flex;
  padding: 150px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  align-self: stretch;
}

.mysteryCall2-container {
  position: relative;
  display: flex;
  max-width: 800px;
  flex-direction: column;
  align-items: flex-start;
  gap: 50px;
}

.mysteryCall2-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 0 0;
  align-self: stretch;
  color: var(--text-color);
  text-align: center;
  font-size: 40px;
  font-weight: bold;
  letter-spacing: -0.4px;
  z-index: 1;
}

.mysteryCall2-button {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  width: 100%;
}

.mysteryCall2-button a {
  text-decoration: none;
}

.mysteryCall2-action-button {
  display: flex;
  align-items: center;
  /*  */
  cursor: pointer;
  padding: 5px 80px;
  gap: 6px;
  color: var(--button-text-color);
  border-radius: 20px;
  background: linear-gradient(90deg, #d129dc 0.08%, #0cdbfd 99.92%);
  font-size: 1rem;
  text-wrap: nowrap;
}

.mysteryCall2-action-button:hover {
  transition: border-radius 0.2s, color 0.2s, filter 0.2s;
  color: var(--background-color);
  filter: drop-shadow(0 0 5px var(--button-glow-color))
    drop-shadow(0 0 10px var(--button-glow-color))
    drop-shadow(0 0 30px var(--button-glow-color));
}

.mysteryCall2-background {
  position: absolute;
  opacity: 0.1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
}

.mysteryCall2-background img {
  color: var(--gradiant3vo);
  width: 1600px;
}

@media screen and (max-width: 850px) {
  .mysteryCall2-title {
    font-size: 32px;
  }
}

@media screen and (max-width: 768px) {
  .mysteryCall2-title {
    font-size: 24px;
  }

  .mysteryCall2-background img {
    color: var(--gradiant3vo);
    width: 1200px;
  }
}

@media screen and (max-width: 400px) {
  .mysteryCall2-title {
    font-size: 20px;
  }

  .mysteryCall2-background img {
    color: var(--gradiant3vo);
    width: 800px;
  }
}
