@import "../../mainVariables.css";

.freedomPhotos {
  display: flex;
  padding: var(--split-padding);
  flex-direction: column;
  align-items: center;
  align-self: stretch;
}

.freedomPhotos-container {
  position: relative;
  display: flex;
  height: 600px;
  width: 100%;
  max-width: var(--max-width);
  justify-content: space-between;
  align-items: flex-end;
}

.freedomPhotos-image-middle {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 50px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
}

.freedomPhotos-image-phone {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  align-items: center;
  z-index: 2;
}

.freedomPhotos-image-tablet {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  z-index: 2;
}

.freedomPhotos-image-middle img {
  width: 100%;
  filter: drop-shadow(0px 0px 20px var(--special-glow-color));
}

.freedomPhotos-image-phone img {
  width: auto;
  height: 469.973px;
  filter: drop-shadow(0px 0px 20px var(--special-glow-color));
}

.freedomPhotos-image-tablet img {
  width: auto;
  height: 340px;
  filter: drop-shadow(0px 0px 20px var(--special-glow-color));
}

@media screen and (max-width: 950px) {
  .freedomPhotos-container {
    height: 500px;
  }

  .freedomPhotos-image-phone img {
    height: 375px;
  }

  .freedomPhotos-image-tablet img {
    height: 275px;
  }
}

@media screen and (max-width: 850px) {
  .freedomPhotos-image-phone img {
    height: 325px;
  }

  .freedomPhotos-image-tablet img {
    height: 225px;
  }
}

@media screen and (max-width: 768px) {
  .freedomPhotos-container {
    height: 450px;
  }

  .freedomPhotos-image-phone img {
    height: 275px;
  }

  .freedomPhotos-image-tablet img {
    height: 175px;
  }
}

@media screen and (max-width: 650px) {
  .freedomPhotos-container {
    height: 350px;
  }
}

@media screen and (max-width: 600px) {
  .freedomPhotos-container {
    height: 300px;
  }
}

@media screen and (max-width: 500px) {
  .freedomPhotos-container {
    height: 250px;
  }

  .freedomPhotos-image-phone img {
    height: 225px;
  }

  .freedomPhotos-image-tablet img {
    height: 125px;
  }
}

@media screen and (max-width: 400px) {
  .freedomPhotos-container {
    height: 230px;
  }

  .freedomPhotos-image-phone img {
    height: 200px;
  }

  .freedomPhotos-image-tablet img {
    height: 100px;
  }
}

@media screen and (max-width: 350px) {
  .freedomPhotos-container {
    height: 200px;
  }

  .freedomPhotos-image-phone img {
    height: 150px;
  }

  .freedomPhotos-image-tablet img {
    height: 100px;
  }
}
