.bg-pages {
  background: var(--Bg-Dark, #080d10);
  /* padding-bottom: 80px; */
  overflow: hidden;
}
.new-header {
  /* background: rgba(8, 13, 16, 0.88); */
  background: var(--Bg-Dark, #080d10);
  /* backdrop-filter: blur(20px); */
  display: flex;
  /* width: 1440px; */
  padding: 20px 0px;
  justify-content: center;
  align-items: center;
  gap: 237px;
  /* padding-bottom: 80px; */
}
.header-main {
  display: flex;
  /* width: 1247px; */
  justify-content: space-between;
  align-items: center;
  /* flex-shrink: 0; */
  width: 100%;
  max-width: 1247px;
}
.nav-logo {
  /* width: 188px; */
  height: 64px;
  flex-shrink: 0;
}
.sections {
  display: flex;
  align-items: center;
  gap: 40px;
}
.sections-text-wrapper {
  color: var(--slightly-light-for-buttons, #dadbdb);
  text-align: center;

  /* Desktop/Buttons/Header */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 21.6px */
  letter-spacing: 0.32px;
}
.sections-text-wrapper:hover {
  font-weight: 600;
  font-size: 17px;
}
.lunchApp-btn {
  width: 188px;
  height: 40px;
  flex-shrink: 0;
}
.header-burger-menu {
  display: none;
  padding-left: 20px;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  align-self: stretch;
  z-index: 9999;
}

.header-burger-menu-icon {
  stroke: white;
  color: white;
  display: flex;
  height: 30px;
  padding-right: var(--horizantal-padding);
}
/* @media screen and (max-width: 570px) {
  .header-burger-menu {
    display: flex;
    margin-right: 9px;
    padding-top: 29px;
    z-index: 10001;
    position: sticky;
  }
} */
@media (max-width: 400px) {
  .nav-logo {
    height: 50px;
    padding-left: 13px;
  }
  .header-burger-menu {
    padding-top: 10px;
  }

  .header-innerlogo-new {
    display: flex;
    /* margin-top: -294px; */
    margin-top: -258px;
    /* margin-top: -320px; */
    height: 47px;
    /* height: 43px; */
    margin-left: -167px;
  }

  .mobile-nav-m {
    display: grid;
    gap: 30px;
    margin-top: -110px;
  }
}
@media screen and (max-width: 450px) and (min-width: 401px) {
  .nav-logo {
    width: 72px;
    height: 64px;
    padding-left: 10px;
    padding-top: 10px;
  }

  .header-burger-menu {
    display: flex;
    padding-top: 10px;
  }

  .header-innerlogo-new {
    display: flex;
    /* margin-top: -193px; */
    margin-top: -262px;
    height: 43px;
    margin-left: -186px;
  }

  .mobile-nav-m {
    display: grid;
    gap: 40px;
  }
  .submenu-m {
    text-decoration: none;
    padding: 10px 0;

    color: var(--Total-white, #fff);
    text-align: center;

    /* Phone/Buttons/Menu Buttons */
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 135%; /* 24.3px */
    letter-spacing: 0.36px;
  }
}
@media (max-width: 768px) {
  /* .header-innerlogo {
    display: flex;
    margin-top: -215px;
    height: 43px;
    margin-left: -160px;
  } */
  /* .nav-logo {
    height: 50px;
  } */
  .lunchApp-btn img {
    display: none;
  }
  .sections {
    display: none;
  }

  .header-burger-menu {
    display: flex;
  }

  .header-mobile-menu {
    position: absolute;
    top: 60px;
    right: 0;
    width: 100%;
    background: rgba(8, 13, 16, 0.95);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  /* .mobile-nav-m {
    display: grid;
    gap: 30px;
  } */

  .submenu-m {
    text-decoration: none;
    padding: 10px 0;

    color: var(--Total-white, #fff);
    text-align: center;

    /* Phone/Buttons/Menu Buttons */
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 135%; /* 24.3px */
    letter-spacing: 0.36px;
  }
}
