@import "../../mainVariables.css";

.socialCards {
  display: flex;
  padding: var(--split-padding);
  align-items: center;
  align-self: stretch;
  justify-content: center;
}

.socialCards-container {
  display: flex;

  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: stretch;

  width: 100%;
  max-width: var(--max-width);
  gap: var(--card-gap);
}

.socialCards-feature {
  display: flex;
  justify-content: center;
  align-self: stretch;
  height: 210px;

  border-radius: 15px;
  background: var(--card-color);
}

.socialCards-text {
  display: flex;
  padding: 5px 100px 5px 25px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  flex: 1 0 0;
  align-self: stretch;
  border-radius: 5px;
  width: 50%;
}

.socialCards-text-r {
  display: flex;
  padding: 5px 25px 5px 100px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;
  flex: 1 0 0;
  width: 100%;
  align-self: stretch;
  width: 50%;
}

.socialCards-text-title {
  color: var(--text-color);
  font-size: var(--card-title-text);
  font-weight: 600;
  align-self: stretch;
  text-align: left;
}

.socialCards-text-description {
  color: var(--para-text-color);
  font-size: var(--card-paragraph-text);
  font-weight: 400;
  align-self: stretch;
  text-align: left;
}

.socialCards-image-container-third-border {
  display: flex;
  padding-right: 25px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
  align-self: stretch;
  width: 50%;
}

.socialCards-image-container-fourth-border {
  display: flex;
  padding-left: 25px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
  align-self: stretch;
  width: 50%;
}

.socialCards-image-container-third {
  display: flex;
  height: 120px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  align-self: stretch;
  overflow: hidden;

  border-radius: 15px;
  border: 1px solid var(--card-second-border-color);
  background: var(--card-second-color);
}

.socialCards-image-container-third img {
  height: 100%;
}

.socialCards-image-container-fourth {
  display: flex;
  height: 120px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  align-self: stretch;
  overflow: hidden;

  border-radius: 15px;
  border: 1px solid var(--card-second-border-color);
  background: var(--card-second-color);
}

.socialCards-image-container-fourth img {
  height: 100%;
}

@media screen and (max-width: 950px) {
  .socialCards-image-first img {
    height: 60px;
  }

  .socialCards-image-third img {
    height: 60px;
  }

  .socialCards-image-special img {
    height: 40px;
  }
}

@media screen and (max-width: 850px) {
}

@media screen and (max-width: 768px) {
  .socialCards-text {
    padding: 5px 25px 5px 25px;
  }

  .socialCards-text-r {
    padding: 5px 25px 5px 25px;
  }
}

@media screen and (max-width: 650px) {
  .socialCards-image-first img {
    height: 50px;
  }

  .socialCards-image-third img {
    height: 50px;
  }

  .socialCards-image-special img {
    height: 30px;
  }
}

@media screen and (max-width: 600px) {
}

@media screen and (max-width: 550px) {
  .socialCards-feature {
    flex-direction: column;
    height: auto;
  }

  .socialCards-image-container-fourth {
    align-items: center;
  }

  .socialCards-image-container-fourth-border {
    padding: 15px 15px 15px 15px;
    width: auto;
  }

  .socialCards-text {
    padding: 0px 15px 15px 15px;
    width: auto;
  }

  .socialCards-text-r {
    padding: 0px 15px 15px 15px;
    width: auto;
  }

  .socialCards-text-title {
    text-align: center;
  }

  .socialCards-text-description {
    text-align: center;
  }
}

@media screen and (max-width: 400px) {
}

@media screen and (max-width: 350px) {
}
