@import "../mainVariables.css";

.subpage {
  text-align: center;
  background: var(--background-color);
  overflow: hidden;
}

.embed {
  position: relative;
  display: flex;
  padding: 150px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  align-self: stretch;
}

.redirect-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  height: 100dvh;
}

.redirect-page button {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  z-index: 999;
}

.redirect-image {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100dvh;
  background-image: url("../assets/images/mysteryBox.webp");
  background-size: cover;
  background-position: center;
  overflow: hidden;
}

.callToAction-action-button2 {
  display: flex;
  align-items: center;
  /*  */
  cursor: pointer;
  padding: 10px 20dvw;
  gap: 6px;
  color: var(--button-text-color);
  border-radius: 20px;
  background: linear-gradient(90deg, #6c84f8 0.08%, #6c84f8 99.92%);
  font-size: 1rem;
  text-wrap: nowrap;
  border: none;
}

.callToAction-action-button2:hover {
  transition: border-radius 0.2s, color 0.2s, filter 0.2s;
  color: var(--background-color);
  filter: drop-shadow(0 0 5px var(--button-glow-color))
    drop-shadow(0 0 10px var(--button-glow-color))
    drop-shadow(0 0 30px var(--button-glow-color));
}

.embed-container {
  display: flex;
  flex-direction: column;

  width: 100%;
  max-width: var(--max-width);

  justify-content: space-between;
  z-index: 2;
}

.b24-form-control-container {
  display: flex !important;
}

.embed-container.loading {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1110px;
  height: 500px;
  justify-content: space-between;
}

.embed-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  width: 100%;
  z-index: 1;

  color: var(--text-color);

  font-size: 70px;

  font-weight: 700;
}

.embed-form-title {
  padding-top: 50px;
  display: flex;
  align-items: center;
  gap: 15px;
  width: 100%;
  z-index: 1;

  color: transparent;
  background: var(--gradiant3vo);
  -webkit-background-clip: text;
  background-clip: text;
  font-size: 20px;
  font-weight: 700;
}

.embed-form-description {
  display: flex;
  flex-direction: column;

  width: 100%;

  text-align: left;
  color: var(--para-text-color);
  font-size: 18px;
  font-weight: 700;
}

.embed-loading {
  height: 80px;
  width: 80px;
  animation: spin 2s linear infinite;
  border-radius: 100px;
  overflow: visible;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.embed-title img {
  height: 70px;
}

.embed-para {
  display: flex;
  padding: 5px 0px;
  flex-direction: column;
  align-items: center;
  flex: 1 0 0;
  z-index: 1;

  text-align: left;
  color: var(--para-text-color);

  font-size: 16px;

  font-weight: 400;
}

.embed-privacy {
  display: flex;
  padding: 5px 0px;
  flex-direction: column;
  flex: 1 0 0;
  z-index: 1;

  text-align: left;
  color: var(--para-text-color);
  font-size: 16px;
  font-weight: 200;
}

.embed-privacy-inner-titles {
  color: var(--text-color);
}

.embed-date {
  padding-bottom: 50px;
  text-align: left;
  color: var(--text-color);
  font-size: 16px;
  font-weight: 400;
}

.embed-background {
  position: absolute;
  opacity: 0.1;
  right: 540px;
  top: 0;
  filter: blur(25px);
  z-index: 0;
}

.embed-background img {
  color: var(--gradiant3vo);
  width: 1339px;
}

.embed .b24-form-content {
  margin: 0;
  padding: 0px 0;
}

.embed .b24-form-wrapper.b24-form-border-bottom {
  border-bottom: none;
}

.embed .b24-form-wrapper {
  width: 100%;
  background-color: transparent;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0 0;
  max-width: none;
  border-bottom: none;
  box-shadow: none;
}

.embed .b24-form-basket {
  border: none;
}

.embed .b24-form-wrapper.b24-form-shadow {
  box-shadow: none;
}

.embed .b24-form-btn-block {
  display: flex;
  justify-content: flex-end;
}

.embed .b24-form-control-label {
  color: var(--text-color);
  display: flex;
  justify-content: flex-start;
}

.embed .b24-form-control-file-item-empty {
  display: flex;
  justify-content: flex-start;
}

.embed .b24-form-control-alert-message {
  background: var(--gradiant3vo);
}

.embed .b24-form-control-alert-message::after {
  border: 3px solid var(--gradiant3vo);
}

.embed .b24-form-btn {
  width: auto;
  justify-self: flex-end;
  color: var(--button-text-color);
  background: var(--gradiant3vo);
  border-radius: 15px;
  padding: 5px 80px;
}

.embed .b24-form-sign {
  display: none;
}

.embed .b24-form-control-string .b24-form-control,
.b24-form-control-list .b24-form-control,
.b24-form-control-text .b24-form-control,
.b24-form-control-alert,
.b24-form-control-list,
.b24-form-control-select .field-item {
  background-color: var(--card-color);
}

.embed .b24-form-control-file {
  background-color: var(--card-color);
}

.embed .b24-form-control-comment {
  color: var(--para-text-color);
}

.embed .b24-form-control-file .b24-form-control {
  border-color: var(--card-border-color);
  color: var(--para-text-color);
}

.b24-form-control-container {
  background: var(--card-color);
}

.b24-form-control-list-selector-item {
  text-align: left;
  background-color: var(--card-color) !important;
}

.comp-result {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.comp-result-search {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.comp-current {
  padding-top: 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 25px;
}

.comp-current a {
  text-decoration: none;
}

.comp-quest-active {
  background-color: var(--special-card-color);
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  width: 30%;

  cursor: pointer;
  overflow: hidden;
}

.comp-quest-title {
  color: var(--text-color);
  padding: 15px;
  font-weight: 500;
}

.comp-quest-done {
  background-color: var(--card-color);
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  width: 30%;

  cursor: default;
  overflow: hidden;
}

.comp-quest-done .comp-quest-title {
  color: var(--para-text-color);
  padding: 15px;
  font-weight: 500;
}

.comp-quest-done img {
  filter: grayscale(1);
}

.comp-result-search-captcha-notdone {
  display: flex;
  align-items: center;
  justify-content: center;
}

.singleusecase-image-title {
  color: var(--text-color);
  text-align: left;
  width: 1110px;
}

.singleusecase-image-title p {
  color: var(--text-color);
  text-align: left;
  width: 100%;
}

.comp-result-search p {
  color: var(--text-color);
  font-weight: 500;
}

.singleUsecasePhoto-container {
  padding-top: 25px;
  filter: drop-shadow(0 0 5px var(--special-card-color))
    drop-shadow(0 0 0px var(--special-card-color))
    drop-shadow(0 0 0px var(--special-card-color));
}

.comp-result-searchbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.comp-result-searchbar input {
  background-color: var(--card-color);
  color: var(--text-color);
  border: none;
  height: 50px;
  border-radius: 10px 0px 0px 10px;
  width: 100%;
  padding-left: 20px;
}

.comp-result-searchbar input::placeholder {
  color: var(--para-text-color);
}

.comp-result-searchbar button:disabled {
  background: var(--special-card-color);
  border-radius: 0px 10px 10px 0px;
  border: solid 2px #00000000;
  font-size: 14px;
  color: var(--para-text-color);
  cursor: pointer;
  height: 50px;
  width: 200px;
  cursor: not-allowed;
  animation: builton-glow3 0s infinite alternate;
}

.comp-result-searchbar button {
  background: var(--special3vo);
  border-radius: 0px 10px 10px 0px;
  border: solid 2px #00000000;
  font-size: 14px;
  color: var(--text-color);
  cursor: pointer;
  height: 50px;
  width: 200px;
  animation: builton-glow3 1s infinite alternate;
}

.voucher-rules {
  width: 100%;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  margin-left: 15px;
  margin-right: 15px;
  color: var(--para-text-color);
  font-weight: lighter;
  text-align: justify;
}

.comp-result-search-captcha-done {
  display: none;
}

.voucher {
  width: 100%;
}

.winner-message {
  color: #00fd92;
}

.non-winner-message {
  color: #ff0000;
}

@media screen and (max-width: 768px) {
  .embed-title {
    font-size: 50px;
  }

  .embed-title img {
    height: 50px;
  }
}

@media screen and (max-width: 550px) {
  .comp-quest-active {
    background-color: var(--special-card-color);
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    width: 100%;

    cursor: pointer;
    overflow: hidden;
  }

  .comp-quest-done {
    background-color: var(--card-color);
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    width: 100%;

    cursor: default;
    overflow: hidden;
  }
}

@media screen and (max-width: 500px) {
  .embed-title {
    font-size: 30px;
  }

  .embed-title img {
    height: 30px;
  }
}

@media screen and (max-width: 350px) {
  .embed-title {
    font-size: 30px;
    justify-content: center;
  }

  .embed-title img {
    height: 30px;
  }
}
