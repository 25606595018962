:root {
  --header-tec-font-family: "Ligconsolata", Helvetica;
  --header-tec-font-size: 18px;
  --header-tec-font-style: normal;
  --header-tec-font-weight: 400;
  --header-tec-letter-spacing: 1.8px;
  --header-tec-line-height: 135.0000023841858%;
  --text-2: rgba(194, 194, 194, 0.75);
}

.hero-page {
  background-color: #080d10;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  max-height: 100vh;
}

.hero-page .overlap-wrapper {
  background-color: #080d10;
  /* height: 100vh; */
  height: 650px;
  width: 1440px;
}

.hero-page .frame-2,
.hero-page .frame-3,
.hero-page .frame-4 {
  flex-direction: column;
  flex-grow: 1;
}

.hero-page .overlap {
  background-image: url(https://c.animaapp.com/sPLUNEAp/img/group-315.png);
  background-size: cover;
  height: auto;
  max-height: 80vh;
  position: relative;
}

.hero-page .header {
  background-color: transparent;
  height: 43px;
  left: 96px;
  position: absolute;
  top: 34px;
  width: 1248px;
}

.hero-page .logo {
  height: 50px;
  left: -15px;
  position: absolute;
  top: -5px;
  width: 108px;
}
/* .hero-page .logo {
  height: 50px;
  left: -5px;
  position: absolute;
  top: -12px;
  width: 108px;
} */

.hero-page .navbar {
  font-family: "Inter";
  align-items: center;
  display: inline-flex;
  gap: 40px;
  left: 306px;
  position: absolute;
  top: 10px;
}

.hero-page .text-wrapper {
  color: var(--slightly-light-for-buttons, #dadbdb);
  text-align: center;
  /* Desktop/Buttons/Header */
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 21.6px */
  letter-spacing: 0.32px;
}

.hero-page .lunchapp {
  height: 36px;
  left: 1085px;
  position: absolute;
  top: 4px;
  width: 163px;
}

.hero-page .overlap-group {
  background-image: url(../../assets/svg/button-stroke.svg);
  width: 188px;
  height: 40px;
  left: -28px;
  position: relative;
  top: -1px;
  cursor: pointer;
  overflow: hidden;
  display: inline-block;
}

.hero-page .overlap-group:before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%; /* Start off-screen to the left */
  width: 100%;
  height: 100%;
  background-color: #7323c140; /* Slide effect color */
  transition: all 0.4s ease-in-out; /* Smooth movement */
  clip-path: inherit;
}

.hero-page .overlap-group:hover::before {
  left: 0;
  clip-path: inherit;
  border-bottom: 30px solid transparent; /* Adjust size as needed */
  border-left: 30px solid inherit; /* Adjust size as needed */
}

.hero-page .overlap-group:hover::after {
  border-top: 30px solid transparent; /* Adjust size as needed */
  border-right: 30px solid inherit; /* Adjust size as needed */
}

.hero-page .overlap-group:active {
  transform: scale(0.9);
  transition: all 0.5s ease-in-out, background-color 0.5s ease; /* Smooth movement */
}

.hero-page .overlap-group:active {
  background-color: #ffffff; /* Button turns white on click */
  animation: fade 0.8s ease-in-out; /* Triggers fade animation */
  color: #ffffff; /* Optional: Change text color to match background */
  clip-path: inherit;
}
.hero-page .overlap-group .div:active {
  color: var(
    --Accent-blue
  ); /* Optional: Change text color to match background */
  clip-path: inherit;
}

@keyframes fade {
  0%,
  100% {
    opacity: 0; /* Fades out */
  }
  50% {
    opacity: 2; /* Fades in */
  }
}

.hero-page .div {
  color: #ffffff;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  left: 42px;
  letter-spacing: 0;
  line-height: 22.4px;
  position: absolute;
  top: 7px;
  white-space: nowrap;
}

.hero-page .frame-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 44px;
  left: 96px;
  position: absolute;
  top: 200px;
  width: 718px;
}

.hero-page .frame-3 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 28px;
  position: relative;
  width: 100%;
}

.hero-page .frame-4 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12px;
  position: relative;
  width: 100%;
}

.hero-page .text-wrapper-2 {
  /* color: #7b00dd; */
  color: var(--Accent-blue);
  font-family: Inter;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 22.1px;
  margin-top: -1px;
  text-align: justify;
  position: relative;
  width: 613px;

  /* Animation */
  transform: translateX(-100%); /* Initially off-screen to the left */
  animation: slide-in 0.7s ease-out forwards;
}

@keyframes slide-in {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

.hero-page .the-future-of-social {
  position: relative;
  color: var(--Total-white, #fff);
  /* H1 (new) */
  padding-right: 51px;
  text-align: start;
  font-family: "Poppins";
  font-size: 78px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 85.8px */
  letter-spacing: 4.68px;
  text-transform: capitalize;

  opacity: 0; /* Start completely hidden */
  animation: reveal-up 1.5s ease-in-out forwards;
}

@keyframes reveal-up {
  0% {
    bottom: -100%; /* Hidden below */
    /* opacity: 0; Invisible */
  }

  100% {
    bottom: 0; /* Fully visible */
    opacity: 1; /* Fully opaque */
  }
}
.Cta-1 {
  display: flex;
  width: 188px;
  padding: 8px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.cta {
  width: 188px;
  height: 162.514px;
  transform: rotate(-50deg);
  flex-shrink: 0;
  background: var(--Accent-blue);
}

.hero-page .the-platform-for-the {
  align-self: flex-start;
  color: var(--text-2);
  font-family: "Inter";
  font-size: 21px;
  font-weight: 400;
  letter-spacing: 4.2px;
  line-height: 27.5px;
  position: relative;
  text-align: start;

  transform: translateX(-100%); /* Initially off-screen to the left */
  animation: slide-in 0.6s ease-out forwards;
}

.hero-page .the-platform-for-the .para {
  color: var(--new-secondary-light-new, rgba(238, 238, 238, 0.9));
  padding-right: 32px;
  font-family: "Roboto";
  font-size: 19px;
  font-style: normal;
  font-weight: 300;
  line-height: 148%;
  letter-spacing: 0.76px;
  padding-right: 40px;
}

.hero-page .frame-5 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 28px;
  position: relative;

  transform: translateY(100%); /* Initially off-screen at the bottom */
  animation: slide-up 0.4s ease-in forwards;
}

@keyframes slide-up {
  0% {
    transform: translateY(250%);
  }
  100% {
    transform: translateY(0);
  }
}

.hero-page .div-wrapper {
  background-image: url(../../assets/Hero/CTA-Button-join.svg);
  width: 188px;
  height: 40px;
  cursor: pointer;
}

.hero-page .div-wrapper:before {
  content: "";

  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background-color: #7323c140;
  transition: all 0.4s ease-in-out;
  clip-path: inherit;
}

.hero-page .div-wrapper:hover::before {
  left: 0;
  clip-path: inherit;
  border-bottom: 30px solid transparent; /* Adjust size as needed */
  border-left: 30px solid inherit; /* Adjust size as needed */
}

.hero-page .div-wrapper:hover::after {
  border-top: 30px solid transparent; /* Adjust size as needed */
  border-right: 30px solid inherit; /* Adjust size as needed */
}

.hero-page .div-wrapper:active {
  transform: scale(0.9);
  transition: all 0.5s ease-in-out, background-color 0.5s ease; /* Smooth movement */
}

.hero-page .div-wrapper:active {
  background-color: #ffffff; /* Button turns white on click */
  animation: fade 0.8s ease-in-out; /* Triggers fade animation */
  color: #ffffff; /* Optional: Change text color to match background */
  clip-path: inherit;
}
.hero-page .div-wrapper .text-wrapper-3:active {
  color: var(
    --Accent-blue
  ); /* Optional: Change text color to match background */
  clip-path: inherit;
}

.div-wrapper-2 .cta-color {
  background-image: url(../../assets/svg/join-pre-sale.svg);
  width: 188px;
  height: 40px;
  cursor: pointer;
}
.div-wrapper-1 .cta-color {
  background-image: url(../../assets/svg/join-pre-sale.svg);
  width: 188px;
  height: 40px;
  cursor: pointer;
}

/* Start  of Create Account Button*/
.div-wrapper-2 .cta-2 {
  background-image: url(../../assets/svg/buuton-stroke-get.svg);
  width: 188px;
  height: 40px;
  cursor: pointer;
  z-index: 1;
  background-size: cover;
  overflow: hidden;
  display: flex;
  justify-content: center;
  /* display: inline-block; */
}
.text-wrapper-cta {
  color: var(--Total-white, #fff);
  font-family: "Inter";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 136%;
  letter-spacing: 0.36px;
  /* padding-top: 7px; */
}
/*start of 2nd edit*/
.div-wrapper-2 {
  position: relative;
  overflow: hidden;
}

.div-wrapper-2::before {
  content: "";
  position: absolute;
  /* left: 0;
  right: 0; */
  left: -100%; /* Start off-screen to the left */
  width: 100%;
  height: 100%;
  /* bottom: 0; */
  top: 0;
  z-index: -1;
  background-color: #7323c140; /* Slide effect color */
  transition: all 0.35s ease-in-out; /* Smooth movement */
  clip-path: inherit;
  /* transition: transform 300ms ease-in-out; */
  background-color: #7323c140;
  /* transform: scale(0) rotate(-3deg);
  transform-origin: left top; */
}

/* .div-wrapper-2:hover::before,
.div-wrapper-2:focus::before {
  left: 0;
  clip-path: inherit;
  border-bottom: 30px solid transparent;  
  transform: scale(1);
} */

/* .div-wrapper-2:hover,
.div-wrapper-2:focus {
  color: white;
} */

/* .div-wrapper-2:active {
  transform: scale(0.95);
  transition: all 0.5s ease-in-out, background-color 0.5s ease;
  background-color: #ffffff;
  animation: fade 0.8s ease-in-out;
  color: #ffffff;
} */

.div-wrapper-2 .text-wrapper-cta {
  color: var(--Total-white, #fff);
  font-family: "Inter";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 136%;
  letter-spacing: 0.36px;
  padding-top: 7px;
  z-index: 2;
  position: relative;
}

.div-wrapper-2 .text-wrapper-cta:active {
  color: var(--Accent-blue);
  clip-path: inherit;
}

.div-wrapper-1 .text-wrapper-cta {
  color: var(--Total-white, #fff);
  font-family: "Inter";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 136%;
  letter-spacing: 0.36px;
  padding-top: 7px;
}

.hero-page .text-wrapper-3 {
  color: #ffffff;
  font-family: "Inter";
  font-size: 18px;
  font-weight: 700;
  left: 33px;
  letter-spacing: 0.36px;
  line-height: 21.6px;
  position: absolute;
  top: 8px;
  white-space: nowrap;
}

.hero-page .frame-6 {
  align-items: center;
  display: inline-flex;
  gap: 162px;
  left: 96px;
  position: absolute;
  top: 598px;
  padding-top: 30px;
}

.hero-page .frame-7 {
  align-items: center;
  display: flex;
  gap: 12px;
  position: relative;
  width: 287px;
}

.hero-page .text-wrapper-4 {
  color: #ffffff;
  font-family: "Inter";
  font-size: 40px;
  display: flex;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 52.5px;
  margin-top: -1px;
  position: relative;

  white-space: nowrap;
  width: fit-content;
}

.hero-page .text-wrapper-5 {
  color: var(--Accent-blue);
  font-family: Inter;
  font-size: 16px;

  font-weight: 600;
  line-height: 124%;
  position: relative;
  white-space: nowrap;
  width: fit-content;

  transform: translateY(100%); /* Initially off-screen at the bottom */
  animation: slide-up 0.4s ease-in forwards;
}

.hero-page .frame-8 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 12px;
  position: relative;
}

.hero-page .overlap-2 {
  height: 636px;
  left: 911px;
  position: absolute;
  top: 131px;
  width: 506px;
}

.hero-page .frame-9 {
  height: 625px;
  left: 0;
  position: absolute;
  top: 0;
  width: 506px;
}
