.faq-section {
  height: 950px;
  width: 100%;
  background: var(--Bg-Dark, #080d10);
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 31.947px;
  padding-left: 134px;
  padding-right: 95px;
}
.faq-title {
  /* color: var(--Total-white, #fff);
  font-feature-settings: "ss01" on;
  font-family: "Michroma";
  font-size: 26px;
  font-weight: 400;
  line-height: 122%;  
  letter-spacing: 1.56px; */
  background-color: #080d10;
  color: var(--Total-white, #fff);

  font-family: "Roboto";
  font-size: 44px;
  font-style: normal;
  font-weight: 700;
  line-height: 124%; /* 54.56px */
  letter-spacing: 2.64px;
  text-transform: capitalize;
  text-align: start;
  padding-left: 145px;
}
/* .btn-1 {
  margin-left: 725px;
} */
.faq-cards {
  display: flex;
  width: 1035px;
  flex-direction: column;
  align-items: flex-end;
  gap: 16px;
}
.faq-cards-1 {
  border-radius: 12px;
  border: 1px solid var(--Accent-blue);
  background: var(--Accent-blue);
  display: flex;
  padding: 32px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
}
/* .faq-card-header {
  display: flex;
  height: 28px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
} */
.faq-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  cursor: pointer;
}
.faq-card-paragraph {
  color: var(--Total-white, #fff);
  text-align: start;
  /* Desktop/Headlines/Subtitle */
  font-family: Inter;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 128%; /* 28.16px */
  letter-spacing: 0.88px;
  width: 923px;
}
.faq-btn-wrapper {
  border-radius: 50px;
  background: var(--Bg-cards-buttons, #0f161b);
  display: flex;
  width: 40px;
  height: 40px;
  transform: rotate(90deg);
  padding: 20px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.faq-btn {
  width: 16px;
  height: 16px;
  transform: rotate(-90deg);
  flex-shrink: 0;
}
.faq-card-answers {
  color: var(--Total-white, #fff);
  text-align: start;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 125%;
  letter-spacing: 0.64px;
  /* width: 787px; */
}
.faq-cards-2 {
  border-radius: 12px;
  border: 1px solid var(--Secondary-Light, #adadad);
  display: flex;
  padding: 32px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
}
.faq-card-header-2 {
  display: flex;
  height: 28px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
/* ##################################################### */
.faq-card {
  border-radius: 12px;
  border: 1px solid var(--Secondary-Light, #adadad);
  display: flex;
  padding: 32px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  background: var(--Bg-Dark, #080d10);
  transition: background 0.3s ease, border-color 0.3s ease;
}
.faq-card:hover {
  /* border-radius: 12px;
  border: 1px solid var(--Secondary-Light, #adadad);
  display: flex;
  padding: 32px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  background: var(--Bg-Dark, #080d10); */
  background: var(--Accent-blue);
  /* transition: background 0.3s ease, border-color 0.3s ease; */
}

.faq-card-open {
  background: var(--Accent-blue);
  border-color: var(--Accent-blue);
}

.faq-card-closed {
  background: var(--Bg-Dark, #080d10);
  border-color: var(--Secondary-Light, #adadad);
}
/* Responsive Styles for Mobile Devices (360px to 800px) */
@media screen and (max-width: 800px) {
  .faq-section {
    height: auto;
    padding-left: 20px;
    padding-right: 20px;
    gap: 20px;
    padding-bottom: 50px;
  }

  .faq-title {
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 1px;
    text-align: center;
    margin-bottom: 16px;
  }

  .faq-cards {
    width: 100%;
    gap: 12px;
  }

  .faq-card {
    padding: 24px 16px;
    gap: 16px;
  }

  .faq-card-paragraph {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.8px;
    width: 100%;
  }

  .faq-btn-wrapper {
    width: 32px;
    height: 32px;
    padding: 10px;
  }

  .faq-btn {
    width: 14px;
    height: 14px;
  }

  .faq-card-answers {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.5px;
    width: 100%;
  }
}

@media screen and (max-width: 400px) {
  .faq-section {
    padding-left: 15px;
    padding-right: 15px;
    margin-top: -17px;
    padding-top: 24px;
  }

  .faq-title {
    /* font-size: 18px; */
    /* line-height: 24px; */
    /* letter-spacing: 0.8px; */
    color: var(--Total-white, #fff);
    font-feature-settings: "ss01" on;
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 122%;
    letter-spacing: 1.92px;
    text-transform: capitalize;
    text-align: start;
    margin-left: -125px;
  }

  .faq-card {
    padding: 20px 12px;
  }

  .faq-card-paragraph {
    font-size: 16px;
    line-height: 22px;
  }

  .faq-btn-wrapper {
    width: 28px;
    height: 28px;
  }

  .faq-btn {
    width: 12px;
    height: 12px;
  }

  .faq-card-answers {
    font-size: 12px;
    line-height: 18px;
  }
}
@media screen and (max-width: 450px) and (min-width: 401px) {
  .faq-section {
    height: auto;
    padding-left: 20px;
    padding-right: 20px;
    gap: 20px;
    padding-bottom: 50px;
  }

  .faq-title {
    /* font-size: 32px;
    line-height: 122%;
    letter-spacing: 1.92px;
    text-align: center;
    margin-left: 0; */
    font-size: 40px;
    line-height: 122%;
    letter-spacing: 1.92px;
    text-align: center;
    margin-left: -407px;
  }

  .faq-cards {
    /* width: 100%;
    gap: 12px; */
    width: 89%;
    gap: 12px;
    margin-right: 26px;
  }

  .faq-card {
    padding: 24px 16px;
    gap: 16px;
  }

  .faq-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    cursor: pointer;
  }

  .faq-card-paragraph {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.8px;
    width: 100%;
  }

  .faq-btn-wrapper {
    width: 32px;
    height: 32px;
    padding: 10px;
  }

  .faq-btn {
    width: 14px;
    height: 14px;
  }

  .faq-card-answers {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.5px;
    width: 100%;
    display: none; /* Initially hidden */
  }

  /* ✅ Show answer when opened */
  .faq-card-open .faq-card-answers {
    display: block;
  }

  /* ✅ Rotate icon when section opens */
  .faq-card-open .faq-btn {
    transform: rotate(180deg);
  }
}

/* ✅ Fix for desktop (≥ 451px) */
/*   */
