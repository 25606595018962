@import "../../mainVariables.css";

.defiFeatures {
  display: flex;
  padding: var(--split-padding);
  align-items: center;
  align-self: stretch;
  justify-content: center;
}

.defiFeatures-container {
  display: flex;

  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: stretch;

  width: 100%;
  max-width: var(--max-width);
  gap: var(--card-gap);
}

.defiFeatures-feature {
  display: flex;
  justify-content: center;
  align-self: stretch;
  height: 210px;

  border-radius: 15px;
  background: var(--card-color);
}

.defiFeatures-text {
  display: flex;
  padding: 5px 100px 5px 25px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  flex: 1 0 0;
  align-self: stretch;
  border-radius: 5px;
  width: 50%;
}

.defiFeatures-text-r {
  display: flex;
  padding: 5px 25px 5px 100px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;
  flex: 1 0 0;
  width: 100%;
  align-self: stretch;
  width: 50%;
}

.defiFeatures-text-title {
  color: var(--text-color);
  font-size: var(--card-title-text);
  font-weight: 600;
  align-self: stretch;
  text-align: center;
}

.defiFeatures-text-description {
  color: var(--para-text-color);
  font-size: var(--card-paragraph-text);
  font-weight: 400;
  align-self: stretch;
  text-align: left;
  display: none;
}

.defiFeatures-image-container-first {
  display: flex;
  padding: 45px 25px 45px 0px;
  align-items: center;

  flex: 1 0 0;
  align-self: stretch;

  width: 50%;
}

.defiFeatures-image-container-second {
  display: flex;
  padding: 45px 0px 45px 25px;
  align-items: center;

  flex: 1 0 0;
  align-self: stretch;

  width: 50%;
}

.defiFeatures-image-container-third {
  display: flex;
  padding: 45px 25px 45px 0px;
  align-items: center;

  flex: 1 0 0;
  align-self: stretch;

  width: 50%;
}

.defiFeatures-image-container-fourth-border {
  display: flex;
  padding-left: 25px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
  align-self: stretch;
  width: 50%;
}

.defiFeatures-image-container-fourth {
  display: flex;
  height: 120px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  overflow: hidden;

  width: 100%;

  border-radius: 15px;
  border: 1px solid var(--card-second-border-color);
  background: var(--card-second-color);
}

.defiFeatures-image-container-fourth {
  display: flex;
  height: 120px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.defiFeatures-image-container-fourth img {
  width: 527px;
  height: 121px;
  flex-shrink: 0;
}

.defiFeatures-image-first {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  flex: 1 0 0;
  height: 120px;
  border-radius: 15px;
  border: 1px solid var(--card-second-border-color);
  background: var(--card-second-color);
}

.defiFeatures-image-second {
  position: relative;
  display: flex;
  height: 120px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  height: 120px;
  width: 100%;
}

.defiFeatures-image-third {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  flex: 1 0 0;

  border-radius: 15px;
  border: 1px solid var(--card-second-border-color);
  background: var(--card-second-color);
  height: 120px;
}

.defiFeatures-image-first img {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  width: 100%;
  height: 80px;
}

.defiFeatures-image-third img {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  height: 80px;
}

.defiFeatures-image-special {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 0 0;
}

.defiFeatures-image-special img {
  width: fit-content;
  height: 40px;
}

.defiFeatures-image-second-inner {
  display: flex;
  padding: 10px 25px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;

  border-radius: 15px;
  border: 1px solid var(--card-second-border-color);
  background: var(--card-second-color);
}

.defiFeatures-image-second-inner-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  align-self: stretch;
}

.defiFeatures-image-second-inner-text-token {
  display: flex;
  align-items: center;
  gap: 5px;

  color: var(--text-color);
  font-size: 20px;
  font-weight: 400;
}

.defiFeatures-image-second-inner-text-token-image {
  height: 25px;
}

.defiFeatures-image-second-inner-text-text {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
  color: var(--text-color);
  font-size: 16px;
  font-weight: 400;
}

.defiFeatures-image-second-inner-text-balance {
  display: flex;
  align-items: flex-start;
  gap: 5px;
  color: var(--para-text-color);
  font-size: 13px;
  font-weight: 275;
}

.defiFeatures-image-flip {
  position: absolute;
  left: 50%;
  bottom: 50%;
  transform: translateX(-50%) translateY(50%);

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.defiFeatures-image-flip img {
  width: 30px;
  height: 30px;
}

@media screen and (max-width: 950px) {
  .defiFeatures-image-first img {
    height: 60px;
  }

  .defiFeatures-image-third img {
    height: 60px;
  }

  .defiFeatures-image-special img {
    height: 40px;
  }
}

@media screen and (max-width: 850px) {
  .defiFeatures-image-second-inner {
    padding: 10px 10px;
  }
}

@media screen and (max-width: 768px) {
  .defiFeatures-text {
    padding: 5px 25px 5px 25px;
  }

  .defiFeatures-text-r {
    padding: 5px 25px 5px 25px;
  }
}

@media screen and (max-width: 650px) {
  .defiFeatures-image-first img {
    height: 50px;
  }

  .defiFeatures-image-third img {
    height: 50px;
  }

  .defiFeatures-image-special img {
    height: 30px;
  }
}

@media screen and (max-width: 600px) {
  .defiFeatures-image-second {
    flex-direction: column;
    justify-content: center;
  }

  .defiFeatures-image-second {
    height: 100%;
  }

  .defiFeatures-image-second-inner-text {
    flex-direction: row;
    justify-content: space-between;
  }

  .defiFeatures-image-second-inner-text-text {
    height: 100%;
  }

  .defiFeatures-image-second-inner-text-balance {
    display: none;
  }
}

@media screen and (max-width: 550px) {
  .defiFeatures-feature {
    flex-direction: column;
    height: auto;
  }

  .defiFeatures-image-container-first {
    padding: 15px 15px 15px 15px;
    width: auto;
  }

  .defiFeatures-image-container-second {
    padding: 15px 15px 15px 15px;
    width: auto;
  }

  .defiFeatures-image-container-third {
    padding: 15px 15px 15px 15px;
    width: auto;
  }

  .defiFeatures-image-first {
    height: 100px;
  }

  .defiFeatures-image-second {
    flex-direction: row;
    justify-content: center;
    height: 100px;
  }

  .defiFeatures-image-second-inner-text {
    flex-direction: column;
    justify-content: space-between;
    padding: 0px 15px;
  }

  .defiFeatures-image-second-inner-text-text {
    height: 100%;
  }

  .defiFeatures-image-second-inner-text-balance {
    display: flex;
  }

  .defiFeatures-image-third {
    height: 100px;
  }

  .defiFeatures-image-container-fourth-border {
    padding: 15px 15px 15px 15px;
    width: auto;
  }

  .defiFeatures-image-container-fourth {
    height: 100px;
  }

  .defiFeatures-image-container-fourth img {
    height: auto;
  }

  .defiFeatures-text {
    padding: 0px 15px 15px 15px;
    width: auto;
  }

  .defiFeatures-text-r {
    padding: 0px 15px 15px 15px;
    width: auto;
  }

  .defiFeatures-text-title {
    text-align: center;
  }

  .defiFeatures-text-description {
    text-align: center;
  }
}

@media screen and (max-width: 400px) {
}

@media screen and (max-width: 350px) {
}
