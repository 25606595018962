.advisors-section {
  max-width: 1249px;
  height: 1679px;
  flex-shrink: 0;
}
.advisors-title {
  color: #fff;
  font-family: "Poppins";
  font-size: 78px;
  font-weight: 700;
  line-height: 110%;
  letter-spacing: 4.68px;
  text-transform: capitalize;
  text-align: center;
}
.advisors-conatiner-wrapper {
  display: flex;
  max-width: 1249px;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
.advisors-conatiner {
  padding-top: 80px;
  display: flex;
  align-items: center;
  gap: 23px;
  align-self: stretch;
}
.advisors-left {
  display: flex;
  max-width: 613px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}
.advisors-right {
  display: flex;
  max-width: 613px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}
.adivors-member {
  border-radius: 12px;
  background: var(--Bg-cards-buttons, #0f161b);
  display: flex;
  height: 293px;
  padding: 20px;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}
.advisors-member-image {
  border-radius: 12px;
  background: contain no-repeat, #0f161b;
  max-width: 253px;
  align-self: stretch;
  aspect-ratio: 1/1;
}
.adivsors-name-position {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
}
.advisors-name {
  color: var(--Total-white, #fff);
  font-family: "Roboto";
  font-size: 22px;
  font-weight: 700;
  line-height: 128%;
  letter-spacing: 0.88px;
  align-self: stretch;
}
.advisors-position {
  color: var(--Accent-Purple, #6c84f8);
  text-align: center;
  font-family: "Roboto";
  font-size: 22px;
  font-weight: 700;
  line-height: 128%;
  letter-spacing: 0.88px;
}
.advisors-description {
  color: var(--secondary-light-new, rgba(238, 238, 238, 0.9));
  font-family: "Roboto";
  font-size: 19px;
  font-weight: 300;
  line-height: 148%;
  letter-spacing: 0.76px;
  align-self: stretch;
}
.adivors-member-last {
  border-radius: 12px;
  background: var(--Bg-cards-buttons, #0f161b);
  display: flex;
  height: 293px;
  padding: 20px;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  margin-inline: 318px;
}
@media (max-width: 576px) {
  .advisors-section {
    max-width: 100%;
    height: auto;
    padding: 20px;
  }

  .advisors-title {
    font-size: 42px;
    letter-spacing: 2px;
  }

  .advisors-conatiner-wrapper {
    gap: 16px;
  }

  .advisors-conatiner {
    flex-direction: column;
    padding-top: 40px;
    gap: 16px;
  }

  .advisors-left,
  .advisors-right {
    max-width: 100%;
    width: 100%;
    align-items: center;
  }

  .adivors-member {
    flex-direction: column;
    height: auto;
    padding: 16px;
    align-items: center;
    text-align: center;
  }

  .advisors-member-image {
    max-width: 150px;
    width: 100%;
    aspect-ratio: 1/1;
    align-self: center;
  }

  .adivsors-name-position {
    align-items: center;
  }

  .adivors-member-last {
    margin-inline: 0;
    flex-direction: column;
    height: auto;
    text-align: center;
    align-items: center;
  }
}
