.AboutSectionOne-wrapper {
  border-radius: 12px;
  background: var(--Bg-cards-buttons, #0f161b);
  display: flex;
  max-width: 1248px;
  padding: 120px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 80px;
  margin-top: 80px;
}
.about-title-text {
  color: var(--Total-white, #fff);
  font-feature-settings: "ss01" on;
  font-family: Poppins;
  font-size: 78px;
  font-style: normal;
  font-weight: 700;
  line-height: 122%; /* 95.16px */
  letter-spacing: 4.68px;
  text-transform: capitalize;
}
/* Responsive Styles for 576px and below */
@media (max-width: 576px) {
  .AboutSectionOne-wrapper {
    max-width: 95%;
    padding: 12vw 6vw;
  }

  .about-title-text {
    font-size: clamp(
      28px,
      8vw,
      48px
    ); /* Dynamically scales between 28px-48px */
    text-align: center;
    letter-spacing: 2px;
    line-height: 1.2;
  }
}
