@import "../../mainVariables.css";

.defiPhotos {
  display: flex;
  padding: var(--split-padding);
  flex-direction: column;
  align-items: center;
  align-self: stretch;
}

.defiPhotos-container {
  position: relative;
  display: flex;
  width: 100%;
  max-width: var(--max-width);
  justify-content: space-between;
  align-items: center;
}

.defiPhotos-image-middle {
  z-index: 1;
  display: flex;

  padding-top: 50px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  position: absolute;
  left: 50%;
  bottom: -50px;
  transform: translateX(-50%);
}

.defiPhotos-image {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.defiPhotos-image img,
.defiPhotos-image-middle img {
  width: 400px;
  height: 808.627px;
  filter: drop-shadow(0px 0px 20px var(--special-glow-color));
}

@media screen and (max-width: 950px) {
  .defiPhotos-image img,
  .defiPhotos-image-middle img {
    width: 370px;
    height: 747.979px;
  }
}

@media screen and (max-width: 850px) {
  .defiPhotos-image img,
  .defiPhotos-image-middle img {
    width: 330px;
    height: 667.117px;
  }
}

@media screen and (max-width: 768px) {
  .defiPhotos-image img,
  .defiPhotos-image-middle img {
    width: 260px;
    height: 525.607px;
  }
}

@media screen and (max-width: 650px) {
  .defiPhotos-image img,
  .defiPhotos-image-middle img {
    width: 210px;
    height: 424.529px;
  }
}

@media screen and (max-width: 600px) {
}

@media screen and (max-width: 500px) {
  .defiPhotos-image img,
  .defiPhotos-image-middle img {
    width: 160px;
    height: 323.451px;
  }
}

@media screen and (max-width: 400px) {
  .defiPhotos-image img,
  .defiPhotos-image-middle img {
    width: 136.033px;
    height: 275px;
  }
}

@media screen and (max-width: 350px) {
} 
