 @import "../../mainVariables.css";

.usecasesHero {
  display: flex;
  padding: var(--top-padding);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}

.usecasesHero-container {
  position: relative;
  display: flex;
  width: 100%;
  max-width: var(--max-width);
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.usecasesHero-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}

.usecasesHero-title-alpha {
  width: 100%;
  color: var(--para-text-color);
  text-align: right;
  font-size: 16px;

  font-weight: 700;
}

.usecasesHero-title-title {
  color: var(--text-color);
  text-align: center;
  font-size: 70px;

  font-weight: 700;
}

.specific-text {
  background: var(
    --3VO-Gradiant,
    linear-gradient(90deg, #d129dc 0.08%, #0cdbfd 99.92%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.usecasesHero-title-subtitle {
  color: var(--para-text-color);
  text-align: center;
  font-size: 16px;
  font-weight: 400;
}

.usecasesHero-button-container {
  display: flex;
  padding: 30px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  align-self: stretch;
}

.usecasesHero-button {
  display: flex;
  height: 40px;
  padding: 5px 80px;
  align-items: center;
  gap: 6px;
  border-radius: 25px;
  background: var(--gradiant3vo);
}

.usecasesHero-button:hover {
  transition: border-radius 0.2s, color 0.2s, filter 0.2s;
  color: var(--background-color);
  filter: drop-shadow(0 0 5px var(--button-glow-color))
    drop-shadow(0 0 10px var(--button-glow-color))
    drop-shadow(0 0 30px var(--button-glow-color));
}

.usecasesHero-button-title {
  color: var(--button-text-color);
  font-size: 20px;
  font-weight: 700;
}

.usecasesHero-background {
  position: absolute;
  opacity: 0.1;
  right: -400px;
  top: -200px;
  filter: blur(50px);
  z-index: 0;
}

.usecasesHero-background img {
  color: var(--gradiant3vo);
  width: 1400px;
}

@media screen and (max-width: 850px) {
  .usecasesHero-title-title {
    font-size: 62px;
  }
}

@media screen and (max-width: 650px) {
  .usecasesHero-title-title {
    font-size: 54px;
  }
}

@media screen and (max-width: 500px) {
  .usecasesHero-title-title {
    font-size: 48px;
  }

  .usecasesHero-comingsoon img {
    width: 160px;
  }
}

@media screen and (max-width: 400px) {
  .usecasesHero-title-title {
    font-size: 42px;
  }
}

@media screen and (max-width: 360px) {
  .usecasesHero-title-title {
    font-size: 32px;
  }

  .usecasesHero-button-title {
    font-size: 16px;
  }

  .usecasesHero-button {
    justify-content: center;
    width: 100%;
    padding: 5px 0px;
  }
}
