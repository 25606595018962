.singleUsecasePhoto {
  display: flex;

  padding: var(--no-padding);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
  align-self: stretch;
}

.singleUsecasePhoto-container {
  position: relative;
  display: flex;
  width: 100%;
  max-width: var(--max-width);
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.singleUsecasePhoto-container img {
  width: 100%;
}
