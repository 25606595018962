/* Ad Bar Styling */
.ad-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(90deg, #d129dc 0.08%, #0cdbfd 99.92%);
  color: white;
  padding: 6px 20px;
  font-size: 14px;
  font-weight: bold;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1002;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}
.ad-bar span a {
  color: white;
  text-decoration: none;
}
.ad-text {
  flex-grow: 1;
}

.close-btn {
  background: none;
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
}

.close-btn:hover {
  color: #ccc;
}
