@import "../../mainVariables.css";

.defiDownload {
  position: relative;
  display: flex;
  padding: var(--bottom-padding);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}

.defiDownload-container {
  display: flex;
  width: 100%;
  max-width: var(--max-width);
  flex-direction: column;
  align-items: center;
  gap: calc(var(--card-gap) * 2);
  z-index: 1;
}

.defiDownload-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.defiDownload-title-version {
  flex: 1 0 0;
  align-self: stretch;
  color: var(--para-text-color);
  text-align: right;
  font-size: var(--button-subtitle-text);
  font-weight: 700;
}

.defiDownload-title-text {
  align-self: stretch;
  color: var(--text-color);
  text-align: center;
  font-size: var(--call-title-text);
  font-weight: 700;
}

.defiDownload-soon {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--card-gap);
  align-self: stretch;
}

.defiDownload-soon img {
  height: 50px;
}

.defiDownload-disclaimer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--card-gap);
  align-self: stretch;
}

.defiDownload-disclaimer-title {
  color: var(--text-color);
  font-size: var(--disclaimer-title-text);
  font-weight: 700;
}

.defiDownload-disclaimer-text {
  color: var(--para-text-color);
  text-align: center;
  font-size: var(--disclaimer-paragraph-text);
  font-weight: 400;
}

.defiDownload-buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}

.defiDownload-buttons-action {
  display: flex;
  padding: 5px 0px;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
}

.defiDownload-buttons-action-button {
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  /*  */
  cursor: pointer;
  width: 100%;
  padding: 5px;

  border-radius: 20px;
  background: linear-gradient(90deg, #d129dc 0.08%, #0cdbfd 99.92%);
}

.defiDownload-buttons-action-button:hover {
  transition: border-radius 0.2s, color 0.2s, filter 0.2s;
  color: var(--background-color);
  filter: drop-shadow(0 0 5px var(--button-glow-color))
    drop-shadow(0 0 10px var(--button-glow-color))
    drop-shadow(0 0 30px var(--button-glow-color));
}

.defiDownload-buttons-action-button-title {
  color: var(--button-text-color);
  font-size: var(--button-title-text);
  font-weight: 700;
}

.defiDownload-buttons-action-button:hover
  .defiDownload-buttons-action-button-title,
.defiDownload-buttons-action-button:hover
  .defiDownload-buttons-action-button-subtitle {
  color: var(--background-color);
}

.defiDownload-buttons-action-button-subtitle {
  color: var(--button-text-color);
  font-size: var(--button-subtitle-text);
  font-weight: 400;
}

.defiDownload-buttons-links {
  display: flex;
  padding: 15px 0px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.defiDownload-buttons-links-design {
  display: flex;
  width: 1px;
  height: 15px;
  padding: 5px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--para-text-color);
}

.defiDownload-buttons-links a {
  text-decoration: none;
  color: var(--para-text-color);
}

.defiDownload-buttons-links-link {
  display: flex;
  padding: 5px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
}

.defiDownload-buttons-links-link:hover {
  color: var(--text-color);
}

.defiDownload-background {
  position: absolute;
  opacity: 0.1;
  right: -400px;
  top: -200px;
  filter: blur(50px);
  z-index: 0;
}

.defiDownload-background img {
  color: var(--gradiant3vo);
  width: 1400px;
}

@media screen and (max-width: 950px) {
}

@media screen and (max-width: 850px) {
}

@media screen and (max-width: 768px) {
}

@media screen and (max-width: 650px) {
  .defiDownload-soon img {
    height: 40px;
  }
}

@media screen and (max-width: 600px) {
}

@media screen and (max-width: 500px) {
}

@media screen and (max-width: 400px) {
  .defiDownload-soon img {
    height: 30px;
  }
}

@media screen and (max-width: 350px) {
}
