.signup-sec {
  display: flex;
  /* padding: 80px 0px; */
  align-items: flex-start;
  gap: 80px;
  background: var(--Bg-Dark, #080d10);
  /* padding-left: 137px;
  padding-right: 137px; */
}
.signup-title-btn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
}
.claimNow {
  cursor: pointer;
}

.signup-input {
  /* width: 100%; */
  max-width: 400px;
  padding: 10px 15px;
  border: 1px solid var(--tertiary-light, #656869);
  background: var(--Bg-cards-buttons, #0f161b);
  border-radius: 4px;
  color: white;
  font-size: 16px;
  font-family: "Inter";
}

.signup-input::placeholder {
  color: #888;
}

.signup-input:focus {
  outline: none;
  border-color: var(--Accent-blue);
}

/* If error, show red border */
.signup-frame.error .signup-input {
  border-color: red !important;
}

/* Error Message */
.error-message {
  color: red;
  font-size: 14px;
  margin-top: -15px;
  /* margin-top: 5px; */
}

.signup-title {
  color: var(--Total-white, #fff);
  font-family: "Roboto";
  font-size: 44px;
  font-weight: 700;
  line-height: 124%;
  letter-spacing: 2.64px;
  text-transform: capitalize;
  text-align: start;
}
.signup-title span {
  color: var(--Accent-blue);
}
.signup-frame {
  display: flex;
  width: 506px;
  padding: 8px 12px;
  justify-content: space-between;
  align-items: center;
  border-radius: 2px;
  background: var(--Bg-cards-buttons, #0f161b);
}
.signup-label-text {
  color: var(--tertiary-light, #656869);
  font-family: "Inter";
  font-size: 19px;
  font-style: normal;
  font-weight: 300;
  line-height: 148%; /* 28.12px */
  letter-spacing: 0.76px;
  width: 156px;
  flex-shrink: 0;
}
.mobile-btn-sign {
  display: none;
}
.signup-coins {
  display: flex;
  /* padding: 0px 0px 56.763px 450.927px; */
  justify-content: flex-end;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
}

.coins-img {
  width: 568px;
  height: 258px;
  background: url(../../assets/AISection/coins_2.png) 50% / cover no-repeat;
}
@media screen and (max-width: 400px) {
  .signup-input {
    width: 100%;
  }
  .signup-sec {
    flex-direction: column;
    align-items: center;
    text-align: left;
    padding: 40px 20px;
    gap: 40px;
    position: relative;
  }

  .signup-title-btn {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    position: relative;
    z-index: 2;
  }

  .signup-title {
    font-size: 28px;
    line-height: 120%;
    letter-spacing: 1.5px;
    text-align: left;
    /* max-width: 70%; */
  }

  .signup-frame {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 12px;
    align-items: center;
    justify-content: center;
    background: var(--Bg-cards-buttons, #0f161b);
    border-radius: 4px;
    gap: 12px;
  }

  .signup-label-text {
    width: 100%;
    font-size: 16px;
    text-align: center;
    letter-spacing: 0.5px;
  }

  .claimNow {
    display: none;
  }

  .signup-coins {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }

  .coins-img {
    width: 100%;
    max-width: 400px;
    height: 100%;
    max-height: 250px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0.2;
  }
  .mobile-btn-sign {
    display: flex;
    background-image: url(../../assets/svg/join-presale-mobile.svg);
    justify-content: center;
    justify-items: center;
    cursor: pointer;
    width: 328px;
    height: 44px;
    flex-shrink: 0;
    margin-top: -17px;
  }
  .signup-text-wrapper-cta {
    text-align: center;
    color: var(--Total-white, #fff);
    font-family: "Inter";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 136%;
    letter-spacing: 0.36px;
    padding-top: 7px;
  }
  .signup-label-text {
    text-align: start;
  }
}
@media screen and (max-width: 450px) and (min-width: 401px) {
  .signup-input {
    width: 100%;
  }
  .signup-sec {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 0px 20px;
    gap: 40px;
    position: relative;
  }

  .signup-title-btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    position: relative;
    z-index: 2;
  }

  .signup-title {
    font-size: 39px;
    line-height: 120%;
    letter-spacing: 1.8px;
    text-align: center;
    /* max-width: 80%; */
    /* margin-top: 137px; */
    max-width: 103%;
    margin-top: 104px;
  }

  .signup-frame {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 12px;
    align-items: center;
    justify-content: center;
    background: var(--Bg-cards-buttons, #0f161b);
    border-radius: 4px;
    gap: 12px;
  }

  .signup-label-text {
    width: 100%;
    font-size: 17px;
    text-align: center;
    letter-spacing: 0.5px;
  }

  .claimNow {
    display: none;
  }

  .signup-coins {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }

  .coins-img {
    width: 100%;
    max-width: 400px;
    height: 100%;
    max-height: 250px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0.2;
  }

  /* Mobile CTA button */
  .mobile-btn-sign {
    display: flex;
    background-image: url(../../assets/svg/join-presale-mobile.svg);
    justify-content: center;
    cursor: pointer;
    width: 280px;
    height: 44px;
    flex-shrink: 0;
    /* margin-top: 10px; */
    margin-top: -16px;
  }

  .signup-text-wrapper-cta {
    text-align: center;
    color: var(--Total-white, #fff);
    font-family: "Inter";
    font-size: 18px;
    font-weight: 700;
    line-height: 136%;
    letter-spacing: 0.36px;
    padding-top: 7px;
  }

  .signup-label-text {
    text-align: center;
  }
}

/* ✅ Fix for desktop (≥ 451px) */
@media screen and (min-width: 451px) {
  /* .signup-input {
    width: 100%;

  } */
  .signup-sec {
    padding-left: 137px;
    padding-right: 137px;
  }

  .signup-title {
    text-align: start;
  }

  .signup-title-btn {
    align-items: flex-start;
  }

  .signup-frame {
    width: 506px;
  }

  .mobile-btn-sign {
    display: none;
  }
}
