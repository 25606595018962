@import "../../mainVariables.css";

.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  /*  */
  width: 100%;
  background-color: var(--header-background-color);
  backdrop-filter: blur(5px);
  z-index: 1000;
}

.header-container {
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -10px;

  /*  */
  width: 100%;
  max-width: 1150px;
}

.header-logo {
  /*  */
  flex: 1 0 0;
  padding-left: var(--horizantal-padding);
}

.header-logo a {
  display: flex;
  align-items: flex-start;
  /*  */
  gap: 8px;
}

.header-logo .header-innerlogo {
  height: 22px;
  width: auto;
}

.header-logo .header-writtenlogo {
  height: 22px;
  width: auto;
}

.header-navigation {
  display: flex;
  /*  */
  padding: 0px 20px;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 20px;
}

.header-navitem {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.header-headerItem {
  display: flex;
  align-items: center;
  /*  */
  cursor: pointer;
  gap: 6px;
}

.header-headerItem-text {
  color: var(--header-text-color);
  transition: color 0.5s, fill 0.5s;
  text-align: center;

  font-size: 1.2rem;

  font-weight: 400;
}

.header-headerItem-arrow {
  fill: var(--header-text-color);
  transition: color 0.5s, fill 0.5s;
  width: 11.202px;
  height: 7px;
}

.header-headerItem:hover .header-headerItem-text,
.header-headerItem:hover .header-headerItem-arrow {
  color: var(--header-text-color-hover);
  fill: var(--header-text-color-hover);
  transition: color 0.5s, fill 0.5s;
}

.header-headerItemDivider {
  margin-top: 6px;
  background-color: var(--header-text-color);
  display: flex;
  width: 0.3px;
  height: 15px;
  align-items: center;
  gap: 6px;
}

.header-submenu {
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  transform: translateX(0);
  /*  */
  display: flex;
  align-items: flex-start;
  z-index: 999;
  background-color: rgba(14, 14, 14, 0.95);
  backdrop-filter: blur(5px);
  border: 1px solid var(--card-border-color);
  border-radius: 10px;
}

.header-submenu-links {
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  /*  */
  padding: 20px var(--horizantal-padding);
  flex: 1 0 0;
  gap: 8px;
}

.header-submenu-links a {
  text-decoration: none;
}

.header-submenu-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  /*  */
  color: var(--text-color);
  height: 50px;
  padding: 0px var(--horizantal-padding);
  width: 160px;
  background-color: none;
  border-radius: 10px;
  border: 1px solid var(--no-color);
  transition: border 0.1s, background-color 0.1s;
  font-weight: bold;
}

.header-submenu-button:hover {
  border: 1px solid var(--card-border-color);
  background-color: var(--card-color);
  transition: border 0.1s, background-color 0.1s;
}

.header-submenu-divider {
  width: 1px;
  background-color: var(--card-color);
  align-self: stretch;
}

.header-submenu-description {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /*  */
  padding: 0px var(--horizantal-padding);
  gap: 8px;
  flex: 1 0 0;
  align-self: stretch;
}

.header-submenu-description-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /*  */
  padding: 0px var(--horizantal-padding);
  flex: 1 0 0;
  align-self: stretch;
  width: 160px;
}

.header-submenu-description-placeholder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /*  */
  padding: 0px var(--horizantal-padding);
  flex: 1 0 0;
  align-self: stretch;
  width: 160px;
  color: var(--header-text-color);
  text-align: center;

  font-size: 14px;

  font-weight: 400;
}

.header-submenu-description-logo {
  width: 40px;
}

.header-submenu-description-title {
  color: var(--text-color);
  text-align: center;

  font-size: 18px;
}

.header-submenu-description-text {
  color: var(--header-text-color);
  text-align: center;

  font-size: 14px;
}

.header-action-button-container {
  display: flex;
  padding-left: 20px;
  align-items: center;

  padding-right: var(--horizantal-padding);
}

.header-action-button-container a {
  text-decoration: none;
  color: inherit;
}

.header-action-button {
  display: flex;
  align-items: center;
  /*  */
  cursor: pointer;
  padding: 5px var(--horizantal-padding);
  gap: 6px;
  color: #000;
  border-radius: 20px 20px 20px 20px;
  background: linear-gradient(90deg, #d129dc 0.08%, #0cdbfd 99.92%);
  background: var(--cyan3vo);

  font-size: 1rem;
  font-weight: 600;
  text-wrap: nowrap;
}

.header-action-button:hover {
  transition: border-radius 0.2s, color 0.2s, filter 0.2s;
  color: var(--background-color);
  filter: drop-shadow(0 0 5px #01dfc960) drop-shadow(0 0 10px #01dfc960)
    drop-shadow(0 0 30px #01dfc960);
}

.header-mobile-action {
  display: flex;
  flex-direction: row;
  text-decoration: none;
}

.header-mobile-action a {
  text-decoration: none;
}

.header-action-button-quests {
  display: flex;
  align-items: center;
  /*  */
  /* cursor: pointer; */
  padding: 5px var(--horizantal-padding);
  gap: 6px;
  color: var(--button-text-color);
  border-radius: 0 20px 20px 0;
  background: var(--special3vo);
  /* background: #383838;
  color: #5d5d5d; */

  font-size: 1rem;
  text-wrap: nowrap;
  font-weight: 600;
}

.header-action-button-quests:hover {
  transition: border-radius 0.2s, color 0.2s, filter 0.2s;
  color: var(--background-color);
  filter: drop-shadow(0 0 5px var(--button-glow-color))
    drop-shadow(0 0 10px var(--button-glow-color))
    drop-shadow(0 0 30px var(--button-glow-color));
}

.header-action-button-quests .shake-text {
  display: inline-block;
  /* animation: shake 3s infinite; */
}

/* Define the shake rotation animation */
@keyframes shake {
  0%,
  100% {
    transform: rotate(0deg);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: rotate(-5deg);
  }
  20%,
  40%,
  60%,
  80% {
    transform: rotate(5deg);
  }
}

.header-burger-menu {
  display: none;
  padding-left: 20px;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  align-self: stretch;
  z-index: 9999;
}

.header-burger-menu-icon {
  fill: white;
  stroke: white;
  color: white;
  display: flex;
  height: 30px;
  padding-right: var(--horizantal-padding);
}

.header-theme-switcher {
  cursor: pointer;
  display: flex;
  justify-content: center;
  fill: var(--para-text-color);
  stroke: var(--para-text-color);
  color: var(--para-text-color);
  display: flex;
  height: 20px;
  align-items: center;
  padding-right: var(--horizantal-padding);
}

.header-mobile-menu {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 300px;
  padding: 0px var(--horizantal-padding);
  height: auto;
  background-color: rgba(14, 14, 14, 0.95);
  backdrop-filter: blur(5px);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

@media screen and (max-width: 950px) {
  .header-navigation {
    display: none;
  }

  .header-burger-menu {
    display: flex;
  }

  .header-action-button-container {
    padding-right: 0;
  }

  .header-submenu {
    position: relative;
    top: auto;
    left: auto;
    transform: none;
    /*  */
    border: none;
    display: flex;
    align-items: flex-start;
    z-index: 999;
    border-radius: 10px;
  }

  .header-submenu-description {
    display: none;
  }

  .header-submenu-divider {
    display: none;
  }

  .header-submenu-button {
    height: 25px;
    padding: 0px var(--horizantal-padding);
    width: 160px;
    background-color: none;
    border-radius: 10px;
    border: 1px solid var(--no-color);
    transition: border 0.1s, background-color 0.1s;
  }
}

@media screen and (max-width: 500px) {
  .header-mobile-menu {
    width: 100%;
    align-items: center;
    padding: 0 0;
  }

  .header-submenu {
    background-color: transparent;
    backdrop-filter: none;
  }

  .header-headerItem {
    align-self: center;
  }

  .header-submenu-button {
    align-items: center;
  }

  .header-action-button-container {
    padding: 10px 0;
  }

  .header-headerItem {
    height: 40px;
  }

  .header-action-button-container {
    display: none;
  }
}

@media screen and (max-width: 400px) {
  .header-mobile-menu {
    width: 100%;
    align-items: center;
    padding: 0px 0px;
  }

  .header-navitem {
    align-items: center;
  }

  .header-action-button-container {
    display: none;
  }
}
