.embed-container-tut {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: var(--max-width);
  justify-content: flex-start;
  z-index: 4;
  min-height: 400px;
}

.tutorial-list {
  margin-top: 50px;
}

.tutorial-item {
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  padding: 15px;
  background-color: var(--card-color);
  margin-bottom: 10px;
  cursor: pointer;
  transition: all 1s ease;
}

.tutorial-title-title {
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  padding: 15px 5px 5px 5px;

  transition: all 1s ease;
  align-items: flex-start;
  color: var(--special3vo);
  font-size: 22px;
  font-weight: 500;
}

.tutorial-item.expanded {
  background-color: var(--special-card-color);
}

.step-description-container {
  display: flex;

  align-items: center;
  justify-content: flex-start;
  height: 100%;
  text-align: left;
}

.tutorial-item-title {
  display: flex;
  align-items: flex-start;
  color: var(--text-color);
  font-size: 14px;
  font-weight: 500;
}

.step-description-number {
  font-size: 150px;
}

.tutorial-step {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;

  justify-content: center;
  padding-top: 10px;
  height: 500px;
}

.step-description {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: var(--text-color);
  font-weight: bold;
  margin-bottom: 20px;
  height: 100%;
  width: 40%;
}

.step-navigation {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.step-image {
  display: flex;
  justify-content: flex-end;
  width: 40%;
  height: 100%;
}

.step-image img {
  height: 100%;
}

.step-navigation button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: var(--button-color);
  color: white;
  border: none;
  border-radius: 5px;
}

.step-navigation button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

@media screen and (max-width: 780px) {
  .tutorial-step {
    height: 400px;
  }
}

@media screen and (max-width: 700px) {
  .tutorial-step {
    height: 300px;
  }
}

@media screen and (max-width: 550px) {
  .tutorial-step {
    flex-direction: column-reverse;
    height: 700px;
  }

  .step-image img {
    height: 100%;
  }

  .step-image {
    height: 500px;
    width: 100%;
    justify-content: center;
  }

  .step-description {
    padding-top: 25px;
    width: 50%;
    height: 50px;
  }

  .step-navigation {
    padding-top: 25px;
  }
}

@media screen and (max-width: 450px) {
  .step-description {
    padding-top: 25px;
    width: 100%;
    height: 50px;
  }
}
