@import "../../mainVariables.css";

.usecasesCall {
  position: relative;
  display: flex;
  padding: var(--split-padding);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}

.usecasesCall-container {
  z-index: 1;
  display: flex;
  width: 100%;
  max-width: var(--max-width);
  flex-direction: column;
  align-items: center;
  gap: calc(var(--card-gap) * 2);
}

.usecasesCall-title {
  display: flex;
  flex-direction: column;
  align-items: center;

  text-align: left;
  color: var(--text-color);
  font-size: var(--call-title-text);
  font-weight: 700;
}

.usecasesCall-action a {
  text-decoration: none;
}

.usecasesCall-action-button {
  display: flex;
  align-items: center;
  /*  */
  cursor: pointer;
  padding: 5px 80px;
  gap: 6px;
  color: var(--button-text-color);
  border-radius: 20px;
  background: linear-gradient(90deg, #d129dc 0.08%, #0cdbfd 99.92%);

  font-size: var(--button-title-text);
  text-wrap: nowrap;
}

.usecasesCall-action-button:hover {
  transition: border-radius 0.2s, color 0.2s, filter 0.2s;
  color: var(--background-color);
  filter: drop-shadow(0 0 5px var(--button-glow-color))
    drop-shadow(0 0 10px var(--button-glow-color))
    drop-shadow(0 0 30px var(--button-glow-color));
}

.usecasesCall-background {
  position: absolute;
  opacity: 0.1;
  right: -400px;
  top: 0px;
  filter: blur(50px);
  z-index: 0;
}

.usecasesCall-background img {
  color: var(--gradiant3vo);
  width: 1400px;
}

@media screen and (max-width: 950px) {
}

@media screen and (max-width: 850px) {
}

@media screen and (max-width: 768px) {
}

@media screen and (max-width: 650px) {
}

@media screen and (max-width: 600px) {
}

@media screen and (max-width: 500px) {
}

@media screen and (max-width: 400px) {
  .usecasesCall-action {
    width: 100%;
  }

  .usecasesCall-action-button {
    justify-content: center;
    padding: 5px 0px;
  }
}

@media screen and (max-width: 350px) {
}
