.apps-section {
  display: flex;
  height: 770px;
  width: 100%;
  background: var(--Bg-Dark, #080d10);
  padding-left: 120px;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  padding-left: 110px;
  /* //95px */
  padding-right: 95px;
}

.apps-title {
  color: var(--Total-white, #fff);

  /* H2 (new) */
  font-family: "Roboto";
  font-size: 44px;
  font-style: normal;
  font-weight: 700;
  line-height: 124%;
  letter-spacing: 2.64px;
  text-transform: capitalize;
}
/* color: var(--Total-white, #fff);
  font-feature-settings: "ss01" on;

 
  font-family: Michroma;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 132%;
  letter-spacing: 1.8px;

} */

.apps-title span {
  color: var(--Accent-for-text, #6c84f8);
  text-align: center;
  /* H2 (new) */
  font-family: "Roboto";
  font-size: 44px;
  font-style: normal;
  font-weight: 700;
  line-height: 124%; /* 54.56px */
  letter-spacing: 2.64px;
  text-transform: capitalize;
}

.apps-cards {
  display: flex;
  align-items: center;
  gap: 32px;
  align-self: stretch;
}
.app-card-hub {
  display: flex;
  width: 400px;
  height: 583px;
  padding: 20px 16px;
  flex-direction: column;
  align-items: flex-end;
  gap: 20px;
  border: 2px solid transparent;
  transition: border-color 0.3s ease, color 0.3s ease;
  border-radius: 15px;
  border-radius: 12px;
  background: var(--Bg-cards-buttons, #0f161b);
}

.app-card-hub:hover {
  border-color: var(--Accent-blue);
  background-color: var(--Accent-blue);
}
.app-card-hub:hover .arrow-up,
.app-card-hub:hover .learn-more-text,
.app-card-hub:hover .app-cards-title,
.app-card-hub:hover .app-cards-text {
  color: white;
}

.app-card-hub:hover .arrow-up {
  filter: brightness(0) invert(1);
  transform: scale(1.1);
  background: var(#fff);
}

.learn-more {
  display: flex;
  align-items: center;
  gap: 12px;
  /* display: flex;
  width: 400px;
  height: 583px;
  padding: 20px 16px;
  flex-direction: column;
  align-items: flex-end;
  gap: 20px; */
}
.learn-more-text {
  color: var(--Accent-Purple, #6c84f8);

  /* Phone/Captions/Small */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 135%; /* 18.9px */
  letter-spacing: 0.28px;
}
.arrow-up {
  width: 12px;
  height: 12px;
}

.app-cards-image-1 {
  background: url("../../assets/appscards/3voHub.png") no-repeat;
  height: 360px;
  flex-shrink: 0;
  align-self: stretch;
}
.app-cards-image-2 {
  background: url("../../assets/appscards/mobile.png") no-repeat;
  height: 360px;
  flex-shrink: 0;
  align-self: stretch;
}
.app-cards-image-3 {
  background: url("../../assets/appscards/3vogram.png") no-repeat;
  height: 360px;
  flex-shrink: 0;
  align-self: stretch;
}
.app-cards-desc {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}
.app-cards-title {
  color: var(--Total-white, #fff);

  /* Desktop/Headlines/Subtitle */
  font-family: Inter;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 128%; /* 28.16px */
  letter-spacing: 0.88px;
  text-align: start;
}
.app-cards-text {
  color: var(--Secondary-Light, #adadad);
  text-align: start;
  /* Desktop/Paragraph/Medium */
  font-family: Inter;
  font-size: 19px;
  font-style: normal;
  font-weight: 300;
  line-height: 148%; /* 28.12px */
  letter-spacing: 0.76px;
}
/* Responsive Styles for AppsSections */
@media screen and (max-width: 1024px) {
  .apps-section {
    height: auto;
    padding: 60px 40px;
    gap: 24px;
  }

  .apps-title {
    font-size: 28px;
    line-height: 120%;
    text-align: center;
  }

  .apps-cards {
    flex-wrap: wrap;
    justify-content: center;
    gap: 16px;
  }

  .app-card-hub {
    width: 300px;
    height: auto;
    padding: 16px;
  }

  .app-cards-image-1,
  .app-cards-image-2,
  .app-cards-image-3 {
    height: 250px;
    background-size: cover;
  }

  .app-cards-title {
    font-size: 20px;
    line-height: 120%;
  }

  .app-cards-text {
    font-size: 16px;
    line-height: 140%;
  }

  .learn-more-text {
    font-size: 12px;
  }

  .arrow-up {
    width: 10px;
    height: 10px;
  }
}

@media screen and (max-width: 768px) {
  .apps-section {
    padding: 40px 20px;
  }

  .apps-title {
    font-size: 24px;
  }

  .app-card-hub {
    width: 280px;
  }

  .app-cards-image-1,
  .app-cards-image-2,
  .app-cards-image-3 {
    height: 220px;
  }

  .app-cards-title {
    font-size: 18px;
  }

  .app-cards-text {
    font-size: 14px;
  }

  .learn-more-text {
    font-size: 10px;
  }
}

@media screen and (max-width: 480px) {
  .apps-section {
    padding: 20px 15px;
    gap: 16px;
    margin-top: -2px;
  }

  .apps-title {
    font-size: 42px;
  }

  .app-card-hub {
    width: 100%;
  }

  .app-cards-image-1,
  .app-cards-image-2,
  .app-cards-image-3 {
    /* height: 200px; */
    height: 300px;
  }

  .app-cards-title {
    font-size: 22px;
  }

  .app-cards-text {
    font-size: 14px;
  }

  .learn-more-text {
    font-size: 12px;
  }

  .arrow-up {
    width: 8px;
    height: 8px;
  }
}
