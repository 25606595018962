@import "../../mainVariables.css";

.projectHero {
  position: relative;
  display: flex;
  padding: var(--full-padding);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}

.projectHero-container {
  display: flex;
  width: 100%;
  max-width: var(--max-width);
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.projectHero-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}

.projectHero-title-title {
  color: var(--text-color);
  text-align: center;
  font-size: 70px;

  font-weight: 700;
}

.projectHero-title-subtitle {
  color: var(--para-text-color);
  text-align: left;
  font-size: 16px;
  font-weight: 400;
}

.projectHero-card-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: calc(var(--card-gap) * 2);
  align-self: stretch;
  width: 100%;
  max-width: var(--max-width);

  padding-top: 75px;
}

.projectHero-card {
  display: flex;
  gap: var(--card-gap);
  padding: calc(var(--card-gap) * 2);
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;

  border-radius: 15px;
  border: 2px solid var(--special3vo);
  background: var(--card-color);
}

.projectHero-card-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1 0 0;

  width: 50%;
}

.projectHero-card-title-main {
  color: var(--text-color);
  font-size: var(--main-title-text);
  font-weight: 700;
}

.projectHero-card-title-sub {
  align-self: stretch;
  color: var(--text-color);
  font-size: var(--card-title-text);
  font-weight: 400;
  text-align: left;
}

.projectHero-card-description {
  display: flex;
  padding: 5px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1 0 0;
  text-align: left;
  align-self: stretch;

  color: var(--para-text-color);
  font-size: var(--card-paragraph-text);
  font-weight: 400;

  width: 50%;
}

.projectHero-background {
  position: absolute;
  opacity: 0.1;
  right: -400px;
  top: -200px;
  filter: blur(50px);
  z-index: 0;
}

.projectHero-background img {
  color: var(--gradiant3vo);
  width: 1400px;
}

@media screen and (max-width: 850px) {
  .projectHero-title-title {
    font-size: 62px;
  }
}

@media screen and (max-width: 650px) {
  .projectHero-title-title {
    font-size: 54px;
  }

  .projectHero-card-description {
    width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .projectHero-title-title {
    font-size: 48px;
  }

  .projectHero-comingsoon img {
    width: 160px;
  }

  .projectHero-card {
    flex-direction: column;
  }

  .projectHero-card-title {
    width: 100%;
  }

  .projectHero-card-title-main {
    color: var(--text-color);
    font-size: var(--main-title-text);
    font-weight: 700;
  }

  .projectHero-card-title-sub {
    align-self: stretch;
    color: var(--text-color);
    font-size: var(--card-title-text);
    font-weight: 400;
    text-align: left;
  }

  .projectHero-title-subtitle {
    width: 100%;

    font-size: 14px;
  }
}

@media screen and (max-width: 400px) {
  .projectHero-title-title {
    font-size: 42px;
  }
}

@media screen and (max-width: 360px) {
  .projectHero-title-title {
    font-size: 32px;
  }

  .projectHero-button-title {
    font-size: 16px;
  }

  .projectHero-button {
    justify-content: center;
    width: 100%;
    padding: 5px 0px;
  }
}
