.brandSection {
  /* height: 70vh; */
  height: 450px;
  width: 100%;
  background: var(--Bg-Dark, #080d10);
}
.fifthFrame {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 129.989px;
  padding-left: 95px;
  padding-right: 95px;
}
.brand-contnet-wrapper {
  display: flex;
  width: 612.011px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
}
.m-text {
  display: none;
}
.brand-title {
  color: var(--Total-white, #fff);
  font-feature-settings: "ss01" on;
  font-family: "Michroma";
  font-size: 26px;
  font-weight: 400;
  line-height: 122%; /* 31.72px */
  letter-spacing: 1.56px;
  text-align: start;
  align-self: stretch;
}

.brand-title span {
  color: var(--Accent-blue);
}

.brand-paragraph {
  color: var(--Secondary-Light, #adadad);
  font-family: "Inter";
  font-size: 19px;
  font-style: normal;
  font-weight: 300;
  line-height: 148%;
  letter-spacing: 0.76px;
  text-align: start;
}

.brand-paragraph span {
  color: var(--Secondary-Light, #adadad);
  font-family: Inter;
  font-size: 19px;
  font-weight: 700;
  line-height: 148%; /* 28.12px */
  letter-spacing: 0.76px;
}

.table-content {
  width: 506px;
  height: 340px;
  border-radius: 12px;
}
.bg-class {
  border-radius: 12px;
  width: 506px;
  height: 340px;
  column-gap: 2px;
  flex-shrink: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* gap: 2px; */
  box-sizing: border-box;
  overflow: auto;
}
.offers {
  border-radius: 1px;
  border-radius: 1px;
  background: var(--Button-bg, #263152);
  display: flex;
  width: 251px;
  height: 64.8px;
  padding: 6px 72px;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  cursor: pointer;
}

.offers:hover {
  background: var(--Accent-Purple, #6c84f8);
}

.offers:hover .offers-icon {
  filter: brightness(0) invert(1); /* Converts colors to white */
  transform: scale(1.1); /* Slightly enlarges the icon */
}

.offers-icon {
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  transition: filter 0.3s ease, transform 0.2s ease; /* Smooth effect */
}
.offers-text {
  color: var(--Total-white, #fff);
  font-family: "Inter";
  font-size: 16px;
  font-weight: 600;
  line-height: 124%; /* 19.84px */
}
@media screen and (max-width: 400px) {
  .fifthFrame {
    flex-direction: column;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    gap: 24px;
  }

  .brand-contnet-wrapper {
    width: 100%;
    text-align: center;
    align-items: center;
    gap: 16px;
    display: flex;
    flex-direction: column;
  }

  .brand-title {
    font-size: 22px;
    letter-spacing: 1px;
    text-align: center;
    width: 100%;
    order: -2;
    margin-left: 134px;
    margin-bottom: 88px;
  }

  .brand-paragraph {
    font-size: 16px;
    letter-spacing: 0.5px;
    text-align: center;
    line-height: 140%;
    width: 100%;
    order: -1;
    margin-left: 115px;
  }

  .table-content {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    order: 1;
  }

  .bg-class {
    width: 50%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* gap: 3px; */
    justify-content: center;
    overflow: hidden;
    margin-left: -228px;
    margin-left: -335px;
    padding-bottom: 20px;
  }

  .offers {
    width: 100%;
    height: 60px;
    padding: 8px 16px;
    justify-content: center;
    /* border-radius: 6px; */
  }

  .brandSection {
    height: auto;
    width: 100%;
    background: var(--Bg-Dark, #080d10);
  }
}
@media screen and (max-width: 450px) and (min-width: 401px) {
  .fifthFrame {
    flex-direction: column;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    gap: 24px;
  }

  .brand-contnet-wrapper {
    width: 100%;
    text-align: center;
    align-items: center;
    gap: 16px;
    display: flex;
    flex-direction: column;
  }

  .brand-title {
    font-size: 22px;
    letter-spacing: 1px;
    text-align: center;
    width: 100%;
    order: -2;
    margin-left: 134px;
    /* margin-bottom: 88px; */
    margin-bottom: 6px;
  }

  .brand-paragraph {
    font-size: 16px;
    letter-spacing: 0.5px;
    text-align: center;
    line-height: 140%;
    width: 100%;
    order: -1;
    margin-left: 115px;
    margin-top: 100px;
  }

  .table-content {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    order: 1;
  }

  .bg-class {
    /* width: 50%; */
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* gap: 3px; */
    justify-content: center;
    overflow: hidden;
    /* margin-left: -228px;
    margin-left: -335px; */
    padding-bottom: 20px;

    margin-left: -280px;
    width: 59%;
  }

  .offers {
    width: 100%;
    height: 60px;
    padding: 8px 16px;
    justify-content: center;
    /* border-radius: 6px; */
  }

  .brandSection {
    /* height: 88vh; */
    height: auto;
    width: 100%;
    background: var(--Bg-Dark, #080d10);
  }

  .titles {
    margin-left: -135px;
    padding-right: 240px;
    /* margin-bottom: -64px; */
    margin-top: -20px;
  }

  .right-text {
    display: flex;
  }
  .m-text {
    display: none;
    /* margin-left: -55px;
    padding-right: 228px;
    display: flex;
    width: 612px;
    align-self: stretch;
    color: var(--new-secondary-light-new, rgba(238, 238, 238, 0.9));
    font-family: "Roboto";
    font-size: 19px;
    font-weight: 300;
    line-height: 148%;
    letter-spacing: 0.76px;
    text-align: start;
    padding-bottom: 28px; */
  }
}
