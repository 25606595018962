.Footer-wrapper {
  display: flex;
  /* width: 100%; */
  /* max-width: 1440px; */
  height: 450px;
  padding: 96px;
  padding-right: 130px;

  flex-direction: column;
  align-items: center;
  gap: 74px;
  background: var(--Bg-cards-buttons, #0f161b);
}
.Footer-section-logos {
  display: flex;
  align-items: center;
  gap: 120px;
  align-self: stretch;
}
.Footer-logo {
  fill: var(
    --Neon-gradient,
    linear-gradient(127deg, #f717fe 10.33%, #0ee2fc 91.7%)
  );
  width: 188px;
  height: 146.222px;
}
.Footer-Sections {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 0 0;
}
.Footer-Sections-Columns {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}
.Footer-Column-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.Footer-column-title {
  color: #fff;
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 124%;
}
.Footer-column-section-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}
.Footer-column-section {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.Footer-column-link {
  /* color: var(--Accent-for-text, #6c84f8); hover */
  color: #fff;
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 400;
  line-height: 135%;
  letter-spacing: 0.32px;
  text-decoration: none;
  transition: color 0.3s ease-in-out;
}
/* Disabled links */
.Footer-column-link.disabled {
  color: gray !important; /* Makes disabled links look faded */
  pointer-events: none !important; /* Prevents clicks */
  cursor: not-allowed !important; /* Shows 'not allowed' cursor */
}
.Right-Reserve-text {
  color: var(--tertiary-light, #656869);
  font-family: "Roboto";
  font-size: 12px;
  font-weight: 400;
  line-height: 135%;
  letter-spacing: 0.24px;
}
.Footer-column-link:hover {
  color: #6c84f8;
  cursor: pointer;
}

@media screen and (max-width: 400px) {
  .Footer-wrapper {
    padding: 24px;
    height: auto;
    gap: 40px;
  }

  .Footer-section-logos {
    flex-direction: column;
    gap: 40px;
    align-items: center;
  }

  .Footer-logo {
    width: 120px;
    height: auto;
  }

  .Footer-Sections {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .Footer-Sections-Columns {
    width: 100%;
    text-align: center; /* Center title */
    padding-bottom: 10px;
  }

  /* Centering title and arrow */
  .Footer-column-title {
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    padding: 10px;
    background: none;
    border: none;
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center;
    gap: 8px;
    color: white;
    width: 100%;
  }

  .Footer-column-title svg {
    transition: transform 0.3s ease;
  }

  .Footer-column-title.open svg {
    transform: rotate(180deg);
  }

  /* Centering the collapsible content */
  .Footer-column-section-wrapper {
    display: none;
    flex-direction: column;
    align-items: center; /* Center content */
    justify-content: center; /* Ensure vertical centering */
    gap: 10px;
    width: 100%;
  }

  /* When a section is open */
  .Footer-column-section-wrapper.open {
    display: flex;
  }

  .Footer-column-section {
    display: flex;
    justify-content: center; /* Center text */
    align-items: center;
    width: 100%;
  }

  .Footer-column-link {
    font-size: 14px;
    text-align: center; /* Ensures text is centered */
    width: 100%;
  }

  .Right-Reserve-text {
    text-align: center;
    font-size: 10px;
  }
}

@media screen and (max-width: 450px) and (min-width: 401px) {
  .Footer-wrapper {
    padding: 32px;
    height: auto;
    gap: 50px;
  }

  .Footer-section-logos {
    flex-direction: column;
    gap: 50px;
    align-items: center;
  }

  .Footer-logo {
    width: 140px;
    height: auto;
  }

  .Footer-Sections {
    flex-direction: column;
    align-items: center;
    gap: 15px;
    width: 100%;
  }

  .Footer-Sections-Columns {
    width: 100%;
    text-align: center;
    padding-bottom: 15px;
  }

  /* Button for collapsible sections */
  .Footer-column-title {
    font-size: 17px;
    font-weight: bold;
    cursor: pointer;
    padding: 12px;
    background: none;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: white;
    width: 100%;
  }

  .Footer-column-title svg {
    transition: transform 0.3s ease;
  }

  .Footer-column-title.open svg {
    transform: rotate(180deg);
  }

  /* Initially HIDDEN collapsible content */
  .Footer-column-section-wrapper {
    display: none; /* Default hidden */
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
    width: 100%;
  }

  /* Show section when opened */
  .Footer-column-section-wrapper.open {
    display: flex;
  }

  .Footer-column-section {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .Footer-column-link {
    font-size: 15px;
    text-align: center;
    width: 100%;
  }

  /* Copyright text */
  .Right-Reserve-text {
    text-align: center;
    font-size: 11px;
  }
}

/* ✅ Fix desktop issue: Make sections always open for larger screens */
@media screen and (min-width: 451px) {
  .Footer-column-section-wrapper {
    display: flex !important; /* Ensures sections stay open */
  }

  .Footer-column-title svg {
    display: none !important; /* Hides arrow on desktop */
  }

  .Footer-column-title {
    background: none !important;
    border: none !important;
    padding: 10px 0 !important;
  }
}
