@import "../../mainVariables.css";

.freedomCards {
  display: flex;
  padding: var(--split-padding);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}

.freedomCards-container {
  display: flex;
  width: 100%;
  max-width: var(--max-width);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--card-gap);
}

.freedomCards-card-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
}

.freedomCards-card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
}

.freedomCards-card-background {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  width: 100%;
  height: 200px;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  background: var(--card-color);
}

.freedomCards-card-text {
  display: flex;
  padding: 5px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;
  flex: 1 0 0;
}

.freedomCards-card-text-title {
  text-align: left;
  align-self: stretch;
  color: var(--text-color);
  font-size: var(--card-title-text);
  font-weight: 600;
}

.freedomCards-card-text-description {
  text-align: left;
  align-self: stretch;
  color: var(--para-text-color);
  font-size: var(--card-paragraphc-text);
  font-weight: 400;
}

.freedomCards-card-picture {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 8px;
  z-index: 2;
}

.freedomCards-card-picture img {
  width: auto;
  height: 340px;
}

@media screen and (max-width: 950px) {
  .freedomCards-card-picture img {
    height: 270px;
  }
}

@media screen and (max-width: 850px) {
  .freedomCards-card-picture img {
    height: 250px;
  }
}

@media screen and (max-width: 768px) {
  .freedomCards-card-picture img { 
    height: 200px;
  }

  .freedomCards-card-background {
    height: 160px;
  }
}

@media screen and (max-width: 650px) {
  .freedomCards-card-picture img {
    height: 180px;
  }
}

@media screen and (max-width: 600px) {
  .freedomCards-card-picture img {
    height: 160px;
  }
}

@media screen and (max-width: 550px) {
  .freedomCards-card-picture img {
    height: 200px;
  }

  .freedomCards-card {
    flex-direction: column;
    gap: var(--card-gap);
  }

  .freedomCards-card-picture {
    order: -1;
  }

  .freedomCards-card-background {
    top: inherit;
    bottom: 0;
    transform: translateY(0);
    height: 160px;
  }

  .freedomCards-card-text-title {
    text-align: center;
  }

  .freedomCards-card-text-description {
    text-align: center;
  }
}

@media screen and (max-width: 500px) {
  .freedomCards-card-picture img {
    height: 160px;
  }
}

@media screen and (max-width: 400px) {
}

@media screen and (max-width: 350px) {
  .freedomCards-card-picture img {
    height: 140px;
  }
}
