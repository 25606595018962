@import "../../mainVariables.css";

.freedomFeatures {
  position: relative;
  display: flex;
  padding: var(--no-padding);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}

.freedomFeatures-container {
  display: flex;
  width: 100%;
  max-width: var(--max-width);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--card-gap);
}

.freedomFeatures-title {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--text-color);
  text-align: center;
  font-size: var(--main-title-text);
  font-weight: 700;
  align-self: stretch;
}

.freedomFeatures-features {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(calc(33.33% - 20px), 1fr));
  grid-gap: var(--card-gap);
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: var(--card-gap);
  align-self: stretch;
}

.freedomFeatures-feature {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 180px;
  border-radius: 15px;
  background: var(--special-card-color);
}

.freedomFeatures-feature-icon {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding: 15px;
}

.freedomFeatures-feature-icon img {
  width: auto;
  height: 80px;
}

.freedomFeatures-feature-text {
  display: flex;
  padding: 25px 25px 25px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 5px;
  flex: 1 0 0;
  align-self: stretch;
}

.freedomFeatures-feature-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;

  color: var(--text-color);
  text-align: left;
  font-size: var(--card-title-text);
  font-weight: 500;
}

.freedomFeatures-feature-description {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
  align-self: stretch;

  color: var(--para-text-color);
  text-align: left;
  font-size: var(--card-paragraph-text);
  font-weight: 500;
}

.freedomFeatures-background {
  position: absolute;
  opacity: 0.1;
  right: -400px;
  top: -500px;
  filter: blur(50px);
  z-index: 0;
}

.freedomFeatures-background img {
  color: var(--gradiant3vo);
  width: 1400px;
}

@media screen and (max-width: 950px) {
}

@media screen and (max-width: 850px) {
  .freedomFeatures-feature {
    display: flex;
    flex-direction: column;
    height: fit-content;
  }

  .freedomFeatures-feature-text {
    padding: 0px 25px 25px 25px;
  }

  .freedomFeatures-feature-title {
    padding: 0px;
    width: 100%;
    align-items: center;
    text-align: center;
  }

  .freedomFeatures-feature-description {
    padding: 0px;
    width: 100%;
    align-items: center;
    text-align: center;
    justify-content: center;
  }
}

@media screen and (max-width: 768px) {
  .freedomFeatures-feature-icon img {
    height: 60px;
  }
}

@media screen and (max-width: 650px) {
}

@media screen and (max-width: 600px) {
  .freedomFeatures-feature-icon img {
    height: 40px;
  }
}

@media screen and (max-width: 500px) {
  .freedomFeatures-features {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(calc(50% - 20px), 1fr));
    grid-gap: var(--card-gap);
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: var(--card-gap);
    align-self: stretch;
  }

  .freedomFeatures-feature {
    min-height: 200px;
  }
}

@media screen and (max-width: 400px) {
}

@media screen and (max-width: 350px) {
}
