@import "../../mainVariables.css";

.footer {
  display: flex;
  flex-direction: column;
  padding: 25px 0px 0px 0px;
  padding: 50px 0px;
  justify-content: center;
  align-items: center;
  gap: 15px;
  background-color: var(--footer-background-color);
  backdrop-filter: blur(5px);
  z-index: 1000;
}

.footer-container {
  display: flex;
  width: 100%;
  max-width: 1110px;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.footer-left {
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  align-self: stretch;
}

.footer-left-icons {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}

.footer-left-icons a {
  display: flex;
  gap: 10px;
}

.footer-left-icons img {
  height: 32px;
  width: auto;
}

.footer-left-social {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.footer-left-social a {
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--social-color);
  width: 32px;
  height: 32px;
  border-radius: 40px;
  color: var(--social-link-color);
}

.footer-left-social a:hover {
  transition: color 0.2s;
  background: var(--social-gradiant3vo);
  color: var(--background-color);
}

.footer-left-rights {
  display: flex;
  align-items: center;
  gap: 8px;

  text-align: left;
  color: var(--para-text-color);

  font-size: 18px;

  font-weight: 400;
}

.footer-right {
  display: flex;
  padding-right: 20px;
  justify-content: center;
  align-items: flex-start;
  gap: 15px;
  flex: 1 0 0;
  align-self: stretch;
}

.footer-right-section {
  display: flex;
  padding: 0px 50px;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  flex: 1 0 0;
  align-self: stretch;
}

.footer-right-section-title {
  color: var(--text-color);

  font-size: 18px;

  font-weight: 600;
}

.footer-right-section a {
  text-decoration: none;
}

.footer-right-section-link {
  color: var(--para-text-color);

  font-size: 16px;

  font-weight: 400;

  transition: color 0.3s, font-weight 0.3s;
  text-align: left;
}

.footer-right-section-link:hover {
  transition: color 0.3s, font-weight 0.3s;
  color: var(--text-color);
}

.footer-rights {
  width: 100%;
  max-width: 1110px;
  display: flex;
  height: 65px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;

  border-top: 1px solid var(--card-color);
  color: var(--para-text-color);

  font-size: 14px;

  font-weight: 400;
}

@media screen and (max-width: 1000px) {
  .footer-right-section {
    display: flex;
    padding: 0px 0;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    flex: 1 0 0;
    align-self: stretch;
  }
}

@media screen and (max-width: 800px) {
  .footer {
    padding: 25px 0px 0px 0px;
  }

  .footer-left {
    gap: 15px;
  }

  .footer-left-social a {
    font-size: 16px;

    width: 28px;
    height: 28px;
  }
}

@media screen and (max-width: 650px) {
  .footer-container {
    flex-direction: column;
  }

  .footer-left {
    padding-left: 0 20px;
  }

  .footer-left-icons {
    align-items: center;
    justify-content: center;
    padding: 0 20px;
  }

  .footer-left-social {
    align-items: center;
    justify-content: center;
    padding: 0 20px;
  }

  .footer-right {
    padding: 0 20px;
    align-items: center;
    justify-content: space-between;
  }

  .footer-right-section {
    display: flex;
    flex-direction: column;
    flex: none;
    width: 110px;
    align-items: flex-start;
    gap: 15px;
    align-self: stretch;
  }
}

@media screen and (max-width: 500px) {
  .footer-left-icons a {
    display: flex;
    gap: 8px;
  }

  .footer-left-icons img {
    height: 22px;
    width: auto;
  }

  .footer-left {
    padding-left: 0;
  }

  .footer-right {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
  }
}
