@import "../../mainVariables.css";

.tokenStatement {
  display: flex;
  padding: var(--split-padding);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}

.tokenStatement-container {
  display: flex;
  width: 100%;
  max-width: var(--max-width);
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.tokenStatement-title {
  display: flex;
  flex-direction: column;
  align-items: center;

  text-align: center;
  color: var(--text-color);
  font-size: var(--statement-title-text);
  font-weight: 300;
}

.tokenStatement-background {
  position: absolute;
  opacity: 0.1;
  right: 540px;
  top: 636px;
  filter: blur(25px);
  z-index: 0;
}

.tokenStatement-background img {
  color: var(--gradiant3vo);
  width: 1500px;
}

@media screen and (max-width: 950px) {
}

@media screen and (max-width: 850px) {
}

@media screen and (max-width: 768px) {
  .tokenStatement-background {
    right: 240px;
    top: 636px;
  }
}

@media screen and (max-width: 650px) {
}

@media screen and (max-width: 600px) {
}

@media screen and (max-width: 500px) {
  .statement-background {
    right: 140px;
    top: 636px;
  }
}

@media screen and (max-width: 400px) {
}

@media screen and (max-width: 350px) {
}
