body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

* {
  transition: width 0.3s ease, height 0.3s ease;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */
@import url("https://fonts.googleapis.com/css?family=Michroma:400");
@font-face {
  font-family: "Ligconsolata-Regular";
  src: url("https://anima-uploads.s3.amazonaws.com/projects/64d4ce5483dc093bd715fcf9/fonts/cairo-medium.ttf")
    format("truetype");
}

@font-face {
  font-family: "Ligconsolata-Bold";
  src: url("https://anima-uploads.s3.amazonaws.com/projects/642ae12125d85fc173442e31/fonts/inconsolata-variablefont_wdth-wght.ttf")
    format("truetype");
}
@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");
* {
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}
html,
body {
  margin: 0px;
  height: 100%;
}
/* a blue color as a generic focus style */
button:focus-visible {
  outline: 2px solid #4a90e2 !important;
  outline: -webkit-focus-ring-color auto 5px !important;
}
a {
  text-decoration: none;
}
