@import "../../mainVariables.css";

.usecasesCards {
  position: relative;
  display: flex;
  padding: 30px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}

.usecasesCards-container {
  display: flex;
  width: 100%;
  max-width: var(--max-width);
  flex-direction: column;
  align-items: center;
  gap: var(--card-gap);
  z-index: 1;
}

.usecasesCards-card-container {
  display: flex;
  max-width: 1110px;
  justify-content: center;
  align-items: flex-start;
  gap: var(--card-gap);
  align-self: stretch;
}

.usecasesCards-card-container-h {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
}

.usecasesCards-card-h {
  display: flex;
  height: 220px;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;

  border-radius: 15px;
  background: var(--special-card-color);
}

.usecasesCards-card-text-h {
  display: flex;
  padding: 5px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;
  flex: 1 0 0;
  align-self: stretch;
}

.usecasesCards-card-text-title-h {
  align-self: stretch;
  color: var(--text-color);
  font-size: var(--card-title-text);
  font-weight: 600;
  text-align: left;
}

.usecasesCards-card-text-description-h {
  align-self: stretch;
  color: var(--para-text-color);
  font-size: var(--card-paragraph-text);
  font-weight: 400;
  text-align: left;
}

.usecasesCards-card-image-h {
  display: flex;
  padding-top: 40px;
  flex-direction: column;
  align-items: center;

  flex: 1 0 0;
  align-self: stretch;
}

.usecasesCards-card {
  display: flex;
  text-decoration: none;

  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  flex: 1 0 0;
  border-radius: 15px;
  background: var(--card-color);
  overflow: hidden;
}

/* .usecasesCards-card:hover .usecasesCards-card-image {
  filter: drop-shadow(0 0 5px var(--button-glow-color))
    drop-shadow(0 0 10px var(--button-glow-color))
    drop-shadow(0 0 30px var(--button-glow-color));
} */

.usecasesCards-card-text {
  display: flex;
  padding: 15px;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  align-self: stretch;
}

.usecasesCards-card-text-title {
  align-self: stretch;
  color: var(--text-color);
  font-size: var(--card-title-text);
  font-weight: 600;
}

.usecasesCards-card-text-description {
  align-self: stretch;
  color: var(--para-text-color);
  font-size: var(--card-paragraph-text);
  font-weight: 400;
}

.usecasesCards-card-image {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
  overflow: hidden;
}

.usecasesCards-card-image img {
  width: 100%;
}

.usecasesCards-card-image-learn {
  border-radius: 50px;
  background-color: var(--card-over-color);
  color: var(--text-color);
  text-decoration: none;
  position: absolute;
  bottom: 10px;
  right: 10px;
  padding: 5px 10px;
}

.usecasesCards-card:hover .usecasesCards-card-image-learn {
  color: var(--button-text-color);
  filter: drop-shadow(0 0 5px var(--button-glow-color))
    drop-shadow(0 0 6px var(--button-glow-color))
    drop-shadow(0 0 7px var(--button-glow-color));
}

.usecasesCards-background {
  position: absolute;
  opacity: 0.1;
  right: 200px;
  top: 636px;
  filter: blur(25px);
  z-index: 0;
}

.usecasesCards-background img {
  color: var(--gradiant3vo);
  width: 1500px;
}

@media screen and (max-width: 950px) {
}

@media screen and (max-width: 850px) {
}

@media screen and (max-width: 768px) {
  .usecasesCards-background {
    right: 240px;
    top: 636px;
  }
}

@media screen and (max-width: 650px) {
}

@media screen and (max-width: 600px) {
  .usecasesCards-card-image-h {
    display: none;
  }
}

@media screen and (max-width: 500px) {
  .statement-background {
    right: 140px;
    top: 636px;
  }
}

@media screen and (max-width: 400px) {
  .usecasesCards-card-h {
    display: flex;
    flex-direction: column;
    height: 150px;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;

    border-radius: 15px;
    background: var(--special-card-color);
  }

  .usecasesCards-card-text-title-h {
    text-align: center;
  }

  .usecasesCards-card-text-description-h {
    text-align: center;
  }

  .usecasesCards-card-image-h {
    display: none;
  }

  .usecasesCards-card-container {
    flex-direction: column;
  }
}

@media screen and (max-width: 350px) {
}
