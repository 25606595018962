@import "../../mainVariables.css";

.usecasesMore {
  position: relative;
  display: flex;
  padding: var(--statement-padding);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}

.usecasesMore-container {
  display: flex;
  width: 100%;
  max-width: var(--max-width);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--card-gap);
}

.usecasesMore-title {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--text-color);
  text-align: center;
  font-size: var(--main-title-text);
  font-weight: 700;
  align-self: stretch;
}

.usecasesMore-features {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(calc(25% - 20px), 1fr));
  grid-gap: var(--card-gap);
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: var(--card-gap);
  align-self: stretch;
}

.usecasesMore-feature {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 180px;
  border-radius: 15px;
  background: var(--special-card-color);
}

.usecasesMore-feature-icon {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding: 15px 15px 0 15px;
}

.usecasesMore-feature-icon img {
  width: auto;
  height: 80px;
}

.usecasesMore-feature-text {
  display: flex;
  padding: 0px 25px 25px 25px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  flex: 1 0 0;
  align-self: stretch;
}

.usecasesMore-feature-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;

  color: var(--text-color);
  text-align: center;
  font-size: var(--card-title-text);
  font-weight: 500;
}

.usecasesMore-feature-description {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
  align-self: stretch;

  color: var(--para-text-color);
  text-align: left;
  font-size: var(--card-paragraph-text);
  font-weight: 500;
}

.usecasesMore-background {
  position: absolute;
  opacity: 0.1;
  right: -400px;
  top: -500px;
  filter: blur(50px);
  z-index: 0;
}

.usecasesMore-background img {
  color: var(--gradiant3vo);
  width: 1400px;
}

@media screen and (max-width: 950px) {
}

@media screen and (max-width: 850px) {
}

@media screen and (max-width: 768px) {
  .usecasesMore-feature-icon img {
    height: 60px;
  }
}

@media screen and (max-width: 650px) {
}

@media screen and (max-width: 600px) {
}

@media screen and (max-width: 500px) {
  .usecasesMore-feature {
    min-height: none;
    max-height: none;
  }

  .usecasesMore-features {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(calc(50% - 20px), 1fr));
    grid-gap: var(--card-gap);
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: var(--card-gap);
    align-self: stretch;
  }
}

@media screen and (max-width: 400px) {
}

@media screen and (max-width: 350px) {
}
