@import "../../mainVariables.css";

.dappsCards {
  display: flex;
  padding: var(--horizantal-padding);
  flex-direction: column;
  align-items: center;
  gap: var(--card-gap);
  align-self: stretch;
}

.dappsCards-container {
  display: flex;
  width: 100%;
  max-width: var(--max-width);
  flex-direction: column;
  align-items: center;
  gap: var(--card-gap);
}

.dappsCards-row {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: var(--card-gap);
  align-self: stretch;
  z-index: 1;
}

.dappsCards-dapp {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  flex: 1 0 0;

  border-radius: 15px;
  background: var(--card-color);
}

.dappsCards-dapp-special {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex: 1 0 0;
  height: 220px;

  border-radius: 15px;
  background: var(--special-card-color);
}

.dappsCards-dapp-text {
  display: flex;
  height: 90px;
  padding: 15px;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  align-self: stretch;
}

.dappsCards-dapp-special .dappsCards-dapp-text {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: auto;
}

.dappsCards-dapp-text-title {
  align-self: stretch;

  color: var(--text-color);
  text-align: center;
  font-size: var(--card-title-text);
  font-weight: 600;
}

.dappsCards-dapp-special .dappsCards-dapp-text-title {
  text-align: left;
}

.dappsCards-dapp-text-description {
  align-self: stretch;

  color: var(--para-text-color);
  text-align: center;
  font-size: var(--card-paragraph-text);
  font-weight: 400;
}

.dappsCards-dapp-special .dappsCards-dapp-text-description {
  text-align: left;
}

.dappsCards-dapp-special .dappsCards-dapp-icons {
  display: flex;
  padding: 25px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  min-height: 100px;
}

.dappsCards-dapp-icons {
  display: flex;
  padding: 25px;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  min-height: 100px;
}

.dappsCards-dapp-icons-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
}

.dappsCards-dapp-icons-icon img {
  height: 50px;
  width: auto;
}

.dappsCards-dapp-icons-title {
  align-self: stretch;
  color: var(--text-color);
  text-align: center;
  font-size: calc(var(--card-paragraph-text) - 2px);
  font-weight: 400;
}

.dappsCards-background {
  position: absolute;
  opacity: 0.1;
  right: 540px;
  top: 636px;
  filter: blur(25px);
  z-index: 0;
}

.dappsCards-background img {
  color: var(--gradiant3vo);
  width: 1500px;
}

@media screen and (max-width: 950px) {
  .dappsCards-row {
    flex-direction: column;
  }

  .dappsCards-dapp {
    width: 100%;
  }

  .dappsCards-dapp-special {
    width: 100%;
    flex-direction: column;
  }

  .dappsCards-dapp-special .dappsCards-dapp-text-title {
    text-align: center;
  }

  .dappsCards-dapp-special .dappsCards-dapp-text-description {
    text-align: center; 
  }
}

@media screen and (max-width: 850px) {
}

@media screen and (max-width: 768px) {
  .dappsCards-background {
    right: 240px;
    top: 636px;
  }

  .dappsCards-dapp-icons-icon img {
    height: 40px;
    width: auto;
  }

  .dappsCards-dapp-icons {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 650px) {
}

@media screen and (max-width: 600px) {
}

@media screen and (max-width: 500px) {
  .statement-background {
    right: 140px;
    top: 636px;
  }
}

@media screen and (max-width: 400px) {
}

@media screen and (max-width: 350px) {
}
