@import "../../mainVariables.css";

.projectTeam {
  position: relative;
  display: flex;
  padding: var(--no-padding);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}

.projectTeam-container {
  display: flex;
  width: 100%;
  max-width: var(--max-width);
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: var(--card-gap);
}

.projectTeam-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}

.projectTeam-title-title {
  color: var(--text-color);
  text-align: center;
  font-size: 70px;

  font-weight: 700;
}

.projectTeam-team {
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 15px 60px;
  align-self: stretch;
  flex-wrap: wrap;
}

.projectTeam-member {
  display: flex;
  width: var(--team-circle);
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.projectTeam-member-picture {
  position: relative;
  width: var(--team-circle);
  height: var(--team-circle);
  border-radius: var(--team-circle);
  background-color: var(--special3vo);
  overflow: hidden;
}

.projectTeam-member-picture img {
  display: flex;
  width: var(--team-circle);
  height: var(--team-circle);
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.projectTeam-member-links {
  display: none;
  background: var(--special-card-color);
  height: var(--team-circle);
  width: var(--team-circle);
  align-items: center;
  justify-content: space-evenly;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.projectTeam-member-picture:hover .projectTeam-member-links {
  background: var(--special-card-color-overlay);
  display: flex;
  transition: background 0.5s;
}

.projectTeam-member-links-twitter {
  color: var(--text-color);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
}

.projectTeam-member-links-twitter-na {
  display: none;
  color: var(--para-text-color);
  font-size: 40px;
}

.projectTeam-member-links-design {
  background-color: var(--text-color);
  width: 2px;
  height: var(--team-circle);
}

.projectTeam-member-links-linkedin {
  color: var(--text-color);
  font-size: 40px;
}

.projectTeam-member-links-linkedin-na {
  display: none;
  color: var(--para-text-color);
  font-size: 40px;
}

.projectTeam-member-text {
  padding: 5px;
}

.projectTeam-member-text-name {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;

  color: var(--text-color);
  text-align: center;
  font-size: calc(var(--statement-paragraph-text) + 2);
  font-weight: 500;
}

.projectTeam-member-text-title {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;

  color: var(--para-text-color);
  text-align: center;
  font-size: var(--statement-paragraph-text);
  font-weight: 400;
}

.projectTeam-background {
  position: absolute;
  opacity: 0.1;
  right: -400px;
  top: -200px;
  filter: blur(50px);
  z-index: 0;
}

.projectTeam-background img {
  color: var(--gradiant3vo);
  width: 1400px;
}

@media screen and (max-width: 850px) {
  .projectTeam-title-title {
    font-size: 62px;
  }
}

@media screen and (max-width: 650px) {
  .projectTeam-title-title {
    font-size: 54px;
  }
}

@media screen and (max-width: 500px) {
  .projectTeam-title-title {
    font-size: 48px;
  }

  .projectTeam-comingsoon img {
    width: 160px;
  }
}

@media screen and (max-width: 400px) {
  .projectTeam-title-title {
    font-size: 42px;
  }
}

@media screen and (max-width: 360px) {
  .projectTeam-title-title {
    font-size: 32px;
  }

  .projectTeam-button-title {
    font-size: 16px;
  }

  .projectTeam-button {
    justify-content: center;
    width: 100%;
    padding: 5px 0px;
  }
}
