.quests-main {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.quests-search-container {
  position: relative;
}

.search-input-container {
  display: flex;
  align-items: center;
  position: relative;
}

.search-input {
  width: 100%;
  height: 50px;
  padding: 0px 15px;
  padding-left: 40px; /* Space for icon */
  border-radius: 15px;
  border: 1px solid var(--background-color);
  background: var(--card-second-border-color);
  color: var(--para-text-color);
  font-size: 22px;
  font-weight: 400;
}

.search-icon {
  position: absolute;
  left: 15px;
  color: var(--para-text-color);
}

.search-results {
  position: absolute;
  margin-top: 10px;
  top: 100%;
  left: 0;
  z-index: 1000;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  background-color: var(--background-color);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
}

.search-item div {
  background-color: var(--card-second-border-color);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 10px 0px 10px;
  cursor: pointer;

  gap: 10px;
}

.quests-leaderboard-loading {
}

.search-item p {
  color: var(--text-color);
  font-weight: 600;
}

.search-item-username {
  font-size: 14px;
  color: var(--para-text-color) !important;
  font-weight: 400 !important;
}

.search-item img {
  height: 35px;
  width: 35px;
  border-radius: 100px;
  object-fit: cover;
}

.search-item:hover {
  background-color: #f5f5f5;
}

.dark-theme .search-item:hover {
  background-color: #333;
  color: #fff;
}

.quests-search-results-container {
  display: flex;
  height: 300px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;

  border-radius: 15px;
  background-color: var(--card-color);

  color: var(--para-text-color);
  text-align: center;
  font-size: 22px;
}

.quests-search-leaderboard-container {
  display: flex;
  height: 500px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;

  border-radius: 15px;
  background-color: var(--card-color);

  color: var(--para-text-color);
  text-align: center;
  font-size: 22px;
}

.quests-search-results-container-filled {
  display: flex;
  height: 350px;
  max-width: 1110px;
  justify-content: center;
  align-items: flex-start;
  gap: 25px;
  align-self: stretch;

  border-radius: 15px;

  color: var(--para-text-color);
  text-align: center;
  font-size: 22px;
}

.quests-results-main {
  display: flex;
  width: 250px;
  padding: 25px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 15px;
  background-color: var(--special-card-color);
}

.quests-results-main-sp {
  display: flex;
  width: 100%;
  padding: 25px;
  gap: 25px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 15px;
  background-color: var(--special-card-color);
}

.quests-results-main-top-sp {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}

.quests-results-other {
  display: flex;
  padding: 20px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 15px;
  background-color: var(--card-color);
}

.quests-results-main-top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
}

.quests-results-name-container {
  display: flex;
  padding: 15px;
  flex-direction: column;
  align-items: center;
  gap: 5px;

  color: var(--text-color);
  text-align: center;
  font-size: 24px;
  font-weight: 700;
}

.quests-results-image-container {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: var(--special3vo);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.quests-results-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
}

.quests-results-main-rank {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
}

.quests-results-rank-title {
  width: 100%;
  color: var(--para-text-color);
  font-size: 18px;
  font-weight: 400;
  text-align: left;
}

.quests-results-rank-value {
  width: 100%;
  color: var(--purple3vo);
  text-align: center;
  font-size: 30px;
  font-weight: 700;
  text-align: left;
}

.quests-results-main-points {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
}

.quests-results-points-title {
  width: 100%;
  color: var(--para-text-color);
  font-size: 18px;
  font-weight: 400;
  text-wrap: wrap;
  text-align: left;
}

.quests-results-points-value {
  width: 100%;
  color: var(--special3vo);
  text-align: center;
  font-size: 30px;
  font-weight: 700;
  text-align: right;
}

.quests-results-reward-value {
  width: 100%;
  color: var(--green3vo);
  text-align: center;
  font-size: 30px;
  font-weight: 700;
  text-align: right;
}

.quests-results-other-row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  flex-wrap: wrap;
  gap: 15px;
}

.quests-results-other-item {
  min-width: 130px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  align-self: stretch;
}

.quests-results-other-item-title {
  align-self: stretch;
  color: var(--para-text-color);
  text-align: center;
  font-size: 24px;
  font-weight: 400;
}

.quests-results-other-item-value {
  align-self: stretch;
  color: var(--text-color);
  text-align: center;
  font-size: 24px;
  font-weight: 700;
}

.quests-results-other-item-points {
  align-self: stretch;
  color: var(--special3vo);
  text-align: center;
  font-size: 24px;
  font-weight: 700;
}

.quests-leaderboard-loading {
  display: flex;
  background: var(--card-color);
  height: 500px;
  width: 100%;
  max-width: 1110px;
  justify-content: center;
  align-items: center;

  align-self: stretch;
  border-radius: 15px;
  color: #fff;
}

.quests-leaderboard-loading::before {
  content: "";
  border: 4px solid var(--special3vo);
  border-left-color: #000;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin-right: 10px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.quests-leaderboard-container {
  display: flex;
  max-width: 1110px;
  justify-content: center;
  align-items: flex-start;
  gap: 25px;
  align-self: stretch;
}

.quests-leaderboard-mvp {
  display: flex;
  width: 300px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 15px;
  background: var(--special-card-color);
}

.quests-leaderboard-mvp img {
  height: 130px;
  width: 130px;
  border-radius: 500px;
}

.quests-leaderboard-mvp-title {
  display: flex;
  padding: 15px;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  align-self: stretch;

  color: var(--text-color);
  text-align: center;
  font-size: 24px;
  font-weight: 700;
}

.quests-leaderboard-mvp-points {
  width: 100%;
  display: flex;

  flex-direction: column;
  align-items: center;
  gap: 5px;
  align-self: stretch;

  color: var(--special3vo);
  text-align: center;
  font-size: 48px;
  font-weight: 700;
}

.quests-leaderboard-mvp-name {
  width: 100%;
  display: flex;
  padding-top: 15px;

  flex-direction: column;
  align-items: center;
  gap: 5px;
  align-self: stretch;

  color: var(--text-color);
  text-align: center;
  font-size: 24px;
  font-weight: 700;
}

.quests-leaderboard-mvp-details {
  display: flex;
  flex-direction: column;
}

.quests-leaderboard-table {
  width: 100%;
  border-collapse: collapse;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 15px;
  background: var(--card-color);
  border-spacing: 0;
}

.quests-leaderboard-table tbody,
.quests-leaderboard-table thead {
  width: 100%;
}

.quests-leaderboard-table thead {
  background-color: var(--table-color-2);
}

.quests-leaderboard-table th {
  width: 100%;
  padding: 10px;
  border: none;
  text-align: left;

  color: var(--text-color);
  font-size: 20px;
  font-weight: 600;
  color: var(--para-text-color);
}

.quests-leaderboard-table tr {
  display: flex;
  width: 100%;
  border: none;
  text-align: left;
  align-items: center;
}

.quests-leaderboard-table td {
  display: flex;
  width: 100%;
  padding: 5px;
  border: none;
  text-align: left;
  align-items: center;
  color: var(--text-color);
  font-size: 20px;
  font-weight: 400;
}

.quests-leaderboard-table tr:nth-child(even) {
  background-color: var(--table-color-2);
}

.quests-leaderboard-table tr:nth-child(odd) {
  background-color: var(--table-color-1);
}

.quests-leaderboard-table td:nth-child(1),
.quests-leaderboard-table th:nth-child(1) {
  display: flex;
  color: var(--para-text-color);
  justify-content: center !important;
  width: 20px;
  min-width: 20px;
}

.quests-leaderboard-table td:nth-child(2),
.quests-leaderboard-table th:nth-child(2) {
  min-width: 200px;
  gap: 10px;
  font-weight: 600;
  font-size: 16px;
  color: var(--para-text-color);
}

.quests-leaderboard-table td:nth-child(3),
.quests-leaderboard-table th:nth-child(3) {
  display: flex;
  width: 25%;
  justify-content: center !important;
  color: var(--special3vo);
  font-weight: 600;
  font-size: 16px;
}

.quests-leaderboard-table td:nth-child(4),
.quests-leaderboard-table th:nth-child(4) {
  display: flex;
  width: 25%;
  justify-content: center !important;
  color: var(--purple3vo);
  font-weight: 600;
  font-size: 16px;
}

.quests-leaderboard-table td:nth-child(5),
.quests-leaderboard-table th:nth-child(5) {
  display: flex;
  width: 25%;
  justify-content: center !important;
  color: var(--green3vo);
  font-weight: 600;
  font-size: 16px;
}

.user-image {
  height: 35px;
  width: 35px;
  border-radius: 100px;
  object-fit: cover;
}

.quest-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 40px 0px;
  gap: 15px;
  width: 100%;
  z-index: 1;

  color: var(--text-color);

  font-size: 50px;

  font-weight: 700;
}

.countdown {
  color: var(--text-color);
  font-weight: 600;
  font-size: 16px;
}

.timer-cointainer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-bottom: 15px;
  margin-top: -30px;
}

.quests-rules {
  padding-top: 25px;
  text-align: left;

  width: 100%;
  color: var(--para-text-color);
  font-weight: 400;
  font-size: 14px;
}

.quests-rules-title {
  font-size: 20px;
  color: var(--text-color);
}

.quest-speical-word {
  color: var(--special3vo);
}

.quest-speical-word1 {
}

.quest-time-update {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-top: 15px;

  color: var(--para-text-color);
  font-weight: 400;
  font-size: 14px;
}

.loading-spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: 15px;
}

.loading-spinner {
  border: 4px solid rgba(0, 0, 0, 0.196);
  border-top: 4px solid var(--special3vo);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.quests-special-point {
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 15px;
  flex-wrap: wrap;
}

.quests-special-point-item img {
  height: 100px;
  border-radius: 15px;
  overflow: hidden;
  /* border: solid 3px var(--green3vo); */
}

.quests-special-point-item-inactive img {
  height: 100px;
  filter: saturate(0);
  opacity: 0.5;
  border: none;
  border-radius: 15px;
  overflow: hidden;
}

@media screen and (max-width: 950px) {
  .quests-results-other {
    flex-direction: column;
  }
}

@media screen and (max-width: 850px) {
  .quests-results-main-sp {
    width: auto;
  }

  .quests-results-other {
    flex-direction: row;
  }

  .quests-leaderboard-container {
    flex-direction: column;
  }

  .quests-leaderboard-mvp {
    position: relative;
    width: auto;
    flex-direction: row;
    height: 200px;
    justify-content: space-evenly;
    gap: 200px;
  }

  .quests-leaderboard-mvp-title {
    justify-content: center;
  }

  .quests-leaderboard-mvp-details img {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
  }

  .quests-search-results-container-filled {
    flex-direction: column;
    height: auto;
  }

  .quests-results-main {
    position: relative;
    width: auto;
    flex-direction: row;
    gap: 25%;
  }

  .quests-results-main-top {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .quests-selected-points-container {
    display: flex;
    flex-direction: column;
    width: 50%;
  }
}

@media screen and (max-width: 650px) {
  .quests-leaderboard-mvp-points {
    font-size: 40px;
  }

  .quests-results-points-value {
    font-size: 40px;
  }

  .quests-results-rank-value {
    font-size: 40px;
  }

  .quests-results-reward-value {
    font-size: 20px;
  }

  .quests-selected-points-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .quests-results-main {
    gap: 10%;
  }

  .quests-results-other-item-title {
    font-size: 20px;
  }

  .quests-results-other-item-value {
    font-size: 20px;
  }

  .quests-results-other-item-points {
    font-size: 20px;
  }

  .quests-special-point {
    padding-top: 20px;
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 15px;
    flex-wrap: wrap;
  }

  .quests-special-point-item {
    border-radius: 15px;
    overflow: hidden;
  }

  .quests-special-point-item img {
    height: 75px;
  }

  .quests-special-point-item-inactive img {
    height: 75px;
    filter: saturate(0);
    opacity: 0.5;
  }
}

@media screen and (max-width: 550px) {
  .quests-results-other {
    flex-direction: column;
  }

  .quests-results-main {
    flex-direction: column;
  }

  .quests-leaderboard-mvp {
    display: flex;
    height: 200px;
    gap: 0;
    padding-inline: 15px;
    justify-content: space-between;
  }

  .quests-leaderboard-mvp img {
    height: 100px;
    width: 100px;
    border-radius: 500px;
  }

  .quests-leaderboard-mvp-title {
    width: 20%;
  }

  .quests-leaderboard-table td:nth-child(2),
  .quests-leaderboard-table th:nth-child(2) {
    min-width: 0;
    overflow-x: hidden;
  }

  .loading-spinner-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    gap: 15px;
  }

  .loading-spinner {
    border: 4px solid rgba(0, 0, 0, 0.196);
    border-top: 4px solid var(--special3vo);
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
  }
}

@media screen and (max-width: 450px) {
  .quests-special-point-item img {
    height: 50px;
  }

  .quests-special-point-item-inactive img {
    height: 50px;
    filter: saturate(0);
    opacity: 0.5;
  }
}
