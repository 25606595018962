.TokenFourSection-wrapper {
  /* display: flex;
  justify-content: center; */
  width: 1140px;
  height: 960.549px;
  flex-shrink: 0;
  padding-bottom: 80px;
}
.Token-Mechanics-title {
  color: var(--Total-white, #fff);
  font-family: Poppins;
  font-size: 44px;
  font-style: normal;
  font-weight: 700;
  line-height: 124%; /* 54.56px */
  letter-spacing: 2.64px;
  text-transform: capitalize;
  transform: rotate(0.038deg);
  padding-bottom: 50px;
  text-align: center;
}
.img-token-mechanics {
  width: 1140px;
  height: 857px;
  flex-shrink: 0;
  border-radius: 12px;
  /* padding-bottom: 0; */
}
@media (max-width: 576px) {
  .TokenFourSection-wrapper {
    width: 100%;
    height: auto;
    padding: 40px 20px;
    text-align: center;
  }

  .Token-Mechanics-title {
    font-size: 32px;
    letter-spacing: 1.5px;
    padding-bottom: 30px;
  }

  .img-token-mechanics {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
}
