.partners-section {
  height: 500px;
  /* width: 100%; */
  /* width: 1249.036px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  padding-left: 120px;
  padding-left: 115px;
  padding-right: 95px;
  background: var(--Bg-Dark, #080d10);
}
.partners-title {
  color: var(--Total-white, #fff);
  text-align: center;
  font-family: "Poppins";
  font-size: 44px;
  font-weight: 700;
  line-height: 124%;
  letter-spacing: 2.64px;
  text-transform: capitalize;
  width: 295px;
  height: 55px;
  transform: rotate(0.038deg);
}
.partner-container-slider {
  border-radius: 12px;
  background: var(--Bg-cards-buttons, #0f161b);
  display: flex;
  padding: 60px 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 42px;
  /* align-self: stretch; */

  overflow: hidden;
  position: relative;
  width: 100%;
}
.partner-container-slider-1 {
  height: 75px;
  align-self: stretch;
}

.partner-slider-inside,
.partner-slider-inside-2 {
  display: flex;
  align-items: center;
  gap: 62px;
  white-space: nowrap;
  width: max-content;
  animation: scroll 30s linear infinite;
}

@keyframes scroll {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-50%);
  }
}

.partner-slider-inside img,
.partner-slider-inside-2 img {
  height: 60px;
  flex-shrink: 0;
  object-fit: contain;
}

.partner-skillful {
  background: cover no-repeat;
  width: 250px;
  height: 49px;
  flex-shrink: 0;
}
.partner-3Dots {
  background: cover no-repeat;
  width: 176px;
  height: 39px;
  flex-shrink: 0;
}

.partner-Trireme {
  background: cover no-repeat;
  width: 226px;
  height: 75px;
  flex-shrink: 0;
}
.partner-stepIn {
  background: cover no-repeat;
  width: 213px;
  height: 47px;
  flex-shrink: 0;
}
.partner-arina {
  background: contain no-repeat;
  width: 127px;
  height: 59px;
  flex-shrink: 0;
}
.partner-container-slider-2 {
  width: 1187px;
  height: 60px;
}

.partner-whiteBridg {
  width: 297px;
  height: 60px;
  flex-shrink: 0;
  background: contain no-repeat;
}
.partner-schooles {
  background: contain no-repeat;
  width: 235px;
  height: 48px;
}
.partner-dmany {
  width: 155px;
  height: 48px;
}
.partner-launch {
  background: cover no-repeat;
  width: 184.802px;
  height: 58.788px;
  flex-shrink: 0;
}
.partner-amplifi {
  background: cover no-repeat;
  width: 192px;
  height: 44px;
}
.partner-chosty {
  background: cover no-repeat;
  width: 319px;
  height: 38px;
}
.partner-onePredict {
  background: cover no-repeat;
  width: 218px;
  height: 58px;
}
.partner-rouge {
  background: cover no-repeat;
  width: 170px;
  height: 44px;
}
.partner-starlight {
  background: cover no-repeat;
  width: 193px;
  height: 54px;
}
@media screen and (max-width: 400px) {
  .partner-container-slider {
    width: 275%;
  }
  .partners-section {
    height: 350px;
    padding-bottom: 35px;
  }
}
@media screen and (max-width: 450px) and (min-width: 401px) {
  .partners-section {
    height: auto;
    padding: 30px 0px;
    gap: 24px;
    margin-bottom: -30px;
  }

  .partners-title {
    font-size: 32px;
    line-height: 120%;
    text-align: center;
    letter-spacing: 1.5px;
    width: auto;
    height: auto;
  }

  .partner-container-slider {
    padding: 40px 0;
    gap: 30px;
    width: 100%;
  }

  .partner-container-slider-1,
  .partner-container-slider-2 {
    width: 100%;
    height: 60px;
  }

  .partner-slider-inside,
  .partner-slider-inside-2 {
    gap: 40px;
    animation: scroll 25s linear infinite;
  }

  .partner-slider-inside img,
  .partner-slider-inside-2 img {
    height: 50px;
    max-width: 100px;
    object-fit: contain;
  }
}

/* ✅ Fix for desktop (≥ 451px) */
@media screen and (min-width: 451px) {
  .partners-section {
    padding-left: 115px;
    padding-right: 95px;
  }

  .partners-title {
    font-size: 44px;
  }

  .partner-container-slider {
    padding: 60px 0;
    gap: 42px;
  }

  .partner-slider-inside,
  .partner-slider-inside-2 {
    gap: 62px;
    animation: scroll 30s linear infinite;
  }

  .partner-slider-inside img,
  .partner-slider-inside-2 img {
    height: 60px;
  }
}
