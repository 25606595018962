/* .hero-page .overlap-3 {
  height: 591px;
  left: -37px;
  position: relative;
  top: 18px;
  width: 571px;
} */
.hero-page .overlap-3 {
  height: 591px;
  left: -75px;
  position: relative;
  top: 15px;
  width: 571px;
}
.hero-page .hexagon {
  height: 500px;
  left: 49px;
  position: absolute;
  top: 0;
  width: 440px;
}

.hero-page .mockup {
  height: 496px;
  /* left: 84px; */
  left: 74px;
  position: absolute;
  top: -1px;
  width: 388px;
}

.hero-page .frame-10 {
  height: 52px;
  left: -25px;
  position: absolute;
  top: -7px;
  width: 200px;
  transform: translateX(-100%);
  animation: slide-in 0.7s ease-out forwards;
}

.hero-page .text-wrapper-6 {
  color: #ffffff80;
  font-family: "Inter";
  font-size: 10px;
  font-weight: 400;
  left: 46px;
  letter-spacing: 0;
  line-height: 16.6px;
  position: absolute;
  top: -1px;
  width: 188px;
}

.hero-page .vector {
  height: 26px;
  left: 75px;
  position: absolute;
  top: 20px;
  width: 166px;
}

.hero-page .frame-11 {
  height: 53px;
  left: 357px;
  position: absolute;
  top: 477px;
  transform: rotate(180deg);
  width: 149px;
}

.hero-page .overlap-4 {
  height: 31px;
  position: relative;
  top: 8px;
  left: 32px;
  width: 188px;

  transform: translateX(100%); /* Initially off-screen to the left */
  animation: slide-in 0.7s ease-out forwards;
}
.header-burger-menu {
  display: none;
  padding-left: 20px;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  align-self: stretch;
  z-index: 9999;
}

.header-burger-menu-icon {
  stroke: white;
  color: white;
  display: flex;
  height: 30px;
  padding-right: var(--horizantal-padding);
}
/* .hero-page .text-wrapper-7 {
    color: #ffffff80;
    font-family: "Inter";
    font-size: 12px;
    font-weight: 400;
    left: 0;
    letter-spacing: 0;
    line-height: 16.6px;
    position: absolute;
    text-align: right;
    top: -21px;
    transform: rotate(180deg);
    width: 187px;
  } */
.image-mobile {
  display: none;
}
.hero-page .text-wrapper-7 {
  color: #ffffff80;
  font-family: "Inter";
  font-size: 10px;
  font-weight: 400;
  left: 36px;
  letter-spacing: 0;
  line-height: 16.6px;
  position: absolute;
  text-align: right;
  top: -15px;
  transform: rotate(180deg);
  width: 187px;
}

.hero-page .img {
  height: 23px;
  left: 17px;
  position: absolute;
  top: 8px;
  transform: rotate(-180deg);
  width: 166px;
}
/* .hero-page .img {
    height: 30px;
    left: 0;
    position: absolute;
    top: 0;
    transform: rotate(-180deg);
    width: 166px;
  } */

.raper {
  display: flow;
  /* margin-left: 109px; */
  /* margin-left: 124px; */
  margin-left: 10px;
  align-content: space-between;
  margin-top: -134px;
}

.submenu {
  color: var(--Total-white, #fff);
  text-align: center;
  font-family: sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
  letter-spacing: 0.36px;
  padding-bottom: 23px;
}

@media screen and (max-width: 800px) {
  .hero-page .navbar {
    display: none;
  }
  /* .hero-page .logo {
    height: 45px;
    left: -85px;
  } */
  .hero-page .logo {
    height: 45px;
    left: -101px;
    top: -16px;
  }

  .hero-page .frame-2 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 44px;
    left: 18px;
    position: absolute;
    /* top: 160px; */
    top: 120px;
    width: 718px;
  }
}
@media screen and (max-width: 400px) {
  .hero-page .frame-5 {
    display: grid;
    margin-left: 1px;
  }
  .hero-page .the-future-of-social {
    font-family: "Roboto";

    padding-right: 300px;
    font-size: 49px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: 2.94px;
    text-transform: capitalize;
  }

  .hero-page .the-platform-for-the .para {
    font-size: 16px;
    /* padding-right: 10px; */
  }
}
@media screen and (max-width: 950px) {
  .header-navigation {
    display: none;
  }

  .header-innerlogo {
    display: flex;
    /* margin-top: -299px; */
    margin-top: -320px;
    /* height: 47px; */
    height: 43px;
    margin-left: 3px;
  }

  /* .header-burger-menu {
    display: flex;
    margin-right: 17px;
    padding-top: 28px;
    z-index: 10001;
    position: sticky;
  } */

  .header-burger-menu {
    display: flex;
    margin-right: 9px;
    padding-top: 29px;
    z-index: 10001;
    position: sticky;
  }

  .header-action-button-container {
    padding-right: 0;
  }

  .header-submenu {
    position: relative;
    top: auto;
    left: auto;
    transform: none;
    border: none;
    display: flex;
    align-items: flex-start;
    z-index: 999;
    border-radius: 10px;
  }

  .header-submenu-button {
    height: 25px;
    padding: 0px var(--horizantal-padding);
    width: 160px;
    background-color: none;
    border-radius: 10px;
    border: 1px solid var(--no-color);
    transition: border 0.1s, background-color 0.1s;
  }
}

@media screen and (max-width: 500px) {
  .header-mobile-menu {
    width: 100%;
    align-items: center;
    padding: 0 0;
  }

  .hero-page .div-wrapper {
    background-image: url(../../assets/svg/button-Mobile.svg);
    fill: var(--Accent-blue);
    stroke-width: 2px;
    stroke: var(--Accent-blue);
    width: 320px;
    height: 44px;
    flex-shrink: 0;
  }

  .hero-page .div-wrapper-D {
    background-image: url(../../assets/svg/Secondary-button-download.svg);
    fill: var(--Accent-blue);
    stroke-width: 2px;
    stroke: var(--Accent-blue);
    width: 320px;
    height: 44px;
    flex-shrink: 0;
  }

  .hero-page .frame-6 {
    align-items: center;
    display: block;
    gap: 24px;
    left: 30px;
    position: absolute;
    top: 669px;
  }

  .header-submenu {
    background-color: transparent;
    backdrop-filter: none;
  }

  .header-headerItem {
    align-self: center;
  }

  .header-submenu-button {
    align-items: center;
  }

  .header-action-button-container {
    padding: 10px 0;
  }

  .header-headerItem {
    height: 40px;
  }
}

@media screen and (max-width: 450px) and (min-width: 401px) {
  .hero-page .frame-5 {
    display: grid;
    margin-left: 27px;
  }
  .hero-page .the-platform-for-the .para {
    font-size: 19px;
    padding-right: 10px;
  }
  .header-innerlogo {
    display: flex;
    /* margin-top: -299px; */
    margin-top: -320px;
    /* height: 47px; */
    height: 43px;
    /* margin-left: 3px; */
    margin-left: -170px;
  }

  .div-wrapper-1 .cta-color {
    background-image: url(../../assets/svg/join-presale-mobile.svg);
    /* width: 188px;
    height: 40px; */
    cursor: pointer;
    width: 328px;
    height: 44px;
    flex-shrink: 0;
  }

  .header-mobile-menu {
    width: 100%;
    align-items: center;
    padding: 0px 0px;
  }
  .hero-page .header {
    height: 0;
  }
  .header-navitem {
    align-items: center;
  }

  .header-action-button-container {
    display: none;
  }

  .hero-page .the-platform-for-the {
    margin-right: 361px;
    font-size: 19px;
    letter-spacing: 3.7px;
  }

  .hero-page .frame-7 {
    right: -95px;
    gap: 0px;
  }
  .hero-page .frame-8 {
    gap: 0px;
  }

  .div-wrapper-2 .cta-2 {
    background-image: url(../../assets/svg/button-bg-Light-long.svg);
    width: 328px;
    height: 44px;
  }

  .hero-page .overlap-group-wrapper {
    height: 24px;
  }

  .div-wrapper-2 .cta-2 .text-wrapper-cta {
    /* display: none; */
  }

  .hero-page .the-platform-for-the .para {
    font-size: 19px;
    padding-right: 10px;
  }
  .hero-page .the-future-of-social {
    font-family: "Roboto";

    padding-right: 300px;
    font-size: 56px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: 2.94px;
    text-transform: capitalize;
  }
  .hero-page .frame-6 {
    top: 662px;
  }

  .hero-page .the-platform-for-the-2 {
    display: flex;
    color: var(--text-2);
    font-family: "Ligconsolata-Regular", Helvetica;
    font-size: 19px;
    font-weight: 400;
    letter-spacing: 1.1px;
    line-height: 27.5px;
    position: relative;
    text-align: left;
  }
  .hero-page .the-platform-for-the-2 .para-2 {
    align-self: flex-start;
    color: var(--text-2);
    font-family: "Ligconsolata-Regular", Helvetica;
    font-size: 22px;
    font-weight: 400;
    letter-spacing: 1.1px;
    line-height: 27.5px;
    position: relative;
  }

  .hero-page .div-wrapper {
    background-image: url(../../assets/Hero/Phone-with-text.svg);
    fill: var(--Accent-blue);
    stroke-width: 2px;
    stroke: var(--Accent-blue);
    width: 320px;
    height: 44px;
    flex-shrink: 0;
  }
  .hero-page .text-wrapper-3 {
    color: #ffffff;
    font-family: "Inter";
    font-size: 21px;
    font-weight: 700;
    left: 77px;
    letter-spacing: 2.36px;
    line-height: 21.6px;
    position: absolute;
    top: 10px;
    white-space: nowrap;
  }
  .image-mobile {
    display: flex;
    padding-left: 51px;
    margin-top: 356px;
    width: 89%;
  }
  .hero-page .frame-6 {
    display: none;
    /* align-items: center;
    display: block;
    gap: 24px;
    left: 30px;
    position: absolute;
   
    top: 635px; */

    /* align-items: center;
    display: flex;
    gap: 0px;
    left: -76px;
    position: absolute;
 
    top: 656px; */
  }
  .overlap-2 {
    display: none;
  }
  .hero-page .frame-5 {
    display: grid;
  }
  .hero-page .div-wrapper-D {
    background-image: url(../../assets/svg/Secondary-button-download.svg);
    fill: var(--Accent-blue);
    stroke-width: 2px;
    stroke: var(--Accent-blue);
    width: 320px;
    height: 44px;
    flex-shrink: 0;
  }
  .hero-page .text-wrapper-3D {
    color: #ffffff;
    font-family: sans-serif, Helvetica;
    font-size: 21px;
    font-weight: 700;
    left: 77px;
    letter-spacing: 2.36px;
    line-height: 21.6px;
    position: static;
    top: 10px;
    white-space: nowrap;
  }
}

.header-mobile-menu {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  padding: 0px var(--horizantal-padding);
  height: 100vh;
  background: var(--bg-header, rgba(8, 13, 16, 0.95));
  border-radius: 0px 0px 2px 2px;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 40px;
  flex-shrink: 0;
}
@media screen and (max-width: 460px) {
  .header-innerlogo {
    display: flex;
    margin-top: -320px;
    height: 43px;
    margin-left: -170px;
  }

  .div-wrapper-1 .cta-color {
    background-image: url(../../assets/svg/join-presale-mobile.svg);
    cursor: pointer;
    width: 328px;
    height: 44px;
    flex-shrink: 0;
  }

  .header-mobile-menu {
    width: 100%;
    align-items: center;
    padding: 0px 0px;
  }

  .hero-page .header {
    height: 0;
  }

  .header-navitem {
    align-items: center;
  }

  .header-action-button-container {
    display: none;
  }

  .hero-page .the-platform-for-the {
    margin-right: 308px;
    font-size: 19px;
    letter-spacing: 3.7px;
  }

  .hero-page .frame-7 {
    right: -95px;
    gap: 0px;
  }

  .hero-page .frame-8 {
    gap: 0px;
  }

  .div-wrapper-2 .cta-2 {
    background-image: url(../../assets/svg/button-bg-Light-long.svg);
    width: 328px;
    height: 44px;
  }

  .hero-page .overlap-group-wrapper {
    height: 24px;
  }

  /* .hero-page .the-platform-for-the .para {
    font-size: 19px;
    padding-right: 10px;
  } */

  /* .hero-page .the-future-of-social {
    font-family: "Roboto";
    padding-right: 300px;
    font-size: 49px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: 2.94px;
    text-transform: capitalize;
  } */

  .hero-page .frame-6 {
    top: 662px;
  }

  .hero-page .the-platform-for-the-2 {
    display: flex;
    color: var(--text-2);
    font-family: "Ligconsolata-Regular", Helvetica;
    font-size: 19px;
    font-weight: 400;
    letter-spacing: 1.1px;
    line-height: 27.5px;
    position: relative;
    text-align: left;
  }

  .hero-page .the-platform-for-the-2 .para-2 {
    align-self: flex-start;
    color: var(--text-2);
    font-family: "Ligconsolata-Regular", Helvetica;
    font-size: 22px;
    font-weight: 400;
    letter-spacing: 1.1px;
    line-height: 27.5px;
    position: relative;
  }

  .hero-page .div-wrapper {
    background-image: url(../../assets/Hero/Phone-with-text.svg);
    fill: var(--Accent-blue);
    stroke-width: 2px;
    stroke: var(--Accent-blue);
    width: 320px;
    height: 44px;
    flex-shrink: 0;
  }

  .hero-page .text-wrapper-3 {
    color: #ffffff;
    font-family: "Inter";
    font-size: 21px;
    font-weight: 700;
    left: 77px;
    letter-spacing: 2.36px;
    line-height: 21.6px;
    position: absolute;
    top: 10px;
    white-space: nowrap;
  }

  .image-mobile {
    padding-top: 50px;
    width: 344px;
    flex-shrink: 0;
    display: flex;
    margin-top: 471px;
  }

  .hero-page .frame-6 {
    display: none;
  }

  .overlap-2 {
    display: none;
  }

  .hero-page .div-wrapper-D {
    background-image: url(../../assets/svg/Secondary-button-download.svg);
    fill: var(--Accent-blue);
    stroke-width: 2px;
    stroke: var(--Accent-blue);
    width: 320px;
    height: 44px;
    flex-shrink: 0;
  }

  .hero-page .text-wrapper-3D {
    color: #ffffff;
    font-family: sans-serif, Helvetica;
    font-size: 21px;
    font-weight: 700;
    left: 77px;
    letter-spacing: 2.36px;
    line-height: 21.6px;
    position: static;
    top: 10px;
    white-space: nowrap;
  }
}
