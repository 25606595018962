@import "../../mainVariables.css";

.mysteryBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}

.mysteryBox-container {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100dvh;
  background-image: url("../../assets/images/mysteryBox.webp");
  background-size: cover;
  background-position: center;
  overflow: hidden;
}

.mysteryBox-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  padding-bottom: 264px;
}

.mysteryBox-title-alpha {
  width: 100%;
  color: var(--para-text-color);
  text-align: right;
  font-size: 16px;

  font-weight: 700;
}

.mysteryBox-title-title {
  color: var(--text-color);
  text-align: center;
  font-size: 70px;

  font-weight: 700;
}

.specific-text {
  background: var(
    --3VO-Gradiant,
    linear-gradient(90deg, #d129dc 0.08%, #0cdbfd 99.92%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.mysteryBox-title-subtitle {
  color: var(--para-text-color);
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  margin-right: 20px;
  margin-left: 20px;
}

@media screen and (max-width: 850px) {
  .mysteryBox-title-title {
    font-size: 62px;
  }
}

@media screen and (max-width: 650px) {
  .mysteryBox-title-title {
    font-size: 54px;
  }
}

@media screen and (max-width: 500px) {
  .mysteryBox-title-title {
    font-size: 48px;
  }

  .mysteryBox-comingsoon img {
    width: 160px;
  }
}

@media screen and (max-width: 400px) {
  .mysteryBox-title-title {
    font-size: 42px;
  }
}

@media screen and (max-width: 360px) {
  .mysteryBox-title-title {
    font-size: 32px;
  }

  .mysteryBox-button-title {
    font-size: 16px;
  }

  .mysteryBox-button {
    justify-content: center;
    width: 100%;
    padding: 5px 0px;
  }
}
